import React, { useEffect, useState } from "react";
import {
  Carousel,
  Avatar,
  List,
  Space,
  Row,
  Col,
  Card,
  Typography,
  Tag,
  Image,
  Collapse,
  Button,
} from "antd";
import { SettingOutlined, NotificationOutlined } from "@ant-design/icons";
import {
  getNotiUserAll,
  getNotiAdminAll,
  readNotificationByNotiId,
  getNotiUserUnReadAll,
  getNotiAdminUnReadAll,
} from "../services/NotificationsService";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCount } from "../redux/notification.slice";
const { Panel } = Collapse;
const { Paragraph, Text } = Typography;

export default function Notifications() {
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("@userRole");
  const userJwt = localStorage.getItem("@userJwt");
  const [notiData, setNotiData] = useState([]);
  const [expandIconPosition, setExpandIconPosition] = useState("start");

  useEffect(() => {
    initAllData();
  }, []);

  const initAllData = async () => {
    if (userRole === "user") {
      const res = await getNotiUserAll();
      if (!res._error) {
        setNotiData(res.data);
      }
    } else {
      const res = await getNotiAdminAll();
      if (!res._error) {
        setNotiData(res.data);
      }
    }
  };

  const onPositionChange = (newExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };
  const onChange = (key, isRead) => {
    //console.log(key);
    if (!isRead) {
      if (Number(key)) {
        const id = Number(key);
        readNoti(id);
      }
    }
  };

  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );
  const readNoti = async (id) => {
    const data = { id: id };
    await readNotificationByNotiId(data);
    const updateData = notiData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isRead: true,
        };
      }
      return item;
    });
    setNotiData(updateData);

    if (userJwt) {
      if (userRole === "user") {
        const res = await getNotiUserUnReadAll();
        if (!res._error) {
          dispatch(updateCount(res.data.length));
        }
      } else {
        const res = await getNotiAdminUnReadAll();
        if (!res._error) {
          dispatch(updateCount(res.data.length));
        }
      }
    }
  };
  const renderCollapseItem = (item) => {
    var colorBg = "#ffa39e";
    if (item.isRead) {
      colorBg = "#b7eb8f";
    }
    return (
      <List.Item>
        <Collapse
          onChange={() => onChange(item.id, item.isRead)}
          expandIconPosition={expandIconPosition}
          style={{ flex: 1, backgroundColor: colorBg }}
        >
          <Panel
            showArrow={false}
            header={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                {item.icon && <Avatar src={item.icon} />}
                {!item.icon && <Avatar icon={<NotificationOutlined />} />}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    marginLeft: 10,
                  }}
                >
                  <span>{item.title}</span>
                  <span>
                    <Text type="secondary">
                      {moment(item.created_dt)
                        .add(543, "year")
                        .format("DD/MM/YYYY HH:mm")}
                    </Text>
                    <Text type="secondary">{` ${
                      item.isRead ? "ดูแล้ว" : "ยังไม่ได้ดู"
                    }`}</Text>
                  </span>
                </div>
                <Button
                  onClick={() => (item.isRead ? null : readNoti(item.id))}
                >
                  ดูเพิ่มเติม
                </Button>
              </div>
            }
            key={item.id}
            // extra={genExtra()}
          >
            <div>
              <div>{item.title}</div>
              <div>{item.description}</div>
              <div>
                {moment(item.created_dt)
                  .add(543, "year")
                  .format("DD/MM/YYYY HH:mm")}
              </div>
              {item.url && (
                <div>
                  <Link target={"_blank"} to={item.url}>
                    คลิกดูลิ้งแนบ
                  </Link>
                </div>
              )}
            </div>
          </Panel>
        </Collapse>
      </List.Item>
    );
  };
  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card className="header-solid h-full ant-card-p-0">
                <List
                  itemLayout="horizontal"
                  dataSource={notiData}
                  header={<h4>การแจ้งเตือน</h4>}
                  renderItem={(item) => renderCollapseItem(item)}
                  pagination={{
                    onChange: (page) => {},
                    pageSize: 20,
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
