import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Select,
  Typography,
  Avatar,
  Image,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { AdminChecker } from "../../utils/AdminChecker";
import {
  getAllDatas,
  createDatas,
  updateDatas,
} from "../../services/TeacherService";

function BasicDataTeacher() {
  AdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [editPassword, setEditPassword] = useState(-1);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllDatas();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const handleSwitch = async (values, e) => {
    const formData = new FormData();
    formData.append("id", values.id);
    formData.append("firstName", values.firstName);
    formData.append("nickname", values.nickname || "");
    formData.append("lastName", values.lastName || "");
    formData.append("mobile", values.mobile || "");
    formData.append("role", values.role);
    formData.append("isActive", e);
    const res = await updateDatas(formData);
    if (!res._error) {
      const updateData = allData.map((item) => {
        if (item.id === values.id) {
          return { ...item, isActive: e };
        }
        return item;
      });
      setAllData(updateData);
    }
  };

  const openEdit = (data) => {
    setEditId(data.id);
    setEditPassword(data.password);
    setImageShow(data.avatar);
    const values = {
      layout: "vertical",
      firstName: data.firstName,
      nickname: data.nickname,
      lastName: data.lastName,
      mobile: data.mobile,
      role: data.role,
      isActive: data.isActive,
    };
    formEdit.setFieldsValue(values);
    setIsModalEditVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Avatar
          style={{
            backgroundColor: "#CCCCCC",
          }}
          src={avatar}
        />
      );
    }
    return (
      <Avatar
        style={{
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "โปรไฟล์",
      dataIndex: "avatar",
      width: 80,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.avatar) : null,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 120,
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortOrder: sortedInfo.columnKey === "firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      dataIndex: "nickname",
      key: "nickname",
      ...getColumnSearchProps("nickname"),
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
      sortOrder: sortedInfo.columnKey === "nickname" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps("lastName"),
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      sortOrder: sortedInfo.columnKey === "lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ประเภท",
      dataIndex: "role",
      key: "role",
      ...getColumnSearchProps("role"),
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortOrder: sortedInfo.columnKey === "role" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "mobile",
      key: "mobile",
      ...getColumnSearchProps("mobile"),
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      sortOrder: sortedInfo.columnKey === "mobile" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานะการใช้งาน",
      dataIndex: "isActive",
      sorter: (a, b) =>
        JSON.stringify(a.isActive).localeCompare(JSON.stringify(b.isActive)),
      sortOrder: sortedInfo.columnKey === "isActive" ? sortedInfo.order : null,
      ellipsis: true,
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <Switch
            checkedChildren="เปิด "
            unCheckedChildren="ปิด "
            checked={record.isActive}
            onChange={(e) => handleSwitch(record, e)}
          />
        ) : null,
    },
    {
      title: "แก้ไข",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"แก้ไข"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("firstName", values.firstName);
        formData.append("nickname", values.nickname || "");
        formData.append("lastName", values.lastName || "");
        formData.append("mobile", values.mobile || "");
        formData.append("role", values.role);
        formData.append("isActive", values.isActive);
        const res = await createDatas(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };
  const handleEditOk = () => {
    setLoading(true);
    formEdit
      .validateFields()
      .then(async (values) => {
        var usePassword = values.password;
        var checkIsChangePassword = false;
        if (editPassword !== values.password) {
          usePassword = values.password;
          checkIsChangePassword = true;
        }
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("id", editId);
        formData.append("firstName", values.firstName);
        formData.append("nickname", values.nickname || "");
        formData.append("lastName", values.lastName || "");
        formData.append("mobile", values.mobile || "");
        formData.append("role", values.role);
        formData.append("isActive", values.isActive);
        const res = await updateDatas(formData);
        if (!res._error) {
          const updateData = allData.map((item) => {
            if (item.id === editId) {
              return {
                ...item,
                avatar: res.data.avatar,
                firstName: values.firstName,
                nickname: values.nickname,
                lastName: values.lastName,
                mobile: values.mobile,
                role: values.role,
                isActive: values.isActive,
              };
            }
            return item;
          });
          setAllData(updateData);
          setIsModalEditVisible(false);
          setImageFiles(null);
          setImageShow(null);
          formEdit.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const checkDuplicateAdminUsername = (rule, value, callback) => {
    const checker = allData.filter((v) => v.username === value);
    if (checker.length > 0) {
      callback(` username ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const checkDuplicateEditAdminUsername = (rule, value, callback) => {
    const checker = allData.filter((v) => v.username === value);
    if (checker.length > 1) {
      callback(` username ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ข้อมูลวิปัสสนาจารย์
                        </h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Button
                          onClick={() => setIsModalVisible(true)}
                          type="ghost"
                          icon={<PlusCircleOutlined />}
                        >
                          เพิ่มข้อมูลวิปัสสนาจารย์
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 1300 }}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
              <Modal
                title="เพิ่มข้อมูลวิปัสสนาจารย์"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    ยืนยันเพิ่มข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    initialValues={{
                      layout: "vertical",
                      role: "วิปัสสนาจารย์",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Avatar
                          shape={"square"}
                          size={140}
                          style={{
                            alignSelf: "center",
                            backgroundColor: "#cccccc",
                            marginBottom: 10,
                            borderRadius: 10,
                          }}
                          src={
                            <Image
                              src={ImageShow}
                              style={{
                                width: 140,
                                height: 140,
                                objectFit: "cover",
                              }}
                            />
                          }
                        />
                      </center>
                    )}
                    <Form.Item label="โปรไฟล์" name="avatar">
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์รูปภาพ"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ชื่อ"
                      name="firstName"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อ!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุชื่อ" />
                    </Form.Item>
                    <Form.Item label="ฉายา" name="nickname">
                      <Input placeholder="ระบุฉายา" />
                    </Form.Item>
                    <Form.Item label="นามสกุล" name="lastName">
                      <Input placeholder="ระบุนามสกุล" />
                    </Form.Item>
                    <Form.Item label="เบอร์มือถือ" name="mobile">
                      <Input
                        placeholder="ระบุเบอร์มือถือ"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item label="ประเภท" name="role" required>
                      <Select>
                        <Select.Option value="วิปัสสนาจารย์">
                          วิปัสสนาจารย์
                        </Select.Option>
                        <Select.Option value="อาจารย์">อาจารย์</Select.Option>
                        <Select.Option value="วิปัสสนาจารย์และอาจารย์">
                          วิปัสสนาจารย์และอาจารย์
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>

              <Modal
                title="แก้ไขข้อมูลวิปัสสนาจารย์"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  formEdit.resetFields();
                  setIsModalEditVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formEdit.resetFields();
                      setIsModalEditVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleEditOk}
                  >
                    ยืนยันแก้ไขข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={formEdit}
                    initialValues={{
                      layout: "vertical",
                      role: "วิปัสสนาจารย์",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Avatar
                          shape={"square"}
                          size={140}
                          style={{
                            alignSelf: "center",
                            backgroundColor: "#cccccc",
                            marginBottom: 10,
                            borderRadius: 10,
                          }}
                          src={
                            <Image
                              src={ImageShow}
                              style={{
                                width: 140,
                                height: 140,
                                objectFit: "cover",
                              }}
                            />
                          }
                        />
                      </center>
                    )}
                    <Form.Item label="โปรไฟล์" name="avatar">
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์รูปภาพ"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ชื่อ"
                      name="firstName"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อ!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุชื่อ" />
                    </Form.Item>
                    <Form.Item label="ฉายา" name="nickname">
                      <Input placeholder="ระบุฉายา" />
                    </Form.Item>
                    <Form.Item label="นามสกุล" name="lastName">
                      <Input placeholder="ระบุนามสกุล" />
                    </Form.Item>
                    <Form.Item label="เบอร์มือถือ" name="mobile">
                      <Input
                        placeholder="ระบุเบอร์มือถือ"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item label="ประเภท" name="role" required>
                      <Select>
                        <Select.Option value="วิปัสสนาจารย์">
                          วิปัสสนาจารย์
                        </Select.Option>
                        <Select.Option value="อาจารย์">อาจารย์</Select.Option>
                        <Select.Option value="วิปัสสนาจารย์และอาจารย์">
                          วิปัสสนาจารย์และอาจารย์
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default BasicDataTeacher;
