/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button, Image, Badge } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/favicon.png";
import {
  UserOutlined,
  FileDoneOutlined,
  SafetyOutlined,
  CloudOutlined,
  DatabaseOutlined,
  ContactsOutlined,
  BankOutlined,
  CheckSquareOutlined,
  CalendarOutlined,
  TrophyOutlined,
} from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";
import { updateIsShowLoginModal } from "../../redux/autoStore.slice";
import Avatar from "antd/lib/avatar/avatar";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const isShowLoginModal = useSelector(
    (state) => state.autoStore.isShowLoginModal
  );
  const notificationCount = useSelector((state) => state.notification.count);
  const onlineCount = useSelector((state) => state.notification.onlineCount);
  const onsiteCount = useSelector((state) => state.notification.onsiteCount);
  const dispatch = useDispatch();
  const page = pathname.replace("/", "");

  const userRole = localStorage.getItem("@userRole");
  const userJwt = localStorage.getItem("@userJwt");
  const stName = localStorage.getItem("@userName");
  const stNickName = localStorage.getItem("@userNickname");
  const stLastName = localStorage.getItem("@userLastname");
  const stUserMobile = localStorage.getItem("@userMobile");
  const userName =
    stNickName !== ""
      ? `${stName} ${stNickName} ${stLastName}`
      : `${stName} ${stLastName}`;
  const userAvatar = localStorage.getItem("@userAvatar");

  const home = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const dharmaOnlineUserChecker = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const basicDataMain = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const dharmaOnline = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];
  const dharmaResult = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];
  const notifications = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
        fill="#111827"
      ></path>
      <path
        d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
        fill="#111827"
      ></path>
    </svg>,
  ];

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>
          ระบบทะเบียนและประมวลผลการวิปัสนากรรมฐาน นิสิต มจร.ขอนแก่น (IDs)
        </span>
      </div>
      <hr />

      {!userJwt && (
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <h6>เข้าสู่ระบบ</h6>
          <p>เพื่อดำเนินกิจกรรม</p>
          <Button
            onClick={() => dispatch(updateIsShowLoginModal(true))}
            type="primary"
            className="ant-btn-sm ant-btn-block"
          >
            คลิกเข้าสู่ระบบ
          </Button>
        </div>
      )}
      {userJwt && (
        <div
          className="footer-box"
          style={{
            background: "#13c2c2",
            paddingBottom: 2,
          }}
        >
          {userAvatar !== "none" && (
            <Avatar
              size={34}
              style={{
                backgroundColor: "#cccccc",
                position: "absolute",
                right: 34,
              }}
              src={
                <Image
                  src={userAvatar}
                  style={{
                    width: 34,
                    height: 34,
                    objectFit: "cover",
                  }}
                />
              }
            />
          )}
          {userAvatar === "none" && (
            <Avatar
              size={34}
              style={{
                backgroundColor: "#cccccc",
                position: "absolute",
                right: 34,
              }}
              icon={<UserOutlined />}
            />
          )}
          <h6>ยินดีต้อนรับ</h6>
          <p>{userName}</p>
        </div>
      )}

      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/home">
            <span
              className="icon"
              style={{
                background: page === "home" ? color : "",
              }}
            >
              {home}
            </span>
            <span className="label">หน้าหลัก</span>
          </NavLink>
        </Menu.Item>

        {userJwt && (
          <>
            <Menu.Item key="44">
              <NavLink to="/notifications">
                <span
                  className="icon"
                  style={{
                    background: page === "notifications" ? color : "",
                  }}
                >
                  <Badge size="small" count={notificationCount || 0}>
                    {notifications}
                  </Badge>
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="label">การแจ้งเตือน</span>
                </div>
              </NavLink>
            </Menu.Item>
          </>
        )}

        {userRole && userRole === "user" && (
          <>
            <Menu.Item key="2">
              <NavLink to="/dharma-result">
                <span
                  className="icon"
                  style={{
                    background: page === "dharmaResult" ? color : "",
                  }}
                >
                  <SafetyOutlined style={{ fontSize: 20 }} />
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="label">สรุปผลปฏิบัติธรรม</span>
                </div>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="3">
              <NavLink to="/dharma-online-user-checker">
                <span
                  className="icon"
                  style={{
                    background: page === "dharmaOnlineUserChecker" ? color : "",
                  }}
                >
                  <FileDoneOutlined style={{ fontSize: 20 }} />
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="label">ตรวจสอบผลรายงาน</span>
                  <span className="label">ปฏิบัติธรรมออนไลน์</span>
                </div>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="4">
              <NavLink to="/dharma-onsite-user-checker">
                <span
                  className="icon"
                  style={{
                    background: page === "dharmaOnsiteUserChecker" ? color : "",
                  }}
                >
                  <FileDoneOutlined style={{ fontSize: 20 }} />
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="label">ตรวจสอบผลรายงาน</span>
                  <span className="label">ปฏิบัติธรรมในสถานที่</span>
                </div>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="5">
              <NavLink to="/dharma-onyear-user-checker">
                <span
                  className="icon"
                  style={{
                    background: page === "dharmaOnyearUserChecker" ? color : "",
                  }}
                >
                  <FileDoneOutlined style={{ fontSize: 20 }} />
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="label">ตรวจสอบผลรายงาน</span>
                  <span className="label">ปฏิบัติธรรมประจำปี</span>
                </div>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink to="/certificate">
                <span
                  className="icon"
                  style={{
                    background: page === "certificate" ? color : "",
                  }}
                >
                  <TrophyOutlined style={{ fontSize: 20 }} />
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="label">รายการวุฒิบัตร</span>
                </div>
              </NavLink>
            </Menu.Item>
          </>
        )}

        {userRole && (userRole === "admin" || userRole === "subadmin") && (
          <>
            <Menu.Item className="menu-item-header" key="5">
              สำหรับเจ้าหน้าที่
            </Menu.Item>

            <Menu.Item key="50">
              <NavLink to="/dharma-online">
                <span
                  className="icon"
                  style={{
                    background: page === "dharmaOnline" ? color : "",
                  }}
                >
                  <Badge size="small" count={onlineCount || 0}>
                    <CloudOutlined style={{ fontSize: 20 }} />
                  </Badge>
                </span>
                <span className="label">ปฏิบัติธรรมออนไลน์</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="51">
              <NavLink to="/dharma-onsite">
                <span
                  className="icon"
                  style={{
                    background: page === "dharmaOnsite" ? color : "",
                  }}
                >
                  <Badge size="small" count={onsiteCount || 0}>
                    <BankOutlined style={{ fontSize: 20 }} />
                  </Badge>
                </span>
                <span className="label">ปฏิบัติธรรมในสถานที่</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="56">
              <NavLink to="/dharma-onyear">
                <span
                  className="icon"
                  style={{
                    background: page === "dharmaOnyear" ? color : "",
                  }}
                >
                  <CalendarOutlined style={{ fontSize: 20 }} />

                  {/* <Badge size="small" count={onsiteCount || 0}>
                    <BankOutlined style={{ fontSize: 20 }} />
                  </Badge> */}
                </span>
                <span className="label">ปฏิบัติธรรมประจำปี</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="57">
              <NavLink to="/user-compensate">
                <span
                  className="icon"
                  style={{
                    background: page === "UserCompensate" ? color : "",
                  }}
                >
                  <CalendarOutlined style={{ fontSize: 20 }} />
                </span>
                <span className="label">ปลดล็อคบัญชีผู้ใช้</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="61">
              <NavLink to="/rooms">
                <span
                  className="icon"
                  style={{
                    background: page === "rooms" ? color : "",
                  }}
                >
                  <BankOutlined style={{ fontSize: 20 }} />
                </span>
                <span className="label">สถานที่ปฏิบัติธรรม</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="60">
              <NavLink to="/banner">
                <span
                  className="icon"
                  style={{
                    background: page === "bannerMain" ? color : "",
                  }}
                >
                  <ContactsOutlined style={{ fontSize: 20 }} />
                </span>
                <span className="label">ข้อมูลแบนเนอร์</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="71">
              <NavLink to="/assessment">
                <span
                  className="icon"
                  style={{
                    background: page === "assessment" ? color : "",
                  }}
                >
                  <CheckSquareOutlined style={{ fontSize: 20 }} />
                </span>
                <span className="label">ข้อมูลแบบประเมิน</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="70">
              <NavLink to="/basic-data">
                <span
                  className="icon"
                  style={{
                    background: page === "basicDataMain" ? color : "",
                  }}
                >
                  <DatabaseOutlined style={{ fontSize: 20 }} />
                </span>
                <span className="label">ข้อมูลพื้นฐาน</span>
              </NavLink>
            </Menu.Item>
          </>
        )}
      </Menu>
      <div style={{ height: 40 }}></div>
    </>
  );
}

export default Sidenav;
