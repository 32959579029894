import React, { useEffect, useState } from "react";
import {
  Carousel,
  List,
  Row,
  Col,
  Card,
  Typography,
  Tag,
  Image,
  Button,
  Alert,
  Modal,
  Form,
  Input,
  Radio,
  Checkbox,
  Divider,
} from "antd";
import {
  FieldTimeOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { getAllDharmaOnyearPlublic } from "../services/DharmaOnyearService";

const { Paragraph, Text, Title } = Typography;

export default function OnYearHome() {
  const [loading, setLoading] = useState(false);
  const [allDharmaOnyear, setAllDharmaOnyear] = useState([]);

  useEffect(() => {
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllDharmaOnyearPlublic();
    if (!res._error) {
      setAllDharmaOnyear(res.data);
    }
  };

  const renderTeacher = (t1, t2, t3, t4, t5) => {
    let res = [];
    if (t1 !== null && t1.length > 0) {
      res.push(t1);
    }
    if (t2 !== null && t2.length > 0) {
      res.push(t2);
    }
    if (t3 !== null && t3.length > 0) {
      res.push(t3);
    }
    if (t4 !== null && t4.length > 0) {
      res.push(t4);
    }
    if (t5 !== null && t5.length > 0) {
      res.push(t5);
    }
    return `วิปัสสนาจารย์: ${String(res).split(",").join(", ")}`;
  };
  const renderStartEnd = (start, end) => {
    const startTime = moment(start).add(543, "year").format("DD/MM/YYYY HH:mm");
    const endTime = moment(end).add(543, "year").format("DD/MM/YYYY HH:mm");
    return (
      <>
        <FieldTimeOutlined /> {startTime} ถึง {endTime}
      </>
    );
  };
  const renderReadMore = (uuidLink) => {
    const url = `/home/dharma-onyear-detail?uuidLink=${uuidLink}`;
    return (
      <>
        <InfoCircleOutlined /> <Link to={url}>อ่านเพิ่มเติม</Link>
      </>
    );
  };
  return (
    <Card className="header-solid h-full ant-card-p-0">
      <List
        itemLayout="vertical"
        size="small"
        header={
          <h3>
            <b>ปฏิบัติธรรมประจำปี</b>
          </h3>
        }
        pagination={{
          onChange: (page) => {},
          pageSize: 10,
        }}
        dataSource={allDharmaOnyear}
        renderItem={(item, index) => (
          <List.Item
            key={item.dharma_onyear_id}
            extra={
              <Image
                style={{
                  width: 320,
                  height: 180,
                  backgroundColor: "#CCCCCC",
                  objectFit: "cover",
                  borderRadius: 8,
                }}
                width={320}
                alt={item.dharma_onyear_name}
                src={item.dharma_onyear_image}
              />
            }
          >
            <List.Item.Meta
              title={
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: false,
                  }}
                >
                  <Link
                    to={`/home/dharma-onyear-detail?uuidLink=${item.dharma_onyear_uuid}`}
                  >
                    {item.dharma_onyear_name}
                  </Link>
                </Paragraph>
              }
              description={
                <>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                  >
                    {item.rooms_name}
                  </Paragraph>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Tag>
                      {renderStartEnd(
                        item.dharma_onyear_startOnyearTime,
                        item.dharma_onyear_endOnyearTime
                      )}
                    </Tag>
                    <Tag style={{ marginTop: 8 }}>
                      {renderReadMore(item.dharma_onyear_uuid)}
                    </Tag>
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
}
