/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Badge,
  Dropdown,
  Button,
  List,
  Avatar,
  Typography,
  Space,
  Modal,
  Form,
  Input,
  message,
  Image,
  Alert,
} from "antd";

import {
  UserOutlined,
  LogoutOutlined,
  LoginOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  BellOutlined,
} from "@ant-design/icons";

import { Link, NavLink } from "react-router-dom";
// import styled from "styled-components";
// import avtar from "../../assets/images/team-2.jpg";

import { adminSignIn, signIn } from "../../services/AuthService";
import { updateProfile } from "../../services/AdminsService";
import { updateUserProfile } from "../../services/UsersServices";

import { useSelector, useDispatch } from "react-redux";
import { updateIsShowLoginModal } from "../../redux/autoStore.slice";
import {
  updateCount,
  updateOnlineCount,
  updateOnsiteCount,
  updateOnsiteGoCount,
  updateOnsiteRsCount,
} from "../../redux/notification.slice";
import {
  getNotiAdminUnReadAll,
  getNotiUserUnReadAll,
  readNotificationByNotiId,
  getNotiAdminUnApproveOnlineAll,
  getNotiAdminUnApproveOnsiteGo,
  getNotiAdminUnApproveOnsite,
} from "../../services/NotificationsService";
import { checkUserCompensate } from "../../services/DharmaOnyearService";
import { getUserCheckAssessment } from "../../services/AssessmentService";
import { getAdminAllActive } from "../../services/AdminsService";

import DoAssessment from "../DoAssessment";

const { Paragraph } = Typography;

// const ButtonContainer = styled.div`
//   .ant-btn-primary {
//     background-color: #1890ff;
//   }
//   .ant-btn-success {
//     background-color: #52c41a;
//   }
//   .ant-btn-yellow {
//     background-color: #fadb14;
//   }
//   .ant-btn-black {
//     background-color: #262626;
//     color: #fff;
//     border: 0px;
//     border-radius: 5px;
//   }
//   .ant-switch-active {
//     background-color: #1890ff;
//   }
// `;

const bell = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#111827"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#111827"
    ></path>
  </svg>,
];

// const wifi = [
//   <svg
//     width="20"
//     height="20"
//     viewBox="0 0 107 107"
//     version="1.1"
//     xmlns="http://www.w3.org/2000/svg"
//     key={0}
//   >
//     <g
//       id="Page-1"
//       stroke="none"
//       stroke-width="1"
//       fill="none"
//       fillRule="evenodd"
//     >
//       <g id="logo-spotify" fill="#2EBD59" fillRule="nonzero">
//         <path
//           d="M53.5,0 C23.9517912,0 0,23.9517912 0,53.5 C0,83.0482088 23.9517912,107 53.5,107 C83.0482088,107 107,83.0482088 107,53.5 C107,23.9554418 83.0482088,0.00365063118 53.5,0 Z M78.0358922,77.1597407 C77.0757762,78.7368134 75.0204708,79.2296486 73.4506994,78.2695326 C60.8888775,70.5922552 45.0743432,68.8582054 26.4524736,73.1111907 C24.656363,73.523712 22.8675537,72.3993176 22.458683,70.6032071 C22.0461617,68.8070966 23.1669055,67.0182873 24.9666667,66.6094166 C45.3444899,61.9548618 62.8273627,63.9590583 76.9297509,72.5745479 C78.4995223,73.5419652 78.9996588,75.5899693 78.0358922,77.1597407 L78.0358922,77.1597407 Z M84.5814739,62.5973729 C83.373115,64.5614125 80.8030706,65.1747185 78.8426817,63.9700102 C64.4664961,55.1318321 42.5408052,52.5727397 25.5325145,57.7347322 C23.3275333,58.4027977 20.9984306,57.1579324 20.3267144,54.9566018 C19.6622996,52.7516206 20.9071648,50.4261685 23.1084954,49.7544524 C42.5371546,43.858683 66.6933811,46.7134766 83.2051859,56.8622313 C85.1692255,58.0705902 85.7898328,60.636984 84.5814739,62.5973729 Z M85.1436711,47.4253497 C67.8980894,37.1853292 39.4523712,36.2434664 22.9880246,41.2375299 C20.3449676,42.0406687 17.5485841,40.5475606 16.7490959,37.9045036 C15.9496076,35.2614466 17.4390652,32.4650631 20.0857728,31.6619243 C38.9850904,25.9267827 70.3987718,27.0329239 90.2509041,38.8171614 C92.627465,40.2299556 93.4087001,43.3001365 91.9995565,45.6730467 C90.5940635,48.0532583 87.5165814,48.838144 85.1436711,47.4253497 Z"
//           id="Shape"
//         ></path>
//       </g>
//     </g>
//   </svg>,
// ];

// const credit = [
//   <svg
//     width="20"
//     height="20"
//     viewBox="0 0 20 20"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     key={0}
//   >
//     <path
//       fill="#1890FF"
//       d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
//     ></path>
//     <path
//       fill="#1890FF"
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
//     ></path>
//   </svg>,
// ];

// const clockicon = [
//   <svg
//     width="20"
//     height="20"
//     viewBox="0 0 20 20"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     key={0}
//   >
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
//       fill="#111827"
//     ></path>
//   </svg>,
// ];

// const data = [
//   {
//     title: "New message from Sophie",
//     description: <>{clockicon} 2 days ago</>,

//     avatar: avtar,
//   },
//   {
//     title: "New album by Travis Scott",
//     description: <>{clockicon} 2 days ago</>,

//     avatar: <Avatar shape="square">{wifi}</Avatar>,
//   },
//   {
//     title: "Payment completed",
//     description: <>{clockicon} 2 days ago</>,
//     avatar: <Avatar shape="square">{credit}</Avatar>,
//   },
// ];

// const logsetting = [
//   <svg
//     width="20"
//     height="20"
//     viewBox="0 0 20 20"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     key={0}
//   >
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M11.4892 3.17094C11.1102 1.60969 8.8898 1.60969 8.51078 3.17094C8.26594 4.17949 7.11045 4.65811 6.22416 4.11809C4.85218 3.28212 3.28212 4.85218 4.11809 6.22416C4.65811 7.11045 4.17949 8.26593 3.17094 8.51078C1.60969 8.8898 1.60969 11.1102 3.17094 11.4892C4.17949 11.7341 4.65811 12.8896 4.11809 13.7758C3.28212 15.1478 4.85218 16.7179 6.22417 15.8819C7.11045 15.3419 8.26594 15.8205 8.51078 16.8291C8.8898 18.3903 11.1102 18.3903 11.4892 16.8291C11.7341 15.8205 12.8896 15.3419 13.7758 15.8819C15.1478 16.7179 16.7179 15.1478 15.8819 13.7758C15.3419 12.8896 15.8205 11.7341 16.8291 11.4892C18.3903 11.1102 18.3903 8.8898 16.8291 8.51078C15.8205 8.26593 15.3419 7.11045 15.8819 6.22416C16.7179 4.85218 15.1478 3.28212 13.7758 4.11809C12.8896 4.65811 11.7341 4.17949 11.4892 3.17094ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
//       fill="#111827"
//     ></path>
//   </svg>,
// ];

const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

// const setting = [
//   <svg
//     width="20"
//     height="20"
//     viewBox="0 0 20 20"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     key={0}
//   >
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M11.4892 3.17094C11.1102 1.60969 8.8898 1.60969 8.51078 3.17094C8.26594 4.17949 7.11045 4.65811 6.22416 4.11809C4.85218 3.28212 3.28212 4.85218 4.11809 6.22416C4.65811 7.11045 4.17949 8.26593 3.17094 8.51078C1.60969 8.8898 1.60969 11.1102 3.17094 11.4892C4.17949 11.7341 4.65811 12.8896 4.11809 13.7758C3.28212 15.1478 4.85218 16.7179 6.22417 15.8819C7.11045 15.3419 8.26594 15.8205 8.51078 16.8291C8.8898 18.3903 11.1102 18.3903 11.4892 16.8291C11.7341 15.8205 12.8896 15.3419 13.7758 15.8819C15.1478 16.7179 16.7179 15.1478 15.8819 13.7758C15.3419 12.8896 15.8205 11.7341 16.8291 11.4892C18.3903 11.1102 18.3903 8.8898 16.8291 8.51078C15.8205 8.26593 15.3419 7.11045 15.8819 6.22416C16.7179 4.85218 15.1478 3.28212 13.7758 4.11809C12.8896 4.65811 11.7341 4.17949 11.4892 3.17094ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
//       fill="#111827"
//     ></path>
//   </svg>,
// ];

function Header({
  placement,
  name,
  subName,
  SubPathLv2,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const isShowLoginModal = useSelector(
    (state) => state.autoStore.isShowLoginModal
  );
  const notificationCount = useSelector((state) => state.notification.count);
  const dispatch = useDispatch();

  //const { Title, Text } = Typography;

  const [loading, setLoading] = useState(false);
  const [isDoCompensate, setIsDoCompensate] = useState(false);
  const [isDoAssessment, setIsDoAssessment] = useState(false);
  const [doAssessmentId, setDoAssessmentId] = useState(0);
  const [adminActiveList, setAdminActiveList] = useState([]);
  //const [visible, setVisible] = useState(false);
  //const [sidenavType, setSidenavType] = useState("transparent");
  const [loginError, setLoginError] = useState("");

  const [notifiUnread, setNotiUnread] = useState([]);

  //const [isModalVisible, setIsModalVisible] = useState(isShowLoginModal);
  const [isModalProfile, setIsModalProfile] = useState(false);
  const [form] = Form.useForm();
  const [formProfile] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(
    localStorage.getItem("@userAvatar") !== "none"
      ? localStorage.getItem("@userAvatar")
      : null
  );

  const userRole = localStorage.getItem("@userRole");
  const userJwt = localStorage.getItem("@userJwt");
  const stName = localStorage.getItem("@userName");
  const stNickName = localStorage.getItem("@userNickname");
  const stLastName = localStorage.getItem("@userLastname");
  const stUserMobile = localStorage.getItem("@userMobile");
  const userName =
    stNickName !== ""
      ? `${stName} ${stNickName} ${stLastName}`
      : `${stName} ${stLastName}`;
  const userAvatar = localStorage.getItem("@userAvatar");

  useEffect(() => window.scrollTo(0, 0));

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {}, [isShowLoginModal]);

  const initData = async () => {
    if (userJwt) {
      if (userRole === "user") {
        const res = await getNotiUserUnReadAll();
        if (!res._error) {
          setNotiUnread(res.data);
          dispatch(updateCount(res.data.length));
        }
        const cpt = await checkUserCompensate();
        if (!cpt._error) {
          if (cpt.data && cpt.data.id) {
            const admins = await getAdminAllActive();
            if (!admins._error) {
              console.log(admins.data);
              setAdminActiveList(admins.data);
            }
            setIsDoCompensate(true);
          } else {
            const asm = await getUserCheckAssessment();
            if (!asm._error) {
              if (asm.data && asm.data.assessment_id) {
                const startDo = new Date(asm.data.startTime);
                const timeNow = new Date();
                if (startDo <= timeNow) {
                  setDoAssessmentId(asm.data.assessment_id);
                  setIsDoAssessment(true);
                }
              }
            }
          }
        }
      } else {
        const res = await getNotiAdminUnReadAll();
        if (!res._error) {
          setNotiUnread(res.data);
          dispatch(updateCount(res.data.length));
        }
        const resOnline = await getNotiAdminUnApproveOnlineAll();
        if (!resOnline._error) {
          dispatch(updateOnlineCount(resOnline.data));
        }
        let countOnsite = 0;
        const resOnsiteGo = await getNotiAdminUnApproveOnsiteGo();
        if (!resOnsiteGo._error) {
          countOnsite = countOnsite + resOnsiteGo.data;
          dispatch(updateOnsiteGoCount(resOnsiteGo.data));
        }
        const resOnsite = await getNotiAdminUnApproveOnsite();
        if (!resOnsite._error) {
          countOnsite = countOnsite + resOnsite.data;
          dispatch(updateOnsiteRsCount(resOnsite.data));
        }
        dispatch(updateOnsiteCount(countOnsite));
      }
    }
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 20,
          },
        }
      : null;

  // const showDrawer = () => setVisible(true);
  // const hideDrawer = () => setVisible(false);
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const openProfile = () => {
    const values = {
      firstName: stName,
      nickname: stNickName,
      lastName: stLastName,
      mobile: stUserMobile,
    };
    formProfile.setFieldsValue(values);
    setIsModalProfile(true);
  };
  const userLogout = () => {
    localStorage.removeItem("@userJwt");
    localStorage.removeItem("@userRefreshToken");
    localStorage.removeItem("@userName");
    localStorage.removeItem("@userNickname");
    localStorage.removeItem("@userLastname");
    localStorage.removeItem("@userId");
    localStorage.removeItem("@userRole");
    localStorage.removeItem("@userAvatar");
    window.location = "/home";
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  const showModal = () => {
    //setIsModalVisible(true);
    dispatch(updateIsShowLoginModal(true));
  };

  const handleCancel = () => {
    form.resetFields();
    //setIsModalVisible(false);
    dispatch(updateIsShowLoginModal(false));
  };

  const onFinish = async (values) => {
    setLoginError("");
    const data = {
      username: values.username,
      password: values.password,
    };

    var reg = /^\d+$/;
    if (!reg.test(values.username)) {
      //admin
      const res = await adminSignIn(data);
      if (!res._error) {
        message.success("ยินดีต้อนรับ");
        window.location.reload(false);
      } else {
        const errorCode = res._error_data.response.data.code;
        if (errorCode === "00400") {
          setLoginError("ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง!");
        }
        if (errorCode === "00404") {
          setLoginError(
            "ชื่อผู้ใช้งานนี้ถูกปิดการใช้งาน กรุณาติดต่อเจ้าหน้าที่!"
          );
        }
      }
    } else {
      //student
      const res = await signIn(data);
      if (!res._error) {
        message.success("ยินดีต้อนรับ");
        window.location.reload(false);
      } else {
        const errorCode = res._error_data.response.data.code;
        if (errorCode === "00400") {
          setLoginError("ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง!");
        }
        if (errorCode === "00404") {
          setLoginError(
            "ชื่อผู้ใช้งานนี้ถูกปิดการใช้งาน กรุณาติดต่อเจ้าหน้าที่!"
          );
        }
      }
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const readNotification = async (id) => {
    const data = { id: id };
    await readNotificationByNotiId(data);
    initData();
  };
  const menu = (
    <List
      min-width="100%"
      className="header-notifications-dropdown "
      itemLayout="horizontal"
      dataSource={notifiUnread}
      size="small"
      header={
        <center>
          <div style={{ textAlign: "center", margin: 10 }}>
            <Link to={"/notifications"}>
              <BellOutlined /> คลิกดูแจ้งเตือนทั้งหมด
            </Link>
          </div>
        </center>
      }
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar shape="square" src={item.icon} />}
            title={
              <Paragraph
                onClick={() => readNotification(item.id)}
                ellipsis={{ rows: 1 }}
              >
                <Link to={item.url || "/"}>{item.title}</Link>
              </Paragraph>
            }
            description={
              <Paragraph type="secondary" ellipsis={{ rows: 2 }}>
                {item.description}
              </Paragraph>
            }
          />
        </List.Item>
      )}
    />
  );
  const usermenu = (
    <List
      min-width="100%"
      className="header-notifications-dropdown "
      itemLayout="horizontal"
    >
      <List.Item onClick={() => openProfile()}>
        <List.Item.Meta
          avatar={<UserOutlined />}
          title={<a onClick={(e) => e.preventDefault()}>แก้ไขโปรไฟล์</a>}
        />
      </List.Item>
      <List.Item onClick={() => userLogout()}>
        <List.Item.Meta
          avatar={<LogoutOutlined />}
          title={<a onClick={(e) => e.preventDefault()}>ออกจากระบบ</a>}
        />
      </List.Item>
    </List>
  );

  const mapToThai = (Lookupkey) => {
    const dataMap = [
      { key: "Pages", value: "หน้าหลัก" },
      { key: "Basic-Data", value: "ข้อมูลพื้นฐาน" },
      { key: "Field", value: "ข้อมูลสาขา" },
      { key: "Admin", value: "ข้อมูลเจ้าหน้าที่" },
      { key: "Teacher", value: "ข้อมูลวิปัสสนาจารย์" },
      { key: "prefix", value: "ข้อมูลคำนำหน้าชื่อ" },
      { key: "graduate-type", value: "ข้อมูลประเภทบัณฑิต" },
      { key: "users", value: "ข้อมูลนิสิต" },
      { key: "import", value: "นำเข้า" },
      { key: "home", value: "หน้าหลัก" },
      { key: "Dharma-Online", value: "ปฏิบัติธรรมออนไลน์" },
      { key: "dharma-online-data", value: "ข้อมูลปฏิบัติธรรมออนไลน์" },
      { key: "dharma-online-checker", value: "ตรวจสอบปฏิบัติธรรมออนไลน์" },
      { key: "dharma-online-report", value: "รายงานปฏิบัติธรรมออนไลน์" },
      {
        key: "dharma-online-report-user",
        value: "รายงานประวัติปฏิบัติธรรมออนไลน์รายบุคคุล",
      },
      { key: "dharma-online-detail", value: "รายละเอียดปฏิบัติธรรมออนไลน์" },
      {
        key: "dharma-online-user-checker",
        value: "ตรวจสอบผลรายงานปฏิบัติธรรมออนไลน์",
      },
      { key: "Dharma-Onsite", value: "ปฏิบัติธรรมในสถานที่" },
      { key: "dharma-onsite-data", value: "ข้อมูลปฏิบัติธรรมในสถานที่" },
      {
        key: "dharma-onsite-checker",
        value: "ตรวจสอบผลรายงานปฏิบัติธรรมในสถานที่",
      },
      { key: "dharma-onsite-report", value: "รายงานปฏิบัติธรรมในสถานที่" },
      {
        key: "Dharma-Onsite-Report-User",
        value: "ประวัติปฏิบัติธรรมในสถานที่รายบุคคุล",
      },
      { key: "dharma-onsite-detail", value: "รายละเอียดปฏิบัติธรรมในสถานที่" },
      {
        key: "dharma-onsite-user-checker",
        value: "ตรวจสอบผลรายงานปฏิบัติธรรมในสถานที่",
      },
      {
        key: "Dharma-Onsite-Gochecker",
        value: "อนุมัติคำขอเข้าร่วมปฏิบัติธรรมในสถานที่",
      },
      { key: "dharma-result", value: "สรุปผลปฏิบัติธรรม" },
      { key: "notifications", value: "การแจ้งเตือน" },
      { key: "banner", value: "ข้อมูลแบนเนอร์" },
      { key: "rooms", value: "สถานที่ปฏิบัติธรรม" },
      { key: "assessment", value: "ข้อมูลแบบประเมิน" },
      { key: "Assessment-Data", value: "จัดการแบบประเมิน" },
      { key: "Assessment-Create", value: "สร้างแบบประเมินใหม่" },
      { key: "Assessment-Update", value: "แก้ไขแบบประเมิน" },
      { key: "dharma-onyear", value: "ปฏิบัติธรรมประจำปี" },
      { key: "dharma-onyear-data", value: "ข้อมูลปฏิบัติธรรมประจำปี" },
      {
        key: "dharma-onyear-register",
        value: "ข้อมูลลงทะเบียนปฏิบัติธรรมประจำปี",
      },
      {
        key: "dharma-onyear-checker",
        value: "ตรวจสอบผลเข้าร่วมปฏิบัติธรรมประจำปี",
      },
      {
        key: "dharma-onyear-result-checker",
        value: "ตรวจสอบผลเข้าร่วมปฏิบัติธรรมประจำปี",
      },
      {
        key: "dharma-onyear-discussion",
        value: "ประชุมสรุปผลปฏิบัติธรรมประจำปี",
      },
      {
        key: "dharma-onyear-result-discussion",
        value: "ประชุมสรุปผลปฏิบัติธรรมประจำปี",
      },
      {
        key: "Dharma-Onyear-User-Checker",
        value: "ตรวจสอบผลรายงานปฏิบัติธรรมประจำปี",
      },
      {
        key: "Dharma-Onyear-Detail",
        value: "รายละเอียดปฏิบัติธรรมประจำปี",
      },
      {
        key: "user-compensate",
        value: "ปลดล็อคบัญชีผู้ใช้",
      },
      {
        key: "assessment-results-list",
        value: "รายงานผลการทำแบบประเมิน",
      },
      {
        key: "assessment-results-checker",
        value: "รายงานผลการทำแบบประเมิน",
      },
      {
        key: "certificate",
        value: "รายการวุฒิบัตร",
      },
      {
        key: "dharma-onyear-report-user",
        value: "ประวัติปฏิบัติธรรมประจำปีรายบุคคุล",
      },
    ];
    const result = dataMap.find(
      ({ key }) => key.toLowerCase() === Lookupkey.toLowerCase()
    );
    if (result) {
      return result.value;
    }
    return Lookupkey;
  };

  const handleEditOk = () => {
    setLoading(true);
    formProfile
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("firstName", values.firstName);
        formData.append("nickname", values.nickname || "");
        formData.append("lastName", values.lastName || "");
        formData.append("mobile", values.mobile || "");
        if (
          userRole === "admin" ||
          userRole === "subadmin" ||
          userRole === "secretary"
        ) {
          const res = await updateProfile(formData);
          if (!res._error) {
            localStorage.setItem("@userName", res.data.firstName);
            localStorage.setItem("@userNickname", res.data.nickname);
            localStorage.setItem("@userLastname", res.data.lastName || "");
            localStorage.setItem("@userMobile", res.data.mobile || "");
            localStorage.setItem("@userAvatar", res.data.avatar || "none");
            setIsModalProfile(false);
            setImageFiles(null);
            formProfile.resetFields();
          }
        } else if (userRole === "user") {
          const res = await updateUserProfile(formData);
          if (!res._error) {
            localStorage.setItem("@userName", res.data.firstName);
            localStorage.setItem("@userNickname", res.data.nickname);
            localStorage.setItem("@userLastname", res.data.lastName || "");
            localStorage.setItem("@userMobile", res.data.mobile || "");
            localStorage.setItem("@userAvatar", res.data.avatar || "none");
            setIsModalProfile(false);
            setImageFiles(null);
            formProfile.resetFields();
          }
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* <div className="setting-drwer" onClick={showDrawer}>
        {setting}
      </div> */}
      <Row gutter={[24, 0]}>
        <Col span={24} md={12}>
          <Breadcrumb>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              <NavLink to={`/${name}`}>
                {mapToThai(Capitalize(name.replace("/", "")))}
              </NavLink>
            </Breadcrumb.Item>
            {subName !== "" && (
              <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
                <NavLink to={`/${name}/${subName}`}>
                  {mapToThai(Capitalize(subName.replace("/", "")))}
                </NavLink>
              </Breadcrumb.Item>
            )}
            {SubPathLv2 !== "" && (
              <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
                <NavLink to={`/${name}/${subName}/${SubPathLv2}`}>
                  {mapToThai(Capitalize(SubPathLv2.replace("/", "")))}
                </NavLink>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </Col>
        <Col span={24} md={12} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          {!userJwt && (
            <Button onClick={showModal} size={"small"}>
              {profile}
              <span>เข้าสู่ระบบ</span>
            </Button>
          )}

          {userJwt && (
            <>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button>
                  <Badge size="small" count={notificationCount || 0}>
                    <a
                      href="#pablo"
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {bell}
                    </a>
                  </Badge>
                </Button>
              </Dropdown>
              <Dropdown overlay={usermenu} trigger={["click"]}>
                <Button>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {userAvatar === "none" && <UserOutlined />}
                      {userAvatar !== "none" && (
                        <Avatar src={userAvatar} size={"small"} />
                      )}
                      {userName}
                    </Space>
                  </a>
                </Button>
              </Dropdown>
            </>
          )}
          <Modal
            title="เข้าสู่ระบบ"
            visible={isShowLoginModal}
            onCancel={handleCancel}
            footer={null}
            centered
          >
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="username"
                label="ชื่อผู้ใช้งาน"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุชื่อผู้ใช้งาน!",
                  },
                ]}
              >
                <Input
                  placeholder="ระบุชื่อผู้ใช้งาน"
                  onChange={() => setLoginError("")}
                />
              </Form.Item>

              <Form.Item
                className="username"
                label="รหัสผ่าน"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุรหัสผ่าน!",
                  },
                ]}
              >
                <Input
                  placeholder="ระบุรหัสผ่าน"
                  type={"password"}
                  onChange={() => setLoginError("")}
                />
              </Form.Item>
              {loginError.length > 0 && (
                <Alert message={loginError} type="error" showIcon />
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<LoginOutlined />}
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="โปรไฟล์"
            visible={isModalProfile}
            onOk={handleEditOk}
            onCancel={() => {
              formProfile.resetFields();
              setIsModalProfile(false);
              setImageFiles(null);
              setImageShow(
                localStorage.getItem("@userAvatar") !== "none"
                  ? localStorage.getItem("@userAvatar")
                  : null
              );
            }}
            confirmLoading={loading}
            closable={false}
            maskClosable={false}
            footer={[
              <Button
                icon={<CloseCircleOutlined />}
                key="back"
                onClick={() => {
                  formProfile.resetFields();
                  setIsModalProfile(false);
                  setImageFiles(null);
                  setImageShow(
                    localStorage.getItem("@userAvatar") !== "none"
                      ? localStorage.getItem("@userAvatar")
                      : null
                  );
                }}
                disabled={loading}
              >
                ยกเลิก
              </Button>,
              <Button
                icon={<CheckCircleOutlined />}
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleEditOk}
              >
                ยืนยันแก้ไขข้อมูล
              </Button>,
            ]}
          >
            <>
              <Form
                {...formItemLayout}
                layout={formLayout}
                form={formProfile}
                initialValues={{
                  layout: "vertical",
                  role: "เจ้าหน้าที่",
                  isActive: true,
                }}
              >
                {ImageShow == null && (
                  <center>
                    <Avatar
                      shape={"square"}
                      size={140}
                      style={{
                        alignSelf: "center",
                        backgroundColor: "#cccccc",
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                      icon={<UserOutlined />}
                    />
                  </center>
                )}
                {ImageShow !== null && (
                  <center>
                    <Avatar
                      shape={"square"}
                      size={140}
                      style={{
                        alignSelf: "center",
                        backgroundColor: "#cccccc",
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                      src={
                        <Image
                          src={ImageShow}
                          style={{
                            width: 140,
                            height: 140,
                            objectFit: "cover",
                          }}
                        />
                      }
                    />
                  </center>
                )}
                <Form.Item label="โปรไฟล์" name="avatar">
                  <Input
                    type={"file"}
                    placeholder="ระบุไฟล์รูปภาพ"
                    accept="image/jpeg, image/png"
                    onChange={onImageChange}
                  />
                </Form.Item>
                <Form.Item
                  label="ชื่อ"
                  name="firstName"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุชื่อ!",
                    },
                  ]}
                >
                  <Input placeholder="ระบุชื่อ" />
                </Form.Item>
                <Form.Item label="ฉายา" name="nickname">
                  <Input placeholder="ระบุฉายา" />
                </Form.Item>
                <Form.Item label="นามสกุล" name="lastName">
                  <Input placeholder="ระบุนามสกุล" />
                </Form.Item>
                <Form.Item label="เบอร์มือถือ" name="mobile">
                  <Input
                    placeholder="ระบุเบอร์มือถือ"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </Form>
            </>
          </Modal>
          <Modal
            title="บัญชีของท่านถูกล็อค"
            visible={isDoCompensate}
            onOk={() => userLogout()}
            onCancel={null}
            confirmLoading={loading}
            closable={false}
            maskClosable={false}
            cancelText={false}
            cancelButtonProps={{ style: { display: "none" } }}
            okText="ออกจากระบบ"
          >
            <div>
              <Alert
                message="เนื่องจากข้อมูลของท่านถูกรายงานเรื่องการซ่อมการปฏิบัติธรรม กรุณาติดต่อเจ้าหน้าที่เพื่อปลดล็อคบัญชี"
                type="error"
              />
              <List size="small" style={{ marginTop: 10 }}>
                <Typography.Text><b>รายชื่อเจ้าหน้าที่</b></Typography.Text>
                <Row gutter={[24, 0]} style={{ marginTop: 10 }}>
                  {adminActiveList.map((v) => (
                    <Col
                      key={v.id}
                      xs={24}
                      md={12}
                      style={{ marginBottom: 10 }}
                    >
                      <Avatar
                        style={{ marginLeft: 4, marginRight: 4 }}
                        src={v.avatar}
                      />
                      <Typography.Text>
                        {v.firstName} {v.nickname} {v.lastName}
                      </Typography.Text>
                    </Col>
                  ))}
                </Row>
              </List>
            </div>
          </Modal>
          <Modal
            title="กรุณาทำแบบประเมิน"
            visible={isDoAssessment}
            onOk={null}
            onCancel={null}
            confirmLoading={loading}
            closable={false}
            maskClosable={false}
            footer={null}
          >
            <DoAssessment assessmentId={doAssessmentId} />
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default Header;
