import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Table, Form, Input } from "antd";
import { CheckCircleOutlined, ImportOutlined } from "@ant-design/icons";
import { Modal, Spin, Space, Alert } from "antd";
import { getAllUsers, importUsers } from "../services/UsersServices";
import { getAllPrefixs } from "../services/PrefixsService";
import { getAllGraduateTypes } from "../services/GraduateTypesService";
import { getAllFields } from "../services/FieldsService";
import { getAllDatas } from "../services/TeacherService";
import ExampleCsv from "../assets/files/ImportStudentsCSV.csv";
import Item from "antd/lib/list/Item";

function ImportUsers() {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isPassCheck, setIsPassCheck] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [allPrefixs, setAllPrefixs] = useState([]);
  const [allGraduateTypes, setAllGraduateTypes] = useState([]);
  const [allField, setAllField] = useState([]);
  const [allTeacher, setAllTeacher] = useState([]);
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [proceesAlert, setProcessAlert] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    // todo
    // get all User to checker
    getAllUser();
  }, []);

  const getAllUser = async () => {
    const res = await getAllUsers();
    if (!res._error) {
      setAllUser(res.data);
    }
    const prefix = await getAllPrefixs();
    if (!prefix._error) {
      setAllPrefixs(prefix.data);
    }
    const graduateTypes = await getAllGraduateTypes();
    if (!graduateTypes._error) {
      setAllGraduateTypes(graduateTypes.data);
    }
    const field = await getAllFields();
    if (!field._error) {
      setAllField(field.data);
    }
    const teachers = await getAllDatas();
    if (!teachers._error) {
      setAllTeacher(teachers.data);
    }
  };

  const fileReader = new FileReader();

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    const checkNullRow = csvRows.filter((v) => v.split(",").length > 2);
    const array = checkNullRow.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header.trim()] = values[index].trim();
        return object;
      }, {});
      return obj;
    });

    proccesingData(csvHeader, array);
  };

  const proccesingData = (csvHeader, arrayData) => {
    // Check header file
    var headerAlert = [];
    var headerErrorCount = 0;
    if (csvHeader.length !== 20) {
      headerErrorCount++;
      headerAlert.push(
        `จำนวนคอลัมนไม่ถูกต้อง (คาดหวัง 20 : ผลลัพธ์ ${csvHeader.length})`
      );
    } else {
      const checkFields = [
        "code",
        "prefixId",
        "firstName",
        "nickname",
        "lastName",
        "mobile",
        "email",
        "graduateTypeId",
        "fieldId",
        "educateYear",
        "numOfDharmaMust",
        "numOfDharma",
        "numOfDharmaCompensate",
        "advisorId",
        "address",
        "road",
        "subdistrict",
        "district",
        "province",
        "postcode",
      ];
      for (let i = 0; i < csvHeader.length; i++) {
        if (csvHeader[i].trim() !== checkFields[i]) {
          headerErrorCount++;
          headerAlert.push(
            `ชื่อคอลัมน์ที่ ${i + 1} ไม่ถูกต้อง (คาดหวัง ${
              checkFields[i]
            } : ผลลัพธ์ ${csvHeader[i]})`
          );
        }
      }
    }
    if (headerErrorCount !== 0) {
      setProcessAlert(headerAlert);
      setLoading(false);
      return false;
    }

    var dataAlert = [];
    var dataErrorCount = 0;
    for (let d = 0; d < arrayData.length; d++) {
      var reg = /^\d+$/;
      if (!reg.test(arrayData[d].code)) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"code"} ชนิดของข้อมูลไม่ถูกต้อง [${arrayData[d].code}]`
        );
      }
      const checkUser = allUser.filter(
        (v) => v.users_code === arrayData[d].code.trim()
      );
      if (checkUser.length > 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"code"} รหัสนักศึกษานี้มีในระบบอยู่แล้ว [${
            arrayData[d].code
          }]`
        );
      }
      if (arrayData[d].prefixId.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"prefixId"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].prefixId
          }]`
        );
      }
      const prefixs = allPrefixs.filter(
        (v) => v.id === Number(arrayData[d].prefixId.trim())
      );
      if (prefixs.length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"prefixId"}  ไม่พบรหัสคำนำหน้านี้ [${
            arrayData[d].prefixId
          }]`
        );
      }
      if (arrayData[d].firstName.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"firstName"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].firstName
          }]`
        );
      }
      if (arrayData[d].graduateTypeId.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"graduateTypeId"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].graduateTypeId
          }]`
        );
      }
      const graduateTypes = allGraduateTypes.filter(
        (v) => v.id === Number(arrayData[d].graduateTypeId.trim())
      );
      if (graduateTypes.length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"graduateTypeId"} ไม่พบรหัสประเภทบัณฑิตนี้ [${
            arrayData[d].graduateTypeId
          }]`
        );
      }
      if (arrayData[d].fieldId.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"fieldId"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].fieldId
          }]`
        );
      }
      const fields = allField.filter(
        (v) => v.id === Number(arrayData[d].fieldId.trim())
      );
      if (fields.length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"fieldId"} ไม่พบรหัสสาขานี้ [${arrayData[d].fieldId}]`
        );
      }
      if (arrayData[d].educateYear.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"educateYear"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].educateYear
          }]`
        );
      }
      if (!reg.test(arrayData[d].educateYear)) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"educateYear"} ชนิดของข้อมูลไม่ถูกต้อง [${
            arrayData[d].educateYear
          }]`
        );
      }
      if (arrayData[d].numOfDharmaMust.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"numOfDharmaMust"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].numOfDharmaMust
          }]`
        );
      }
      if (!reg.test(arrayData[d].numOfDharmaMust.trim())) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"numOfDharmaMust"} ชนิดของข้อมูลไม่ถูกต้อง [${
            arrayData[d].numOfDharmaMust
          }]`
        );
      }
      if (arrayData[d].numOfDharma.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"numOfDharma"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].numOfDharma
          }]`
        );
      }
      if (!reg.test(arrayData[d].numOfDharma.trim())) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"numOfDharma"} ชนิดของข้อมูลไม่ถูกต้อง [${
            arrayData[d].numOfDharma
          }]`
        );
      }
      if (arrayData[d].numOfDharmaCompensate.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"numOfDharmaCompensate"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].numOfDharmaCompensate
          }]`
        );
      }
      if (!reg.test(arrayData[d].numOfDharmaCompensate.trim())) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"numOfDharmaCompensate"} ชนิดของข้อมูลไม่ถูกต้อง [${
            arrayData[d].numOfDharmaCompensate
          }]`
        );
      }
      if (arrayData[d].advisorId.trim().length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"advisorId"} จำนวนอักขระไม่ถูกต้อง [${
            arrayData[d].advisorId
          }]`
        );
      }
      const techs = allTeacher.filter(
        (v) => v.id === Number(arrayData[d].advisorId.trim())
      );
      if (techs.length <= 0) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"advisorId"} ไม่พบรหัสที่ปรึกษานี้ [${
            arrayData[d].advisorId
          }]`
        );
      }
    }
    if (dataErrorCount !== 0) {
      setProcessAlert(dataAlert);
      setLoading(false);
      return false;
    }
    setArray(arrayData);
    setLoading(false);
    setIsPassCheck(true);
  };

  const handleOnChange = (e) => {
    //setFile(e.target.files[0]);
    setVisible(true);
    setLoading(true);
    if (e.target.files[0]) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(e.target.files[0]);
    }
  };

  const handleCancel = () => {
    setProcessAlert([]);
    setIsPassCheck(false);
    setLoading(false);
    setVisible(false);
    form.resetFields();
  };

  const submitImportData = async () => {
    const data = {
      userList: array,
    };
    const res = await importUsers(data);
    if (!res._error) {
      window.location = "/basic-data/users";
    }
  };

  const renderHeaderColumn = (data) => {
    return (
      <>
        <div>{data.split(" ")[0]}</div>
        <div>{data.split(" ")[1]}</div>
      </>
    );
  };

  const columns = [
    {
      title: renderHeaderColumn(`รหัสนักศึกษา (code)`),
      dataIndex: "code",
      key: "code",
    },
    {
      title: renderHeaderColumn(`รหัสคำนำหน้า (prefixId)`),
      dataIndex: "prefixId",
      key: "prefixId",
    },
    {
      title: renderHeaderColumn(`ชื่อ (firstName)`),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: renderHeaderColumn(`ฉายา (nickname)`),
      dataIndex: "nickname",
      key: "nickname",
    },
    {
      title: renderHeaderColumn(`นามสกุล (lastName)`),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: renderHeaderColumn(`เบอร์โทร (mobile)`),
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: renderHeaderColumn(`อีเมล (email)`),
      dataIndex: "email",
      key: "email",
    },
    {
      title: renderHeaderColumn(`รหัสประเภทบัณฑิต (graduateTypeId)`),
      dataIndex: "graduateTypeId",
      key: "graduateTypeId",
    },
    {
      title: renderHeaderColumn(`รหัสสาขา (fieldId)`),
      dataIndex: "fieldId",
      key: "fieldId",
    },
    {
      title: renderHeaderColumn(`ปีการศึกษาที่เข้า (educateYear)`),
      dataIndex: "educateYear",
      key: "educateYear",
    },
    {
      title: renderHeaderColumn(
        `จำนวนวันปฏิบัติธรรมที่คาดหวัง (numOfDharmaMust)`
      ),
      dataIndex: "numOfDharmaMust",
      key: "numOfDharmaMust",
    },
    {
      title: renderHeaderColumn(`จำนวนวันปฏิบัติธรรมที่สำเร็จ (numOfDharma)`),
      dataIndex: "numOfDharma",
      key: "numOfDharma",
    },
    {
      title: renderHeaderColumn(
        `จำนวนวันปฏิบัติธรรมที่ต้องซ่อม (numOfDharmaCompensate)`
      ),
      dataIndex: "numOfDharmaCompensate",
      key: "numOfDharmaCompensate",
    },
    {
      title: renderHeaderColumn(`รหัสที่ปรึกษา (advisorId)`),
      dataIndex: "advisorId",
      key: "advisorId",
    },
    {
      title: renderHeaderColumn(`ที่อยู่ (address)`),
      dataIndex: "address",
      key: "address",
    },
    {
      title: renderHeaderColumn(`ถนน (road)`),
      dataIndex: "road",
      key: "road",
    },
    {
      title: renderHeaderColumn(`แขวง/ตำบล (subdistrict)`),
      dataIndex: "subdistrict",
      key: "subdistrict",
    },
    {
      title: renderHeaderColumn(`เขต/อำเภอ (district)`),
      dataIndex: "district",
      key: "district",
    },
    {
      title: renderHeaderColumn(`จังหวัด (province)`),
      dataIndex: "province",
      key: "province",
    },
    {
      title: renderHeaderColumn(`รหัสไปรษณีย์ (postcode)`),
      dataIndex: "postcode",
      key: "postcode",
    },
  ];
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={24}>
                        <h6 className="font-semibold m-0">
                          นำเข้าข้อมูลนิสิต (.csv)
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Row gutter={[24, 0]} className="">
                  <Col xs={24} md={12}>
                    <Form
                      // {...formItemLayout}
                      // layout={formLayout}
                      form={form}
                      initialValues={{
                        layout: "vertical",
                      }}
                    >
                      <Form.Item label="กรุณาเลือกไฟล์ (.csv)" name="file">
                        <Input
                          type={"file"}
                          placeholder="ระบุไฟล์ .csv"
                          accept={".csv"}
                          onChange={handleOnChange}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col xs={12} md={6}>
                    <a href={ExampleCsv} download="ExampleCsv" target="_blank">
                      <Button
                        block
                        type="default"
                        icon={<ImportOutlined />}
                        //onClick={submitImportData}
                      >
                        {"ดาวน์โหลดตัวอย่าง .csv"}
                      </Button>
                    </a>
                  </Col>
                  <Col xs={12} md={6}>
                    <Button
                      block
                      key="submit"
                      type="primary"
                      icon={<CheckCircleOutlined />}
                      onClick={submitImportData}
                      disabled={array.length <= 0}
                      loading={loading}
                    >
                      {"ยืนยันนำเข้าข้อมูล"}
                    </Button>
                  </Col>
                </Row>

                <Table
                  sticky
                  dataSource={array}
                  columns={columns}
                  scroll={{ x: 3800 }}
                  rowKey="code"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        centered
        width="80%"
        visible={visible}
        title={"นำเข้าข้อมูลนิสิต (.csv)"}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleCancel}
          >
            {loading ? "กำลังตรวจสอบ" : "ปิดผลการตรวจสอบ"}
          </Button>,
        ]}
      >
        {loading && (
          <p>
            <Space size="middle">
              <Spin size="small" />
            </Space>{" "}
            กรุณารอสักครู่ กำลังตรวจข้อมูล . . .
          </p>
        )}
        {!loading && <p>ผลการตรวจข้อมูล</p>}
        {proceesAlert.map((v, index) => (
          <Alert key={index} message={v} type="error" />
        ))}
        {isPassCheck && (
          <Alert
            message={
              "ข้อมูลพร้อมนำเข้า กรุณาตรวจสอบข้อมูลในตาราง และกดปุ่มยืนยันนำเข้าข้อมูล"
            }
            type="success"
          />
        )}
      </Modal>
    </>
  );
}

export default ImportUsers;
