import React, { useEffect, useState } from "react";
import { Button, Typography, Radio, Input, Divider } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import {
  getAllAssessmentById,
  sendAssessmentResults,
} from "../services/AssessmentService";
export default function DoAssessment({ assessmentId }) {
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allResultData, setAllResultData] = useState([]);
  const [assessmentName, setAssessmentName] = useState("");
  useEffect(() => {
    if (assessmentId && assessmentId !== 0) {
      initAllData();
    }
  }, [assessmentId]);

  const initAllData = async () => {
    setLoading(true);
    const res = await getAllAssessmentById(assessmentId);
    if (!res._error) {
      const mapping = [...res.data.questions];
      const result = mapping.map((v) => {
        return {
          assessmentId: v.assessmentId,
          assessmentQuestionId: v.id,
          ordering: v.ordering,
          result_type: v.result_type,
          result: v.result_type === "FIVESCORE" ? 5 : "",
        };
      });
      setAllData(res.data);
      setAllResultData(result);
      setAssessmentName(res.data.assessment.name);
    }
    setLoading(false);
  };

  const handleChangeFiveScroe = (questionId, value) => {
    const update = allResultData.map((v) => {
      if (v.assessmentQuestionId === questionId) {
        return {
          ...v,
          result: value,
        };
      }
      return v;
    });
    setAllResultData(update);
  };

  const handleTextChange = (questionId, value) => {
    const update = allResultData.map((v) => {
      if (v.assessmentQuestionId === questionId) {
        return {
          ...v,
          result: value,
        };
      }
      return v;
    });
    setAllResultData(update);
  };

  const renderQuestion = (v) => {
    return (
      <div key={v.id} style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 8 }}>{`ข้อที่ ${v.ordering + 1} ${
          v.title
        }`}</div>
        {v.result_type === "FIVESCORE" && (
          <div>
            <Radio.Group
              defaultValue={5}
              onChange={(e) => handleChangeFiveScroe(v.id, e.target.value)}
            >
              <Radio value={5}>มากที่สุด</Radio>
              <Radio value={4}>มาก</Radio>
              <Radio value={3}>ปานกลาง</Radio>
              <Radio value={2}>น้อย</Radio>
              <Radio value={1}>น้อยที่สุด</Radio>
            </Radio.Group>
          </div>
        )}
        {v.result_type === "STRING" && (
          <div>
            <Input onChange={(e) => handleTextChange(v.id, e.target.value)} />
          </div>
        )}
        {v.result_type === "TEXT" && (
          <div>
            <Input.TextArea
              rows={3}
              onChange={(e) => handleTextChange(v.id, e.target.value)}
            />
          </div>
        )}
        <Divider />
      </div>
    );
  };

  const sendResults = async () => {
    setLoading(true);
    const data = {
      assessmentId: assessmentId,
      results: allResultData,
    };
    const res = await sendAssessmentResults(data);
    if (!res._error) {
      window.location.reload(false);
    }
    setLoading(false);
  };
  return (
    <div>
      {/* <p>{JSON.stringify(allData)}</p> */}
      <Typography.Title level={5}>{assessmentName}</Typography.Title>
      {allData.assessment && (
        <p>
          <Typography.Text>
            คำชี้แจง: {allData.assessment.detail}
          </Typography.Text>
        </p>
      )}
      <Divider />
      {allData.questions && allData.questions.map((v) => renderQuestion(v))}
      <div>
        <Button
          onClick={() => sendResults()}
          loading={loading}
          disabled={loading}
          icon={<CheckCircleOutlined />}
          block
          type="primary"
        >
          ส่งแบบประเมิน
        </Button>
      </div>
    </div>
  );
}
