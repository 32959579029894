import React from "react";
import { Card, Input } from "antd";
const { Search } = Input;
export default function StudentCodeSearch({ isLoading = false, onSearch }) {
  return (
    <Card size="small">
      <Search
        addonBefore="รหัสนักศึกษา"
        enterButton="ค้นหา"
        size="large"
        loading={isLoading}
        onSearch={onSearch}
      />
    </Card>
  );
}
