import { RunAPI } from "../utils/RunApi";
import { APPCONFIG } from "../config/app.config";
import jwtDecode from "jwt-decode";

export const adminSignIn = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/admins/signin`;
 
  const apikey = `${APPCONFIG.APIKEY}`;
  const res = await RunAPI("POST", url, data, apikey);
  if (!res._error && res.data.token) {
    const JWT = res.data.token;
    const RefreshToken = res.data.refreshToken;
    const decoded = jwtDecode(JWT);
    localStorage.setItem("@userJwt", JWT);
    localStorage.setItem("@userRefreshToken", RefreshToken);
    localStorage.setItem("@userName", decoded.userName);
    localStorage.setItem("@userNickname", decoded.userNickname || "");
    localStorage.setItem("@userLastname", decoded.userLastname || "");
    localStorage.setItem("@userId", JSON.stringify(decoded.userId));
    localStorage.setItem("@userMobile", decoded.userMobile || "");
    localStorage.setItem("@userRole", decoded.userRole);
    localStorage.setItem("@userAvatar", decoded.userAvatar || "none");
  }
  return res;
};

export const signIn = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users/signin`;
  const apikey = `${APPCONFIG.APIKEY}`;
  const res = await RunAPI("POST", url, data, apikey);
  if (!res._error && res.data.token) {
    const JWT = res.data.token;
    const RefreshToken = res.data.refreshToken;
    const decoded = jwtDecode(JWT);
    localStorage.setItem("@userJwt", JWT);
    localStorage.setItem("@userRefreshToken", RefreshToken);
    localStorage.setItem("@userName", decoded.userName);
    localStorage.setItem("@userNickname", decoded.userNickname|| "");
    localStorage.setItem("@userLastname", decoded.userLastname|| "");
    localStorage.setItem("@userId", JSON.stringify(decoded.userId));
    localStorage.setItem("@userMobile", decoded.userMobile || "");
    localStorage.setItem("@userRole", "user");
    localStorage.setItem("@userAvatar", decoded.userAvatar || "none");
  }
  return res;
};
