import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Form,
  Switch,
  Select,
  Typography,
  Alert,
  Image,
} from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import { reportResultsByUserId } from "../../services/DharamaOnsiteResultsService";
import StudentCodeSearch from "../../components/StudentCodeSearch";
import moment from "moment";
function DharmaOnsiteUserReport() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const onSearch = async (data) => {
    const res = await reportResultsByUserId(data);
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const renderStatus = (statusText, adminNote = "") => {
    if (statusText === "รอตรวจสอบ" || statusText === "รอดำเนินการ") {
      return <Alert message="รอตรวจสอบ" type="warning" showIcon />;
    } else if (statusText === "อนุมัติ") {
      return (
        <>
          <Alert message="อนุมัติแล้ว" type="success" showIcon />
        </>
      );
    } else if (statusText === "ไม่อนุมัติ") {
      return (
        <>
          <Alert message="ไม่อนุมัติ" type="error" showIcon />
          {adminNote.trim().length > 0 && (
            <Alert
              style={{ whiteSpace: "pre-wrap" }}
              message={adminNote}
              type="error"
              showIcon
            />
          )}
        </>
      );
    }
  };

  const renderStatusgo = (statusText, adminNote = "") => {
    if (statusText === "รอตรวจสอบ") {
      return <Alert message="รอตรวจสอบ" type="warning" showIcon />;
    } else if (statusText === "อนุมัติ") {
      return (
        <>
          <Alert message="อนุมัติแล้ว" type="success" showIcon />
        </>
      );
    } else if (statusText === "ไม่อนุมัติ") {
      return (
        <>
          <Alert message="ไม่อนุมัติ" type="error" showIcon />
          {adminNote.trim().length > 0 && (
            <Alert
              style={{ whiteSpace: "pre-wrap" }}
              message={adminNote}
              type="error"
              showIcon
            />
          )}
        </>
      );
    }
  };

  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            aspectRatio: "16/9",
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 100,
          aspectRatio: "16/9",
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };

  const columns = [
    {
      title: "รหัสรายงาน",
      dataIndex: "dharma_onsite_results_id",
      width: 140,
      key: "dharma_onsite_results_id",
      ...getColumnSearchProps("dharma_onsite_results_id"),
      sorter: (a, b) => a.dharma_onsite_results_id - b.dharma_onsite_results_id,
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_id"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "ภาพปกกิจกรรม",
      dataIndex: "dharma_onsite_image",
      width: 140,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.dharma_onsite_image) : null,
    },
    {
      title: "หัวข้อกิจกรรม",
      width: 220,
      dataIndex: "dharma_onsite_name",
      key: "dharma_onsite_name",
      ...getColumnSearchProps("dharma_onsite_name"),
      sorter: (a, b) =>
        a.dharma_onsite_name.localeCompare(b.dharma_onsite_name),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานะการอนุมัติเข้าร่วม",
      width: 240,
      dataIndex: "dharma_onsite_results_statusgo",
      key: "dharma_onsite_results_statusgo",
      ...getColumnSearchProps("dharma_onsite_results_statusgo"),
      sorter: (a, b) =>
        a.dharma_onsite_results_statusgo.localeCompare(
          b.dharma_onsite_results_statusgo
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_statusgo"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        renderStatusgo(
          record.dharma_onsite_results_statusgo,
          record.dharma_onsite_results_admin_checkgo_note,
          record
        ),
    },
    {
      title: "สถานะการตรวจสอบรายงาน",
      width: 240,
      dataIndex: "dharma_onsite_results_status",
      key: "dharma_onsite_results_status",
      ...getColumnSearchProps("dharma_onsite_results_status"),
      sorter: (a, b) =>
        a.dharma_onsite_results_status.localeCompare(
          b.dharma_onsite_results_status
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_status"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        renderStatus(
          record.dharma_onsite_results_status,
          record.dharma_onsite_results_admin_note
        ),
    },
    {
      title: "จำนวนวันที่อนุมัติ",
      width: 200,
      dataIndex: "dharma_onsite_results_point_day",
      key: "dharma_onsite_results_point_day",
      ...getColumnSearchProps("dharma_onsite_results_point_day"),
      sorter: (a, b) =>
        a.dharma_onsite_results_point_day - b.dharma_onsite_results_point_day,
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_point_day"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        record.dharma_onsite_results_status === "อนุมัติ"
          ? record.dharma_onsite_results_point_day
          : "-",
    },
    {
      title: "รหัสนักศึกษา",
      width: 160,
      dataIndex: "users_code",
      key: "users_code",
      ...getColumnSearchProps("users_code"),
      sorter: (a, b) => a.users_code.localeCompare(b.users_code),
      sortOrder:
        sortedInfo.columnKey === "users_code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      width: 160,
      dataIndex: "users_firstName",
      key: "users_firstName",
      ...getColumnSearchProps("users_firstName"),
      sorter: (a, b) => a.users_firstName.localeCompare(b.users_firstName),
      sortOrder:
        sortedInfo.columnKey === "users_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      width: 160,
      dataIndex: "users_nickname",
      key: "users_nickname",
      ...getColumnSearchProps("users_nickname"),
      sorter: (a, b) => a.users_nickname.localeCompare(b.users_nickname),
      sortOrder:
        sortedInfo.columnKey === "users_nickname" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      width: 160,
      dataIndex: "users_lastName",
      key: "users_lastName",
      ...getColumnSearchProps("users_lastName"),
      sorter: (a, b) => a.users_lastName.localeCompare(b.users_lastName),
      sortOrder:
        sortedInfo.columnKey === "users_lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วันเวลาที่รายงาน",
      width: 240,
      dataIndex: "dharma_onsite_results_created_dt",
      key: "dharma_onsite_results_created_dt",
      ...getColumnSearchProps("dharma_onsite_results_created_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_created_dt.localeCompare(
          b.dharma_onsite_results_created_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_created_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onsite_results_created_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาที่รายงานล่าสุด",
      width: 240,
      dataIndex: "dharma_onsite_results_updated_dt",
      key: "dharma_onsite_results_updated_dt",
      ...getColumnSearchProps("dharma_onsite_results_updated_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_updated_dt.localeCompare(
          b.dharma_onsite_results_updated_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_updated_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onsite_results_updated_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "เจ้าหน้าที่ตรวจสอบล่าสุด",
      width: 220,
      dataIndex: "admins_firstName",
      key: "admins_firstName",
      ...getColumnSearchProps("admins_firstName"),
      sorter: (a, b) => a.admins_firstName.localeCompare(b.admins_firstName),
      sortOrder:
        sortedInfo.columnKey === "admins_firstName" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => record.admins_firstName || "-",
    },
    {
      title: "วันเวลาเจ้าหน้าที่ตรวจสอบล่าสุด",
      width: 240,
      dataIndex: "dharma_onsite_results_admin_check_dt",
      key: "dharma_onsite_results_admin_check_dt",
      ...getColumnSearchProps("dharma_onsite_results_admin_check_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_admin_check_dt.localeCompare(
          b.dharma_onsite_results_admin_check_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_admin_check_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        record.dharma_onsite_results_admin_check_dt
          ? moment(record.dharma_onsite_results_admin_check_dt)
              .add(543, "year")
              .format("DD-MM-YYYY HH:mm")
          : "-",
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          รายงานประวัติปฏิบัติธรรมในสถานที่รายบุคคุล
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <StudentCodeSearch isLoading={loading} onSearch={onSearch} />
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 1800 }}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnsiteUserReport;
