import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";

export const getAllDatas = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/teachers`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createDatas = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/teachers`;
  const res = await RunApiRefreshTokenMultipart("POST", url, data);
  return res;
};

export const updateDatas = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/teachers`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};
