import React, { useState } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  Card,
  List,
  Divider,
  Typography,
} from "antd";
import { createAssessment } from "../../services/AssessmentService";

const { Option } = Select;

const AssessmentCreate = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    console.log("Received values of form:", values);
    const res = await createAssessment(values);
    if (!res._error) {
      window.location = "/assessment/assessment-data";
    }
    setLoading(false);
  };

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} md={24}>
        <Row gutter={[24, 0]}>
          <Col xs={24} className="mb-24">
            <Card
              className="header-solid h-full ant-card-p-0 "
              title={
                <>
                  <Row
                    gutter={[24, 0]}
                    className="ant-row-flex ant-row-flex-middle"
                  >
                    <Col xs={24} md={12}>
                      <h6 className="font-semibold m-0">สร้างแบบประเมินใหม่</h6>
                    </Col>
                  </Row>
                </>
              }
            >
              <Form
                form={form}
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                autoComplete="off"
                layout={"vertical"}
              >
                <Form.Item
                  name="name"
                  label="ชื่อแบบประเมิน"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุชื่อแบบประเมิน!",
                    },
                  ]}
                >
                  <Input placeholder="ระบุชื่อแบบประเมิน" />
                </Form.Item>
                <Form.Item name="detail" label="คำชี้แจง (ถ้ามี)">
                  <Input.TextArea rows={4} placeholder="ระบุคำชี้แจง (ถ้ามี)" />
                </Form.Item>
                <Divider />
                <Form.List name="questions">
                  {(fields, { add, remove, move }) => (
                    <>
                      {fields.map((field, index) => (
                        <>
                          <Card
                            key={index}
                            title={`คำถามข้อที่ ${index + 1}`}
                            headStyle={{ margin: 0, paddingBottom: 0 }}
                            bodyStyle={{ margin: 0, paddingTop: 0 }}
                            extra={
                              <Space>
                                {index > 0 && fields.length > 1 && (
                                  <UpCircleOutlined
                                    onClick={() => move(index, index - 1)}
                                  />
                                )}
                                {index !== fields.length - 1 && (
                                  <DownCircleOutlined
                                    onClick={() => move(index, index + 1)}
                                  />
                                )}
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              </Space>
                            }
                          >
                            <Row gutter={[24, 0]}>
                              <Col xs={24} md={24}>
                                <Form.Item
                                  {...field}
                                  label="คำถาม"
                                  name={[field.name, "question"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "กรุณาระบุคำถาม!",
                                    },
                                  ]}
                                >
                                  <Input placeholder="ระบุคำถาม" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <Form.Item
                                  {...field}
                                  label="ประเภทคำตอบ"
                                  name={[field.name, "result"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "กรุณาเลือกประเภทคำตอบ!",
                                    },
                                  ]}
                                >
                                  <Select
                                    size={"large"}
                                    placeholder={"กรุณาเลือกประเภทคำตอบ"}
                                  >
                                    <Option value={"FIVESCORE"}>
                                      {"แบบคะแนนห้าระดับ"}
                                    </Option>
                                    <Option value={"STRING"}>
                                      {"แบบข้อความสั้น"}
                                    </Option>
                                    <Option value={"TEXT"}>
                                      {"แบบข้อความยาว"}
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                          <Divider />
                        </>
                      ))}

                      <Form.Item style={{ marginTop: 20 }}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          เพิ่มคำถามแบบประเมิน
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button
                    loading={loading}
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    ยืนยันสร้างแบบประเมิน
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AssessmentCreate;
