import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const getAllPrefixs = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/prefixs`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createPrefix = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/prefixs`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const updatePrefix = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/prefixs`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};
