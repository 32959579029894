import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const getUserCheckAssessment = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/assessment/user-check`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getAllAssessment = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/assessment`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createAssessment = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/assessment`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const getAllAssessmentByUuid = async (assessmentUuid) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/assessment/request/${assessmentUuid}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getAllAssessmentById = async (assessmentId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/assessment/request-id/${assessmentId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const sendAssessmentResults = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/assessment-result`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const getAssessmentOverAllResults = async (assessmentId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/assessment-result/overall/${assessmentId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};
