import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Typography,
  Image,
  Alert,
  Radio,
  List,
} from "antd";
import {
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import {
  findAllResultsByAdmin,
  adminSendCheckerResult,
} from "../../services/DharamaOnlineResultsService";
import moment from "moment";
import {
  walkRound,
  walkTime,
  sitRound,
  sitTime,
  speak,
  actions,
  solveProblem,
  progress,
} from "../../constants/dharmaOnline.constants";
import { NotificationFetch } from "../../utils/NotificationFetch";

const { TextArea } = Input;

function DharmaOnlineChecker() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [dharmaOnlineTitle, setDharmaOnlineTitle] = useState(null);
  const [dharmaOnlineStartEnd, setDharmaOnlineStartEnd] = useState(null);
  const [dharmaOnlineResultStatus, setDharmaOnlineResultStatus] =
    useState(null);
  const [dharmaOnlineResultNote, setDharmaOnlineResultNote] = useState(null);
  const [adminApprove, setAdminApprove] = useState(null);
  const [adminApproveTime, setAdminApproveTime] = useState(null);
  const [adminApproveNote, setAdminApproveNote] = useState(null);

  const [userInfoCode, setUserInfoCode] = useState(null);
  const [userInfoName, setUserInfoName] = useState(null);
  const [userField, setUserField] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userCreatedDt, setUserCreatedDt] = useState(null);
  const [userUpdatedDt, setUserUpdatedDt] = useState(null);
  const [ImageShow, setImageShow] = useState(null);

  const [resWalkRound, setResWalkRound] = useState(null);
  const [resWalkTime, setResWalkTime] = useState(null);
  const [resSitRound, setResSitRound] = useState(null);
  const [resSitTime, setSitTime] = useState(null);
  const [resSpeak, setResSpeak] = useState(null);
  const [resActions, setResActions] = useState([]);
  const [resSolveProblem, setResSolveProblem] = useState(null);
  const [resProgress, setProgress] = useState(null);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [editId, setEditId] = useState(-1);

  const [formEdit] = Form.useForm();

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await findAllResultsByAdmin();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const openEdit = (data) => {
    setResWalkRound(
      walkRound.filter(
        (v) => v.id === Number(data.dharma_online_results_walkRound)
      )[0].value
    );
    setResWalkTime(
      walkTime.filter(
        (v) => v.id === Number(data.dharma_online_results_walkTime)
      )[0].value
    );
    setResSitRound(
      sitTime.filter(
        (v) => v.id === Number(data.dharma_online_results_sitRound)
      )[0].value
    );
    setSitTime(
      sitRound.filter(
        (v) => v.id === Number(data.dharma_online_results_sitTime)
      )[0].value
    );
    setResSpeak(
      speak.filter((v) => v.id === Number(data.dharma_online_results_speak))[0]
        .value
    );
    setResActions(data.dharma_online_results_actions.split(","));
    setResSolveProblem(
      solveProblem.filter(
        (v) => v.id === Number(data.dharma_online_results_solveProblem)
      )[0].value
    );
    setProgress(
      progress.filter(
        (v) => v.id === Number(data.dharma_online_results_progress)
      )[0].value
    );
    setDharmaOnlineResultStatus(data.dharma_online_results_status);
    const adminName = `${data.admins_firstName} ${data.admins_nickname || ""} ${
      data.admins_lastName || ""
    }`;
    setAdminApprove(adminName);
    setAdminApproveNote(data.dharma_online_results_admin_note);
    setAdminApproveTime(
      moment(data.dharma_online_results_admin_check_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm")
    );
    const startEndOnline = `ออนไลน์: ${moment(
      data.dharma_online_startOnlineTime
    )
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm")} ถึง ${moment(
      data.dharma_online_endOnlineTime
    )
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm")}`;
    const usersCode = `รหัสนักศึกษา: ${data.users_code} `;
    const usersName = `ชื่อ: ${data.users_firstName} ${data.users_nickname} ${data.users_lastName}`;
    const note =
      data.dharma_online_results_detail.length > 0
        ? data.dharma_online_results_detail
        : "-";
    setDharmaOnlineTitle(`กิจกรรม: ${data.dharma_online_name}`);
    setDharmaOnlineStartEnd(startEndOnline);
    setDharmaOnlineResultNote(`สรุปเพิ่มเติม : ${note}`);
    setUserInfoCode(usersCode);
    setUserInfoName(usersName);
    setUserType(`ประเภทนิสิต: ${data.graduate_types_name}`);
    setUserField(
      `สาขา: ${data.fields_name} ${data.fields_level} ${data.fields_type}`
    );
    setUserCreatedDt(
      `วันที่สร้างรายงาน: ${moment(data.dharma_online_results_created_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm")}`
    );
    setUserUpdatedDt(
      `วันที่รายงานล่าสุด: ${moment(data.dharma_online_results_updated_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm")}`
    );
    setEditId(data.dharma_online_results_id);
    setImageShow(data.dharma_online_results_image);
    setIsModalEditVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const renderStatus = (statusText, adminNote = "") => {
    if (statusText === "รอตรวจสอบ") {
      return <Alert message="รอตรวจสอบ" type="warning" showIcon />;
    } else if (statusText === "อนุมัติ") {
      return (
        <>
          <Alert message="อนุมัติแล้ว" type="success" showIcon />
        </>
      );
    } else if (statusText === "ไม่อนุมัติ") {
      return (
        <>
          <Alert message="ไม่อนุมัติ" type="error" showIcon />
          {adminNote.trim().length > 0 && (
            <Alert
              style={{ whiteSpace: "pre-wrap" }}
              message={adminNote}
              type="error"
              showIcon
            />
          )}
        </>
      );
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            aspectRatio: "16/9",
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 100,
          aspectRatio: "16/9",
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const renderImageSent = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            aspectRatio: "16/9",
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 100,
          aspectRatio: "16/9",
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "รหัสรายงาน",
      dataIndex: "dharma_online_results_id",
      width: 140,
      key: "dharma_online_results_id",
      ...getColumnSearchProps("dharma_online_results_id"),
      sorter: (a, b) => a.dharma_online_results_id - b.dharma_online_results_id,
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_id"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "ภาพปกกิจกรรม",
      dataIndex: "dharma_online_image",
      width: 140,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.dharma_online_image) : null,
    },
    {
      title: "หัวข้อกิจกรรม",
      width: 220,
      dataIndex: "dharma_online_name",
      key: "dharma_online_name",
      ...getColumnSearchProps("dharma_online_name"),
      sorter: (a, b) =>
        a.dharma_online_name.localeCompare(b.dharma_online_name),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานะการตรวจสอบ",
      width: 240,
      dataIndex: "dharma_online_results_status",
      key: "dharma_online_results_status",
      ...getColumnSearchProps("dharma_online_results_status"),
      sorter: (a, b) =>
        a.dharma_online_results_status.localeCompare(
          b.dharma_online_results_status
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_status"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        renderStatus(
          record.dharma_online_results_status,
          record.dharma_online_results_admin_note
        ),
    },
    {
      title: "ภาพประกอบที่เข้าร่วม",
      dataIndex: "dharma_online_image",
      width: 140,
      render: (_, record) =>
        allData.length >= 1
          ? renderImageSent(record.dharma_online_results_image)
          : null,
    },
    {
      title: "รหัสนักศึกษา",
      width: 160,
      dataIndex: "users_code",
      key: "users_code",
      ...getColumnSearchProps("users_code"),
      sorter: (a, b) => a.users_code.localeCompare(b.users_code),
      sortOrder:
        sortedInfo.columnKey === "users_code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      width: 160,
      dataIndex: "users_firstName",
      key: "users_firstName",
      ...getColumnSearchProps("users_firstName"),
      sorter: (a, b) => a.users_firstName.localeCompare(b.users_firstName),
      sortOrder:
        sortedInfo.columnKey === "users_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      width: 160,
      dataIndex: "users_nickname",
      key: "users_nickname",
      ...getColumnSearchProps("users_nickname"),
      sorter: (a, b) => a.users_nickname.localeCompare(b.users_nickname),
      sortOrder:
        sortedInfo.columnKey === "users_nickname" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      width: 160,
      dataIndex: "users_lastName",
      key: "users_lastName",
      ...getColumnSearchProps("users_lastName"),
      sorter: (a, b) => a.users_lastName.localeCompare(b.users_lastName),
      sortOrder:
        sortedInfo.columnKey === "users_lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วันเวลาที่รายงาน",
      width: 240,
      dataIndex: "dharma_online_results_created_dt",
      key: "dharma_online_results_created_dt",
      ...getColumnSearchProps("dharma_online_results_created_dt"),
      sorter: (a, b) =>
        a.dharma_online_results_created_dt.localeCompare(
          b.dharma_online_results_created_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_created_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_online_results_created_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาที่รายงานล่าสุด",
      width: 240,
      dataIndex: "dharma_online_results_updated_dt",
      key: "dharma_online_results_updated_dt",
      ...getColumnSearchProps("dharma_online_results_updated_dt"),
      sorter: (a, b) =>
        a.dharma_online_results_updated_dt.localeCompare(
          b.dharma_online_results_updated_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_updated_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_online_results_updated_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "เจ้าหน้าที่ตรวจสอบล่าสุด",
      width: 220,
      dataIndex: "admins_firstName",
      key: "admins_firstName",
      ...getColumnSearchProps("admins_firstName"),
      sorter: (a, b) => a.admins_firstName.localeCompare(b.admins_firstName),
      sortOrder:
        sortedInfo.columnKey === "admins_firstName" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => record.admins_firstName || "-",
    },
    {
      title: "วันเวลาเจ้าหน้าที่ตรวจสอบล่าสุด",
      width: 240,
      dataIndex: "dharma_online_results_admin_check_dt",
      key: "dharma_online_results_admin_check_dt",
      ...getColumnSearchProps("dharma_online_results_admin_check_dt"),
      sorter: (a, b) =>
        a.dharma_online_results_admin_check_dt.localeCompare(
          b.dharma_online_results_admin_check_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_admin_check_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        record.dharma_online_results_admin_check_dt
          ? moment(record.dharma_online_results_admin_check_dt)
              .add(543, "year")
              .format("DD-MM-YYYY HH:mm")
          : "-",
    },
    {
      title: "ตรวจสอบ",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"ตรวจสอบ"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const handleEditOk = () => {
    setLoading(true);
    formEdit
      .validateFields()
      .then(async (values) => {
        const data = {
          id: editId,
          status: values.status,
          admin_note: values.admin_note || "",
          point_day: values.point_day,
        };
        const res = await adminSendCheckerResult(data);
        var newData = res.data[0];
        if (!res._error) {
          const updateData = allData.map((item) => {
            if (item.dharma_online_results_id === editId) {
              return {
                ...item,
                ...newData,
              };
            }
            return item;
          });
          setAllData(updateData);
          setIsModalEditVisible(false);
          setImageShow(null);
          formEdit.resetFields();
          NotificationFetch();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const renderActionListSelected = () => {
    if (resActions[0] !== "") {
      return resActions
        .map((v) => {
          return actions[v - 1].value;
        })
        .join();
    }
    return "-";
  };
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ตรวจสอบผลรายงานปฏิบัติธรรมออนไลน์
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: "max-content" }}
                  sticky
                  rowKey="dharma_online_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>

              <Modal
                title="ตรวจสอบผลการเข้าร่วมปฏิบัติธรรมออนไลน์"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  formEdit.resetFields();
                  setIsModalEditVisible(false);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formEdit.resetFields();
                      setIsModalEditVisible(false);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    disabled={dharmaOnlineResultStatus === "อนุมัติ"}
                    onClick={handleEditOk}
                  >
                    ยืนยันผลการตรวจสอบ
                  </Button>,
                ]}
              >
                <>
                  <Row gutter={[24, 0]}>
                    <Col xs={24} md={12}>
                      <List
                        header={<div>{dharmaOnlineTitle}</div>}
                        //footer={<div>Footer</div>}
                        bordered
                        size="small"
                      >
                        <List.Item>{dharmaOnlineStartEnd}</List.Item>
                        <List.Item>{userInfoCode}</List.Item>
                        <List.Item>{userInfoName}</List.Item>
                        <List.Item>{userType}</List.Item>
                        <List.Item>{userField}</List.Item>
                        <List.Item>{userCreatedDt}</List.Item>
                        <List.Item>{userUpdatedDt}</List.Item>
                        <List.Item style={{ flexDirection: "column" }}>
                          {
                            <>
                              <div>รูปภาพประกอบกิจกรรม :</div>
                              <div>
                                {!ImageShow ? "ไม่พบ" : ""}
                                {ImageShow !== null && (
                                  <Image
                                    src={ImageShow}
                                    style={{
                                      height: 200,
                                      backgroundColor: "#CCCCCC",
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          }
                        </List.Item>

                        <List.Item>
                          จำนวนรอบในการเดินจงกรม: {resWalkRound}
                        </List.Item>
                        <List.Item>
                          เวลาเฉลี่ยในการเดินจงกรม : {resWalkTime}
                        </List.Item>
                        <List.Item>
                          จำนวนรอบในการนั่งสมาธิ : {resSitRound}
                        </List.Item>
                        <List.Item>
                          เวลาเฉลี่ยในการนั่งสมาธิ : {resSitTime}
                        </List.Item>
                        <List.Item>การปิดวาจา : {resSpeak}</List.Item>
                        <List.Item>
                          สภาวะการปฏิบัติประจำวัน : {renderActionListSelected()}
                        </List.Item>
                        <List.Item>
                          เมื่อเกิดปัญหาหรือข้อสงสัย : {resSolveProblem}
                        </List.Item>
                        <List.Item>ความก้าวหน้า : {resProgress}</List.Item>

                        <List.Item>{dharmaOnlineResultNote}</List.Item>
                      </List>

                      {/* <div>{userInfoName}</div>
                      <div>{userCreatedDt}</div>
                      <div>{userUpdatedDt}</div>
                      <div>
                        รูปภาพประกอบกิจกรรม : {!ImageShow ? "ไม่พบ" : ""}
                        {ImageShow !== null && (
                          <Image
                            src={ImageShow}
                            style={{
                              height: 200,
                              backgroundColor: "#CCCCCC",
                            }}
                          />
                        )}
                      </div> */}
                      {/* <div>{dharmaOnlineResultNote}</div> */}
                    </Col>
                    <Col xs={24} md={12}>
                      {dharmaOnlineResultStatus === "อนุมัติ" && (
                        <>
                          <Alert
                            message="อนุมัติแล้ว"
                            description={`เจ้าหน้าที่ได้อนุมัติผลการรายงานเข้าร่วมปฏิบัติธรรมออนไลน์นี้แล้ว โดย ${adminApprove} ล่าสุดเมื่อ ${adminApproveTime}`}
                            type="success"
                            showIcon
                          />
                        </>
                      )}
                      {dharmaOnlineResultStatus === "ไม่อนุมัติ" && (
                        <>
                          <Alert
                            message="ไม่อนุมัติ"
                            description={`เจ้าหน้าที่ไม่อนุมัติผลการรายงานเข้าร่วมปฏิบัติธรรมออนไลน์นี้ โดย ${adminApprove} ล่าสุดเมื่อ ${adminApproveTime} เนื่องจาก ${adminApproveNote}`}
                            type="error"
                            showIcon
                            style={{ marginBottom: 20 }}
                          />
                        </>
                      )}
                      {dharmaOnlineResultStatus !== "อนุมัติ" && (
                        <Form
                          layout={"vertical"}
                          form={formEdit}
                          initialValues={{
                            layout: "vertical",
                            status: "อนุมัติ",
                            point_day: 1,
                          }}
                        >
                          <Form.Item
                            label="ผลการตรวจสอบ"
                            name="status"
                            required
                          >
                            <Radio.Group>
                              <Radio value="อนุมัติ"> อนุมัติ </Radio>
                              <Radio value="ไม่อนุมัติ"> ไม่อนุมัติ </Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            label="จำนวนอนุมัติวันปฏิบัติธรรมออนไลน์ "
                            name="point_day"
                            required
                            rules={[
                              {
                                required: true,
                                message:
                                  "กรุณาระบุจำนวนอนุมัติวันปฏิบัติธรรมออนไลน์ !",
                              },
                            ]}
                          >
                            <Input
                              placeholder="ระบุจำนวนอนุมัติวันปฏิบัติธรรมออนไลน์"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                            />
                          </Form.Item>
                          <Form.Item
                            label="รายละเอียดเพิ่มเติม"
                            name="admin_note"
                          >
                            <TextArea
                              rows={4}
                              placeholder="ระบุรายละเอียดเพิ่มเติม"
                            />
                          </Form.Item>
                        </Form>
                      )}
                    </Col>
                  </Row>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnlineChecker;
