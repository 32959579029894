import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Typography,
  Image,
  DatePicker,
  Checkbox,
  Select,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { AdminChecker } from "../utils/AdminChecker";
import { getAllRooms, createRoom, updateRoom } from "../services/RoomsService";
import moment from "moment";
import { onSiteServices } from "../constants/dharmaOnsite.constants";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

function Rooms() {
  AdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [ImageFiles2, setImageFiles2] = useState(null);
  const [ImageShow2, setImageShow2] = useState(null);
  const [ImageFiles3, setImageFiles3] = useState(null);
  const [ImageShow3, setImageShow3] = useState(null);
  const [ImageFiles4, setImageFiles4] = useState(null);
  const [ImageShow4, setImageShow4] = useState(null);
  const [ImageFiles5, setImageFiles5] = useState(null);
  const [ImageShow5, setImageShow5] = useState(null);
  const [ImageFiles6, setImageFiles6] = useState(null);
  const [ImageShow6, setImageShow6] = useState(null);
  const [checkboxOnline, setCheckboxOnline] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [editPassword, setEditPassword] = useState(-1);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllRooms();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const handleSwitch = async (values, e) => {
    const formData = new FormData();
    formData.append("id", values.rooms_id);
    formData.append("name", values.rooms_name);
    formData.append("approveRoom", values.rooms_approveRoom);
    formData.append("building", values.rooms_building);
    formData.append("floor", values.rooms_floor);
    formData.append("maxSeats", values.rooms_maxSeats);
    formData.append("bookType", values.rooms_bookType || "ภายใน");
    formData.append("services", values.rooms_services || "");
    formData.append("detail", values.rooms_detail);
    formData.append("isActive", e);
    formData.append("isRemoveImage2", false);
    formData.append("isRemoveImage3", false);
    formData.append("isRemoveImage4", false);
    formData.append("isRemoveImage5", false);
    formData.append("isRemoveImage6", false);
    const res = await updateRoom(formData);
    var newData = res.data[0];
    if (!res._error) {
      const updateData = allData.map((item) => {
        if (item.rooms_id === values.rooms_id) {
          var userActive = false;
          if (e === true || e === 1) {
            userActive = true;
          }
          return { ...item, ...newData, rooms_isActive: userActive };
        }
        return item;
      });
      setAllData(updateData);
    }
  };

  const openEdit = (data) => {
    setEditId(data.rooms_id);
    setImageShow(data.rooms_image1);
    setImageShow2(data.rooms_image2);
    setImageShow3(data.rooms_image3);
    setImageShow4(data.rooms_image4);
    setImageShow5(data.rooms_image5);
    setImageShow6(data.rooms_image6);
    var userActive = false;
    if (data.rooms_isActive === true || data.rooms_isActive === 1) {
      userActive = true;
    }
    const servicesValue = data.rooms_services;
    var serviceVal = undefined;
    if (servicesValue.trim() !== "") {
      serviceVal = servicesValue.trim().split(",").map(Number);
    }
    const values = {
      layout: "vertical",
      name: data.rooms_name,
      approveRoom: data.rooms_approveRoom,
      building: data.rooms_building,
      floor: data.rooms_floor,
      maxSeats: data.rooms_maxSeats,
      services: serviceVal,
      detail: data.rooms_detail,
      bookType: data.rooms_bookType,
      isActive: userActive,
    };
    formEdit.setFieldsValue(values);
    setIsModalEditVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            height: 100,
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 100,
          height: 100,
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "ภาพสถานที่ปฏิบัติธรรม",
      width: 150,
      dataIndex: "rooms_image1",
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.rooms_image1) : null,
    },
    {
      title: "รหัส",
      width: 150,
      dataIndex: "rooms_id",
      key: "rooms_id",
      ...getColumnSearchProps("rooms_id"),
      sorter: (a, b) => a.rooms_id - b.rooms_id,
      sortOrder: sortedInfo.columnKey === "rooms_id" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "rooms_name",
      key: "rooms_name",
      ...getColumnSearchProps("rooms_name"),
      sorter: (a, b) => a.rooms_name.localeCompare(b.rooms_name),
      sortOrder:
        sortedInfo.columnKey === "rooms_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "อาคาร/ตึก/สถานที่",
      dataIndex: "rooms_building",
      key: "rooms_building",
      ...getColumnSearchProps("rooms_building"),
      sorter: (a, b) => a.rooms_building.localeCompare(b.rooms_building),
      sortOrder:
        sortedInfo.columnKey === "rooms_building" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชั้น",
      width: 150,
      dataIndex: "rooms_floor",
      key: "rooms_floor",
      ...getColumnSearchProps("rooms_floor"),
      sorter: (a, b) => a.rooms_floor.localeCompare(b.rooms_floor),
      sortOrder:
        sortedInfo.columnKey === "rooms_floor" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "จำนวนที่รองรับ",
      width: 150,
      dataIndex: "rooms_maxSeats",
      key: "rooms_maxSeats",
      ...getColumnSearchProps("rooms_maxSeats"),
      sorter: (a, b) => a.rooms_maxSeats - b.rooms_maxSeats,
      sortOrder:
        sortedInfo.columnKey === "rooms_maxSeats" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานะการใช้งาน",
      dataIndex: "rooms_isActive",
      sorter: (a, b) =>
        JSON.stringify(a.rooms_isActive).localeCompare(
          JSON.stringify(b.rooms_isActive)
        ),
      sortOrder:
        sortedInfo.columnKey === "rooms_isActive" ? sortedInfo.order : null,
      ellipsis: true,
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <Switch
            checkedChildren="เปิด "
            unCheckedChildren="ปิด "
            checked={record.rooms_isActive}
            onChange={(e) => handleSwitch(record, e)}
          />
        ) : null,
    },
    {
      title: "แก้ไข",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"แก้ไข"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const checkBoxHandler = (e) => {
    const valueReceive = e.target.value;
    const newData = checkboxOnline;
    const findValue = checkboxOnline.find(
      (element) => element === valueReceive.toString()
    );
    if (!findValue) {
      newData.push(valueReceive.toString());
      setCheckboxOnline(newData);
    } else {
      const update = checkboxOnline.filter(
        (element) => element !== valueReceive.toString()
      );
      setCheckboxOnline(update);
    }
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        var servicesSelected = "";
        if (checkboxOnline && checkboxOnline.length > 0) {
          servicesSelected = checkboxOnline.join();
        }
        const formData = new FormData();
        formData.append("image1", ImageFiles);
        formData.append("image2", ImageFiles2);
        formData.append("image3", ImageFiles3);
        formData.append("image4", ImageFiles4);
        formData.append("image5", ImageFiles5);
        formData.append("image6", ImageFiles6);
        formData.append("name", values.name);
        formData.append("approveRoom", values.approveRoom);
        formData.append("building", values.building || "");
        formData.append("floor", values.floor || "");
        formData.append("maxSeats", values.maxSeats);
        formData.append("detail", values.detail || "");
        formData.append("bookType", values.bookType);
        formData.append("services", servicesSelected);
        formData.append("isActive", values.isActive);
        const res = await createRoom(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          setImageFiles2(null);
          setImageShow2(null);
          setImageFiles3(null);
          setImageShow3(null);
          setImageFiles4(null);
          setImageShow4(null);
          setImageFiles5(null);
          setImageShow5(null);
          setImageFiles6(null);
          setImageShow6(null);
          setCheckboxOnline([]);
          form.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };
  const handleEditOk = () => {
    setLoading(true);
    formEdit
      .validateFields()
      .then(async (values) => {
        var servicesSelected = "";
        if (values.services && values.services.length > 0) {
          servicesSelected = values.services.join();
        }
        const formData = new FormData();
        formData.append("image1", ImageFiles);
        formData.append("image2", ImageFiles2);
        formData.append("image3", ImageFiles3);
        formData.append("image4", ImageFiles4);
        formData.append("image5", ImageFiles5);
        formData.append("image6", ImageFiles6);
        formData.append("id", editId);
        formData.append("name", values.name);
        formData.append("approveRoom", values.approveRoom);
        formData.append("building", values.building || "");
        formData.append("floor", values.floor || "");
        formData.append("maxSeats", values.maxSeats);
        formData.append("detail", values.detail || "");
        formData.append("bookType", values.bookType);
        formData.append("services", servicesSelected);
        formData.append("isActive", values.isActive);
        formData.append("isRemoveImage2", ImageShow2 ? false : true);
        formData.append("isRemoveImage3", ImageShow3 ? false : true);
        formData.append("isRemoveImage4", ImageShow4 ? false : true);
        formData.append("isRemoveImage5", ImageShow6 ? false : true);
        formData.append("isRemoveImage6", ImageShow6 ? false : true);
        const res = await updateRoom(formData);
        var newData = res.data[0];
        if (!res._error) {
          const updateData = allData.map((item) => {
            if (item.rooms_id === editId) {
              return {
                ...item,
                ...newData,
              };
            }
            return item;
          });
          setAllData(updateData);
          setIsModalEditVisible(false);
          setImageFiles(null);
          setImageShow(null);
          setImageFiles2(null);
          setImageShow2(null);
          setImageFiles3(null);
          setImageShow3(null);
          setImageFiles4(null);
          setImageShow4(null);
          setImageFiles5(null);
          setImageShow5(null);
          setImageFiles6(null);
          setImageShow6(null);
          formEdit.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const onImageChange = (event, id) => {
    if (event.target.files && event.target.files[0]) {
      if (id === 1) {
        setImageFiles(event.target.files[0]);
        setImageShow(URL.createObjectURL(event.target.files[0]));
      } else if (id === 2) {
        setImageFiles2(event.target.files[0]);
        setImageShow2(URL.createObjectURL(event.target.files[0]));
      } else if (id === 3) {
        setImageFiles3(event.target.files[0]);
        setImageShow3(URL.createObjectURL(event.target.files[0]));
      } else if (id === 4) {
        setImageFiles4(event.target.files[0]);
        setImageShow4(URL.createObjectURL(event.target.files[0]));
      } else if (id === 5) {
        setImageFiles5(event.target.files[0]);
        setImageShow5(URL.createObjectURL(event.target.files[0]));
      } else if (id === 6) {
        setImageFiles6(event.target.files[0]);
        setImageShow6(URL.createObjectURL(event.target.files[0]));
      }
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          สถานที่ปฏิบัติธรรม
                        </h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Button
                          onClick={() => setIsModalVisible(true)}
                          type="ghost"
                          icon={<PlusCircleOutlined />}
                        >
                          เพิ่มสถานที่ปฏิบัติธรรม
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 1800 }}
                  rowKey="rooms_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
              <Modal
                title="เพิ่มสถานที่ปฏิบัติธรรม"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                  setImageFiles2(null);
                  setImageShow2(null);
                  setImageFiles3(null);
                  setImageShow3(null);
                  setImageFiles4(null);
                  setImageShow4(null);
                  setImageFiles5(null);
                  setImageShow5(null);
                  setImageFiles6(null);
                  setImageShow6(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                      setImageFiles2(null);
                      setImageShow2(null);
                      setImageFiles3(null);
                      setImageShow3(null);
                      setImageFiles4(null);
                      setImageShow4(null);
                      setImageFiles5(null);
                      setImageShow5(null);
                      setImageFiles6(null);
                      setImageShow6(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    ยืนยันเพิ่มข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    initialValues={{
                      layout: "vertical",
                      bookType: "ภายใน",
                      isActive: true,
                    }}
                  >
                    <Form.Item
                      label="ชื่อ"
                      name="name"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อ!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุชื่อ" />
                    </Form.Item>
                    <Form.Item label="อาคาร/ตึก/สถานที่" name="building">
                      <Input placeholder="ระบุอาคาร/ตึก/สถานที่ (ถ้ามี)" />
                    </Form.Item>
                    <Form.Item label="ชั้น" name="floor">
                      <Input placeholder="ระบุชั้น" />
                    </Form.Item>
                    <Form.Item
                      label="จำนวนที่รองรับ"
                      name="maxSeats"
                      required
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]*$/),
                          message:
                            "กรุณาระบุจำนวนที่รองรับ! และต้องเป็นตัวเลขเท่านั้น!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุจำนวนที่รองรับ"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ผู้อำนวยการศูนย์"
                      name="approveRoom"
                      required
                      extra="* สำหรับผู้อำนวยการศูนย์จะนำไปใช้สร้างบันทึกข้อความ"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุผู้อำนวยการศูนย์!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุผู้อำนวยการศูนย์" />
                    </Form.Item>
                    <Form.Item
                      label="ประเภทบันทึกข้อความ"
                      name="bookType"
                      required
                    >
                      <Select>
                        <Select.Option value="ภายใน">ภายใน</Select.Option>
                        <Select.Option value="ภายนอก">ภายนอก</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="สิ่งอำนวยความสะดวก" name="onSiteServices">
                      <Row gutter={[24, 0]}>
                        {onSiteServices.map((v) => (
                          <Col key={v.id} md={8} xs={12}>
                            <Checkbox
                              onChange={(e) => checkBoxHandler(e)}
                              value={v.id}
                              style={{ lineHeight: "32px" }}
                            >
                              {v.value}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Form.Item>

                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 1"
                      name="image"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือกภาพสถานที่ปฏิบัติธรรม 1!",
                        },
                      ]}
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 1"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 1)}
                      />
                    </Form.Item>
                    {ImageShow2 !== null && (
                      <center>
                        <Image
                          src={ImageShow2}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 2"
                      name="image2"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 2"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 2)}
                      />
                    </Form.Item>
                    {ImageShow3 !== null && (
                      <center>
                        <Image
                          src={ImageShow3}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 3"
                      name="image3"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 3"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 3)}
                      />
                    </Form.Item>
                    {ImageShow4 !== null && (
                      <center>
                        <Image
                          src={ImageShow4}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 4"
                      name="image4"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 4"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 4)}
                      />
                    </Form.Item>
                    {ImageShow5 !== null && (
                      <center>
                        <Image
                          src={ImageShow5}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 5"
                      name="image5"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 5"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 5)}
                      />
                    </Form.Item>
                    {ImageShow6 !== null && (
                      <center>
                        <Image
                          src={ImageShow6}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 6"
                      name="image6"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 6"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 6)}
                      />
                    </Form.Item>
                    <Form.Item label="รายละเอียดเพิ่มเติม" name="detail">
                      <TextArea
                        rows={2}
                        placeholder="ระบุรายละเอียดเพิ่มเติม"
                      />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>

              <Modal
                title="แก้ไขสถานที่ปฏิบัติธรรม"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  formEdit.resetFields();
                  setIsModalEditVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formEdit.resetFields();
                      setIsModalEditVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleEditOk}
                  >
                    ยืนยันแก้ไขข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={formEdit}
                    initialValues={{
                      layout: "vertical",
                      isActive: true,
                      bookType: "ภายใน",
                    }}
                  >
                    <Form.Item
                      label="ชื่อ"
                      name="name"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อ!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุชื่อ" />
                    </Form.Item>
                    <Form.Item label="อาคาร/ตึก/สถานที่" name="building">
                      <Input placeholder="ระบุอาคาร/ตึก/สถานที่ (ถ้ามี)" />
                    </Form.Item>
                    <Form.Item label="ชั้น" name="floor">
                      <Input placeholder="ระบุชั้น" />
                    </Form.Item>
                    <Form.Item
                      label="จำนวนที่รองรับ"
                      name="maxSeats"
                      required
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]*$/),
                          message:
                            "กรุณาระบุจำนวนที่รองรับ! และต้องเป็นตัวเลขเท่านั้น!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุจำนวนที่รองรับ"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ผู้อำนวยการศูนย์"
                      name="approveRoom"
                      required
                      extra="* สำหรับผู้อำนวยการศูนย์จะนำไปใช้สร้างบันทึกข้อความ"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุผู้อำนวยการศูนย์!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุผู้อำนวยการศูนย์" />
                    </Form.Item>
                    <Form.Item
                      label="ประเภทบันทึกข้อความ"
                      name="bookType"
                      required
                    >
                      <Select>
                        <Select.Option value="ภายใน">ภายใน</Select.Option>
                        <Select.Option value="ภายนอก">ภายนอก</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name="services" label="สิ่งอำนวยความสะดวก">
                      <Checkbox.Group style={{ width: "100%" }}>
                        <Row gutter={[24, 0]}>
                          {onSiteServices.map((v) => (
                            <Col key={v.id} md={8} xs={12}>
                              <Checkbox
                                value={v.id}
                                style={{
                                  lineHeight: "32px",
                                }}
                              >
                                {v.value}
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>

                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 1"
                      name="image"
                      required
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 1"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 1)}
                      />
                    </Form.Item>
                    {ImageShow2 !== null && (
                      <center>
                        <Image
                          src={ImageShow2}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                        <div style={{ marginBottom: 8 }}>
                          <Button
                            onClick={() => setImageShow2(null)}
                            icon={<DeleteOutlined />}
                          >
                            ลบรูปภาพนี้
                          </Button>
                        </div>
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 2"
                      name="image2"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 2"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 2)}
                      />
                    </Form.Item>
                    {ImageShow3 !== null && (
                      <center>
                        <Image
                          src={ImageShow3}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                        <div style={{ marginBottom: 8 }}>
                          <Button
                            onClick={() => setImageShow3(null)}
                            icon={<DeleteOutlined />}
                          >
                            ลบรูปภาพนี้
                          </Button>
                        </div>
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 3"
                      name="image3"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 3"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 3)}
                      />
                    </Form.Item>
                    {ImageShow4 !== null && (
                      <center>
                        <Image
                          src={ImageShow4}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                        <div style={{ marginBottom: 8 }}>
                          <Button
                            onClick={() => setImageShow4(null)}
                            icon={<DeleteOutlined />}
                          >
                            ลบรูปภาพนี้
                          </Button>
                        </div>
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 4"
                      name="image4"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 4"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 4)}
                      />
                    </Form.Item>
                    {ImageShow5 !== null && (
                      <center>
                        <Image
                          src={ImageShow5}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                        <div style={{ marginBottom: 8 }}>
                          <Button
                            onClick={() => setImageShow5(null)}
                            icon={<DeleteOutlined />}
                          >
                            ลบรูปภาพนี้
                          </Button>
                        </div>
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 5"
                      name="image5"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 5"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 5)}
                      />
                    </Form.Item>
                    {ImageShow6 !== null && (
                      <center>
                        <Image
                          src={ImageShow6}
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                        <div style={{ marginBottom: 8 }}>
                          <Button
                            onClick={() => setImageShow6(null)}
                            icon={<DeleteOutlined />}
                          >
                            ลบรูปภาพนี้
                          </Button>
                        </div>
                      </center>
                    )}
                    <Form.Item
                      label="ภาพสถานที่ปฏิบัติธรรม 6"
                      name="image6"
                      extra="ภาพสถานที่ปฏิบัติธรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 1:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพสถานที่ปฏิบัติธรรม 6"
                        accept="image/jpeg, image/png"
                        onChange={(e) => onImageChange(e, 6)}
                      />
                    </Form.Item>
                    <Form.Item label="รายละเอียดเพิ่มเติม" name="detail">
                      <TextArea
                        rows={2}
                        placeholder="ระบุรายละเอียดเพิ่มเติม"
                      />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Rooms;
