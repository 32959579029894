import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";

export const checkUserIsSent = async (dharmaOnsiteId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/user/${dharmaOnsiteId}`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const findAllResultsByUserId = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/user`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const findAllResultsByAdmin = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const findAllReportResultsByAdmin = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/send-report`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const adminSendCheckerResultGo = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/admin-result-go`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const adminSendCheckerResult = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/admin-result`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const userCreateDharmaOnsite = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/user`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const userUpdateDharmaOnsite = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/user`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const userSendReportDharmaOnsite = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/user-report`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};

export const generatePDFApproveGo = async (status, resultuuid) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/generate-pdf/${status}/${resultuuid}`;
  const res = await RunApiRefreshToken("DOWNLOAD", url);
  return res;
};

export const generatePDFResult = async (status, resultuuid) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/generate-pdf-result/${resultuuid}`;
  const res = await RunApiRefreshToken("DOWNLOAD", url);
  return res;
};

export const reportResultsByUserId = async (userCode) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/report-user/${userCode}`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};
