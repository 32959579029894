import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";
import { RunAPI } from "../utils/RunApi";

export const getAllRooms = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/rooms`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getAllRoomsActive = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/rooms/active`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createRoom = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/rooms`;
  const res = await RunApiRefreshTokenMultipart("POST", url, data);
  return res;
};

export const updateRoom = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/rooms`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};
