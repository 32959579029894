import React, { useEffect, useState } from "react";
import { Button, Modal, Typography, Radio, Input } from "antd";
import { getAllAssessmentByUuid } from "../../../services/AssessmentService";
export default function AssessmentModal({
  isOpenAssessment,
  assessmentUuid,
  onClose,
}) {
  const [allData, setAllData] = useState([]);
  const [assessmentName, setAssessmentName] = useState("");
  useEffect(() => {
    if (isOpenAssessment) {
      initAllData();
    }
  }, [isOpenAssessment]);

  const initAllData = async () => {
    const res = await getAllAssessmentByUuid(assessmentUuid);
    if (!res._error) {
      console.log(res.data);
      setAllData(res.data);
      setAssessmentName(res.data.assessment.name);
    }
  };

  const renderQuestion = (v) => {
    return (
      <div key={v.id} style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 8 }}>{`ข้อที่ ${v.ordering + 1} ${
          v.title
        }`}</div>
        {v.result_type === "FIVESCORE" && (
          <div>
            <Radio.Group defaultValue={5}>
              <Radio value={5}>มากที่สุด</Radio>
              <Radio value={4}>มาก</Radio>
              <Radio value={3}>ปานกลาง</Radio>
              <Radio value={2}>น้อย</Radio>
              <Radio value={1}>น้อยที่สุด</Radio>
            </Radio.Group>
          </div>
        )}
        {v.result_type === "STRING" && (
          <div>
            <Input />
          </div>
        )}
        {v.result_type === "TEXT" && (
          <div>
            <Input.TextArea rows={3} />
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <Modal
        title={assessmentName}
        visible={isOpenAssessment}
        footer={null}
        onCancel={onClose}
      >
        {/* <p>{JSON.stringify(allData)}</p> */}
        <Typography.Title level={5}>{assessmentName}</Typography.Title>
        {allData.assessment && (
          <p>
            <Typography.Text>
              คำชี้แจง: {allData.assessment.detail}
            </Typography.Text>
          </p>
        )}
        {allData.questions && allData.questions.map((v) => renderQuestion(v))}
      </Modal>
    </>
  );
}
