import { createSlice } from "@reduxjs/toolkit";

export const autoStoreSlice = createSlice({
  name: "autoStore",
  initialState: {
    isShowLoginModal: false,
  },
  reducers: {
    updateIsShowLoginModal: (state, action) => {
 
      state.isShowLoginModal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateIsShowLoginModal } = autoStoreSlice.actions;

export default autoStoreSlice.reducer;
