import { configureStore } from "@reduxjs/toolkit";
import autoStoreSlice from "./redux/autoStore.slice";
import notificationSlice from "./redux/notification.slice";

// export default configureStore({
//   reducer: {
//     autoStore: autoStoreSlice,
//     notification: notificationSlice,
//   },
// });
const store = configureStore({
  reducer: {
    autoStore: autoStoreSlice,
    notification: notificationSlice,
  },
});

export default store;
