export const onSiteServices = [
  { id: 1, value: "ที่พักรวม" },
  { id: 2, value: "ที่พักแยก" },
  { id: 3, value: "ห้องน้ำรวม" },
  { id: 4, value: "ห้องน้ำแยก" },
  { id: 5, value: "ชุดปฏิบัติธรรมให้เช่า" },
  { id: 6, value: "หมอน" },
  { id: 7, value: "ที่นอน" },
  { id: 8, value: "ผ้าห่ม" },
  { id: 9, value: "WIFI" },
  { id: 10, value: "อาหารเช้า" },
  { id: 11, value: "อาหารเพล" },
  { id: 12, value: "อาหารเย็น" },
  { id: 13, value: "บริการเครื่องดื่มกาแฟ(เสียค่าใช้จ่าย)" },
  { id: 13, value: "บริการเครื่องดื่มกาแฟ(ไม่เสียค่าใช้จ่าย)" },
  { id: 14, value: "จุดบริการน้ำดื่ม" },
  { id: 15, value: "ใกล้ร้านสะดวกซื้อ" },
  { id: 16, value: "มีภาหนะ รับ-ส่ง กับ บขส." },
  { id: 17, value: "มีสัญญาณโทรศัพย์ AIS" },
  { id: 18, value: "มีสัญญาณโทรศัพย์ DTAC" },
  { id: 19, value: "มีสัญญาณโทรศัพย์ TRUE" },
];
