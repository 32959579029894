import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Select,
  Typography,
  Avatar,
  Image,
  Alert,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { AdminChecker } from "../utils/AdminChecker";
import {
  getAllCompensate,
  unlockUserCompensate,
} from "../services/UsersServices";

function UserCompensate() {
  AdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allPrefixs, setAllPrefixs] = useState([]);
  const [allGraduateTypes, setAllGraduateTypes] = useState([]);
  const [allField, setAllField] = useState([]);
  const [allTeacher, setAllTeacher] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [selectUserText, setSelectUserText] = useState("");

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [editPassword, setEditPassword] = useState(-1);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllCompensate();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const openEdit = (data) => {
    setEditId(data.dharma_onyear_compemsate_id);
    setEditPassword(data.users_password);
    setImageShow(data.users_avatar);
    const text = `ท่านยืนยันที่ต้องการปลดล็อคบัญชีของ รหัสนักศึกษา ${data.users_code} ชื่อ ${data.users_firstName} ${data.users_nickname} ${data.users_lastName} ใช่หรือไม่?`;
    setSelectUserText(text);
    setIsModalEditVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Avatar
          style={{
            backgroundColor: "#CCCCCC",
          }}
          src={avatar}
        />
      );
    }
    return (
      <Avatar
        style={{
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "โปรไฟล์",
      dataIndex: "users_avatar",
      width: 80,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.users_avatar) : null,
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "users_code",
      key: "users_code",
      width: 180,
      ...getColumnSearchProps("users_code"),
      sorter: (a, b) => a.users_code - b.users_code,
      sortOrder:
        sortedInfo.columnKey === "users_code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "คำนำหน้า",
      dataIndex: "prefixs_name",
      key: "prefixs_name",
      width: 180,
      ...getColumnSearchProps("prefixs_name"),
      sorter: (a, b) => a.prefixs_name.localeCompare(b.prefixs_name),
      sortOrder:
        sortedInfo.columnKey === "prefixs_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "users_firstName",
      key: "users_firstName",
      width: 180,
      ...getColumnSearchProps("users_firstName"),
      sorter: (a, b) => a.users_firstName.localeCompare(b.users_firstName),
      sortOrder:
        sortedInfo.columnKey === "users_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      dataIndex: "users_nickname",
      key: "nickname",
      width: 180,
      ...getColumnSearchProps("users_nickname"),
      sorter: (a, b) => a.users_nickname.localeCompare(b.users_nickname),
      sortOrder:
        sortedInfo.columnKey === "users_nickname" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      dataIndex: "users_lastName",
      key: "users_lastName",
      width: 180,
      ...getColumnSearchProps("users_lastName"),
      sorter: (a, b) => a.users_lastName.localeCompare(b.users_lastName),
      sortOrder:
        sortedInfo.columnKey === "users_lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "users_mobile",
      key: "users_mobile",
      ...getColumnSearchProps("users_mobile"),
      sorter: (a, b) => a.users_mobile.localeCompare(b.users_mobile),
      sortOrder:
        sortedInfo.columnKey === "users_mobile" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ประเภทบัณฑิต",
      dataIndex: "graduate_types_name",
      key: "graduate_types_name",
      ...getColumnSearchProps("graduate_types_name"),
      sorter: (a, b) =>
        a.graduate_types_name.localeCompare(b.graduate_types_name),
      sortOrder:
        sortedInfo.columnKey === "graduate_types_name"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "สาขา",
      dataIndex: "fields_name",
      key: "fields_name",
      ...getColumnSearchProps("fields_name"),
      sorter: (a, b) => a.fields_name.localeCompare(b.fields_name),
      sortOrder:
        sortedInfo.columnKey === "fields_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ปีการศึกษาที่เข้า",
      dataIndex: "users_educateYear",
      key: "users_educateYear",
      ...getColumnSearchProps("users_educateYear"),
      sorter: (a, b) => a.users_educateYear.localeCompare(b.users_educateYear),
      sortOrder:
        sortedInfo.columnKey === "users_educateYear" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "จำนวนวันปฏิบัติธรรมที่คาดหวัง",
      dataIndex: "users_numOfDharmaMust",
      key: "users_numOfDharmaMust",
      ...getColumnSearchProps("users_numOfDharmaMust"),
      sorter: (a, b) =>
        a.users_numOfDharmaMust.localeCompare(b.users_numOfDharmaMust),
      sortOrder:
        sortedInfo.columnKey === "users_numOfDharmaMust"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "จำนวนวันปฏิบัติธรรมที่สำเร็จ",
      dataIndex: "users_numOfDharma",
      key: "users_numOfDharma",
      ...getColumnSearchProps("users_numOfDharma"),
      sorter: (a, b) => a.users_numOfDharma.localeCompare(b.users_numOfDharma),
      sortOrder:
        sortedInfo.columnKey === "users_numOfDharma" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "จำนวนวันปฏิบัติธรรมที่ต้องซ่อม",
      dataIndex: "users_numOfDharmaCompensate",
      key: "users_numOfDharmaCompensate",
      ...getColumnSearchProps("users_numOfDharmaCompensate"),
      sorter: (a, b) =>
        a.users_numOfDharmaCompensate.localeCompare(
          b.users_numOfDharmaCompensate
        ),
      sortOrder:
        sortedInfo.columnKey === "users_numOfDharmaCompensate"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "สถานะ",
      dataIndex: "users_status",
      key: "users_status",
      ...getColumnSearchProps("users_status"),
      sorter: (a, b) => a.users_status.localeCompare(b.users_status),
      sortOrder:
        sortedInfo.columnKey === "users_status" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ที่ปรึกษา",
      dataIndex: "teachers_firstName",
      key: "teachers_firstName",
      ...getColumnSearchProps("teachers_firstName"),
      sorter: (a, b) =>
        a.teachers_firstName.localeCompare(b.teachers_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ปลดล็อคบัญชี",
      dataIndex: "operation",
      fixed: "right",
      width: 140,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"ปลดล็อคบัญชี"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const handleEditOk = async () => {
    setLoading(true);
    const data = {
      compensateId: editId,
    };
    const res = await unlockUserCompensate(data);
    if (!res._error) {
      const updateData = allData.filter(
        (item) => item.dharma_onyear_compemsate_id !== editId
      );
      setAllData(updateData);
      setIsModalEditVisible(false);
      setImageFiles(null);
      setImageShow(null);
    }
    setLoading(false);
  };

  const checkDuplicateCode = (rule, value, callback) => {
    const checker = allData.filter((v) => v.code === value);
    if (checker.length > 0) {
      callback(` รหัสนักศึกษา ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const checkDuplicateUsername = (rule, value, callback) => {
    const checker = allData.filter((v) => v.username === value);
    if (checker.length > 0) {
      callback(` username ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const checkDuplicateEditCode = (rule, value, callback) => {
    const checker = allData.filter((v) => v.code === value);
    if (checker.length > 1) {
      callback(` รหัสนักศึกษา ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const checkDuplicateEditUsername = (rule, value, callback) => {
    const checker = allData.filter((v) => v.username === value);
    if (checker.length > 1) {
      callback(` username ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ปลดล็อคบัญชีผู้ใช้
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 3800 }}
                  rowKey="users_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>

              <Modal
                title="ยืนยันการปลดล็อคบัญชี"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  setIsModalEditVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                // width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      setIsModalEditVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleEditOk}
                  >
                    ยืนยันปลดล็อคบัญชีนี้
                  </Button>,
                ]}
              >
                <div>
                  <Alert message={selectUserText} type="warning" />
                </div>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default UserCompensate;
