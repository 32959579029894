/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
// import Tables from "./pages/Tables";
// import Billing from "./pages/Billing";
// import Rtl from "./pages/Rtl";
// import Profile from "./pages/Profile";
// import SignUp from "./pages/SignUp";
// import SignIn from "./pages/SignIn";
// import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import ImportUsers from "./pages/ImportUsers";

import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Rooms from "./pages/Rooms";
import BasicDataMain from "./pages/BasicData/BasicDataMain";
import BasicDataField from "./pages/BasicData/BasicDataField";
import BasicDataAdmin from "./pages/BasicData/BasicDataAdmin";
import BasicDataTeacher from "./pages/BasicData/BasicDataTeacher";
import BasicDataPrefix from "./pages/BasicData/BasicDataPrefix";
import BasicDataGraduateType from "./pages/BasicData/BasicDataGraduateType";
import DharmaOnlineMain from "./pages/DharmaOnline/DharmaOnlineMain";
import DharmaOnlineData from "./pages/DharmaOnline/DharmaOnlineData";
import DharmaOnlineChecker from "./pages/DharmaOnline/DharmaOnlineChecker";
import DharmaOnlineReport from "./pages/DharmaOnline/DharmaOnlineReport";
import DharmaOnlineDetail from "./pages/DharmaOnline/DharmaOnlineDetail";
import DharmaOnlineUserChecker from "./pages/DharmaOnline/DharmaOnlineUserChecker";
import DharmaOnsiteMain from "./pages/DharmaOnsite/DharmaOnsiteMain";
import DharmaOnsiteData from "./pages/DharmaOnsite/DharmaOnsiteData";
import DharmaOnsiteChecker from "./pages/DharmaOnsite/DharmaOnsiteChecker";
import DharmaOnsiteReport from "./pages/DharmaOnsite/DharmaOnsiteReport";
import DharmaOnsiteDetail from "./pages/DharmaOnsite/DharmaOnsiteDetail";
import DharmaOnsiteUserChecker from "./pages/DharmaOnsite/DharmaOnsiteUserChecker";
import DharmaResult from "./pages/DharmaResult";
import Notifications from "./pages/Notifications";
import Banner from "./pages/Banner";
import DharmaOnsiteGoChecker from "./pages/DharmaOnsite/DharmaOnsiteGoChecker";
import AssessmentMain from "./pages/Assessment/AssessmentMain";
import AssessmentData from "./pages/Assessment/AssessmentData";
import AssessmentCreate from "./pages/Assessment/AssessmentCreate";
import AssessmentUpdate from "./pages/Assessment/AssessmentUpdate";
import DharmaOnlineUserReport from "./pages/DharmaOnline/DharmaOnlineUserReport";
import DharmaOnsiteUserReport from "./pages/DharmaOnsite/DharmaOnsiteUserReport";
import DharmaOnYearMain from "./pages/DharmaOnYear/DharmaOnYearMain";
import DharmaOnyearData from "./pages/DharmaOnYear/DharmaOnyearData";
import DharmaOnYearRegister from "./pages/DharmaOnYear/DharmaOnYearRegister";
import DharmaOnYearChecker from "./pages/DharmaOnYear/DharmaOnyearChecker";
import DharmaOnYearResultChecker from "./pages/DharmaOnYear/DharmaOnyearResultChecker";
import DharmaOnYearDiscussion from "./pages/DharmaOnYear/DharmaOnyearDiscussion";
import DharmaOnYearResultDiscussion from "./pages/DharmaOnYear/DharmaOnyearResultDiscussion";
import DharmaOnYearUserChecker from "./pages/DharmaOnYear/DharmaOnYearUserChecker";
import DharmaOnyearDetail from "./pages/DharmaOnYear/DharmaOnyearDetail";
import DharmaOnyearUserReport from "./pages/DharmaOnYear/DharmaOnyearUserReport";
import UserCompensate from "./pages/UserCompensate";
import AssessmentResultList from "./pages/Assessment/AssessmentResultList";
import AssessmentResultChecker from "./pages/Assessment/AssessmentResultChecker";
import Certificate from "./pages/Certificate";

function App() {
  return (
    <div className="App">
      <Switch>
        {/* <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} /> */}
        <Main>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} /> */}
          <Route exact path="/home" component={Home} />
          <Route exact path="/basic-data/users" component={Users} />
          <Route
            exact
            path="/basic-data/users/import"
            component={ImportUsers}
          />
          <Route exact path="/rooms" component={Rooms} />
          <Route exact path="/basic-data" component={BasicDataMain} />
          <Route exact path="/basic-data/field" component={BasicDataField} />
          <Route exact path="/basic-data/admin" component={BasicDataAdmin} />
          <Route exact path="/basic-data/prefix" component={BasicDataPrefix} />
          <Route
            exact
            path="/basic-data/graduate-type"
            component={BasicDataGraduateType}
          />
          <Route
            exact
            path="/basic-data/teacher"
            component={BasicDataTeacher}
          />
          <Route exact path="/dharma-online" component={DharmaOnlineMain} />
          <Route
            exact
            path="/dharma-online/dharma-online-data"
            component={DharmaOnlineData}
          />
          <Route
            exact
            path="/dharma-online/dharma-online-checker"
            component={DharmaOnlineChecker}
          />
          <Route
            exact
            path="/dharma-online/dharma-online-report"
            component={DharmaOnlineReport}
          />
          <Route
            exact
            path="/dharma-online/dharma-online-report-user"
            component={DharmaOnlineUserReport}
          />
          <Route
            exact
            path="/home/dharma-online-detail"
            component={DharmaOnlineDetail}
          />
          <Route
            exact
            path="/dharma-online-user-checker"
            component={DharmaOnlineUserChecker}
          />
          <Route exact path="/dharma-result" component={DharmaResult} />
          <Route exact path="/notifications" component={Notifications} />
          <Route exact path="/banner" component={Banner} />
          <Route exact path="/dharma-onsite" component={DharmaOnsiteMain} />
          <Route
            exact
            path="/dharma-onsite/dharma-onsite-data"
            component={DharmaOnsiteData}
          />
          <Route
            exact
            path="/dharma-onsite/dharma-onsite-gochecker"
            component={DharmaOnsiteGoChecker}
          />
          <Route
            exact
            path="/dharma-onsite/dharma-onsite-checker"
            component={DharmaOnsiteChecker}
          />
          <Route
            exact
            path="/dharma-onsite/dharma-onsite-report"
            component={DharmaOnsiteReport}
          />
          <Route
            exact
            path="/dharma-onsite/dharma-onsite-report-user"
            component={DharmaOnsiteUserReport}
          />
          <Route
            exact
            path="/home/dharma-onsite-detail"
            component={DharmaOnsiteDetail}
          />
          <Route
            exact
            path="/dharma-onsite-user-checker"
            component={DharmaOnsiteUserChecker}
          />
          <Route exact path="/assessment" component={AssessmentMain} />
          <Route
            exact
            path="/assessment/assessment-data"
            component={AssessmentData}
          />
          <Route
            exact
            path="/assessment/assessment-data/assessment-create"
            component={AssessmentCreate}
          />
          <Route
            exact
            path="/assessment/assessment-data/assessment-update"
            component={AssessmentUpdate}
          />

          <Route exact path="/dharma-onyear" component={DharmaOnYearMain} />
          <Route
            exact
            path="/dharma-onyear/dharma-onyear-data"
            component={DharmaOnyearData}
          />
          <Route
            exact
            path="/dharma-onyear/dharma-onyear-register"
            component={DharmaOnYearRegister}
          />
          <Route
            exact
            path="/dharma-onyear/dharma-onyear-checker"
            component={DharmaOnYearChecker}
          />
          <Route
            exact
            path="/dharma-onyear/dharma-onyear-result-checker"
            component={DharmaOnYearResultChecker}
          />
          <Route
            exact
            path="/dharma-onyear/dharma-onyear-discussion"
            component={DharmaOnYearDiscussion}
          />
          <Route
            exact
            path="/dharma-onyear/dharma-onyear-result-discussion"
            component={DharmaOnYearResultDiscussion}
          />
          <Route
            exact
            path="/dharma-onyear-user-checker"
            component={DharmaOnYearUserChecker}
          />
          <Route
            exact
            path="/home/dharma-onyear-detail"
            component={DharmaOnyearDetail}
          />
          <Route
            exact
            path="/dharma-onyear/dharma-onyear-report-user"
            component={DharmaOnyearUserReport}
          />
          <Route exact path="/user-compensate" component={UserCompensate} />
          <Route
            exact
            path="/assessment/assessment-results-list"
            component={AssessmentResultList}
          />
          <Route
            exact
            path="/assessment/assessment-results-checker"
            component={AssessmentResultChecker}
          />
          <Route exact path="/certificate" component={Certificate} />

          {/* <Redirect from="*" to="/home" /> */}
        </Main>
      </Switch>
    </div>
  );
}

export default App;
