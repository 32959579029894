import React, { useEffect, useState } from "react";
import {
  Carousel,
  Avatar,
  List,
  Space,
  Row,
  Col,
  Card,
  Typography,
  Tag,
  Image,
  Button,
  Modal,
  Form,
  Input,
  Divider,
  DatePicker,
} from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { getOneDharmaOnsitePlublicId } from "../../services/DharmaOnsiteService";
import { getMe } from "../../services/UsersServices";
import { userCreateDharmaOnsite } from "../../services/DharamaOnsiteResultsService";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { onSiteServices } from "../../constants/dharmaOnsite.constants";

const { Text, Title } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export default function DharmaOnsiteDetail() {
  let query = new URLSearchParams(useLocation().search);
  let uuidToFind = query.get("uuidLink");
  const userRole = localStorage.getItem("@userRole");
  const [loading, setLoading] = useState(false);
  const [allDharmaOnsite, setAllDharmaOnsite] = useState([]);

  const [isSent, setIsSent] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [roomImages, setRoomImages] = useState([]);
  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleError, setIsModalVisibleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [sendTimeStatus, setSendTimeStatus] = useState("WAIT");
  const [alertOption, setAlertOption] = useState("");

  const [dharmaOnsiteId, setDharmaOnsiteId] = useState(null);
  const [dharmaOnsiteUuid, setDharmaOnsiteUuid] = useState(null);

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    initAllData();
  }, []);

  const initAllData = async () => {
    if (userRole === "user") {
      const me = await getMe();
      if (!me._error) {
        setUserInfo(me.data);
      }
    }
    const res = await getOneDharmaOnsitePlublicId(uuidToFind);
    if (!res._error) {
      setAllDharmaOnsite(res.data);
      setDharmaOnsiteId(res.data[0].dharma_onsite_id);
      setDharmaOnsiteUuid(res.data[0].dharma_onsite_uuid);
      setSendTimeStatus(res.data[0].sendTimeStatus);
      const sts = res.data[0].sendTimeStatus;
      let saveStatusAlert = "";
      if (sts === "WAIT") {
        saveStatusAlert = "(ยังไม่ถึงเวลาให้รายงาน)";
      } else if (sts === "CLOSE") {
        saveStatusAlert = "(หมดเวลาให้รายงาน)";
      } else {
        saveStatusAlert = "";
      }
      setAlertOption(saveStatusAlert);

      const roomImagesData = [];
      if (res.data[0].rooms_image1) {
        roomImagesData.push(res.data[0].rooms_image1);
      }
      if (res.data[0].rooms_image2) {
        roomImagesData.push(res.data[0].rooms_image2);
      }
      if (res.data[0].rooms_image3) {
        roomImagesData.push(res.data[0].rooms_image3);
      }
      if (res.data[0].rooms_image4) {
        roomImagesData.push(res.data[0].rooms_image4);
      }
      if (res.data[0].rooms_image5) {
        roomImagesData.push(res.data[0].rooms_image5);
      }
      if (res.data[0].rooms_image6) {
        roomImagesData.push(res.data[0].rooms_image6);
      }
      setRoomImages(roomImagesData);
    }
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    const nowTime = new Date().getTime();
    const startEvent = new Date(
      allDharmaOnsite[0].dharma_onsite_startOnsiteTime
    ).getTime();
    if (nowTime < startEvent) {
      const setDisabledDate =
        (current &&
          current <
            moment(allDharmaOnsite[0].dharma_onsite_startOnsiteTime)
              .hour(0)
              .minute(0)) ||
        (current &&
          current >
            moment(allDharmaOnsite[0].dharma_onsite_endOnsiteTime)
              .hour(23)
              .minute(59));
      return setDisabledDate;
    } else {
      const setDisabledDate =
        (current && current < moment(nowTime).hour(0).minute(0)) ||
        (current &&
          current >
            moment(allDharmaOnsite[0].dharma_onsite_endOnsiteTime)
              .hour(23)
              .minute(59));
      return setDisabledDate;
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        console.log(values);
        const data = {
          dharmaOnsiteId: dharmaOnsiteId,
          dharmaOnsiteUuid: dharmaOnsiteUuid,
          user_startgo_dt: values.goTime[0]._d.toJSON(),
          user_endgo_dt: values.goTime[1]._d.toJSON(),
        };
        const res = await userCreateDharmaOnsite(data);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
          setIsSent(true);
          window.location = "/dharma-onsite-user-checker";
        }
        // else {
        //   const errorStatus = res._error_data.response.data.data;
        //   if (errorStatus === "WAIT") {
        //     setErrorMessage("ยังไม่ถึงเวลาการรายงานผลการปฏิบัติธรรม");
        //   } else if (errorStatus === "CLOSE") {
        //     setErrorMessage("หมดเวลาในการรายงานผลการปฏิบัติธรรมนี้แล้ว");
        //   }
        //   setIsModalVisible(false);
        //   setIsModalVisibleError(true);
        // }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const renderStartEnd = (start, end) => {
    const startTime = moment(start).add(543, "year").format("DD/MM/YYYY HH:mm");
    const endTime = moment(end).add(543, "year").format("DD/MM/YYYY HH:mm");
    return (
      <>
        {startTime} ถึง {endTime}
      </>
    );
  };

  const handleCancelError = () => {
    window.location.reload(false);
  };

  const checkConditionReceive = () => {
    if (userRole === "user" && allDharmaOnsite[0]) {
      const conditions = allDharmaOnsite[0].dharma_onsite_conditions;
      const userCondition = userInfo[0].fields_level;
      const check = conditions.search(userCondition);
      if (check >= 0) {
        return (
          <>
            <List.Item style={{ flexDirection: "column" }}>
              <Button
                onClick={() => setIsModalVisible(true)}
                icon={<EditOutlined />}
                block
              >
                ทำเรื่องขออนุมติเข้าร่วมกิจกรรม
              </Button>
            </List.Item>
          </>
        );
      }
    }
    return <></>;
  };

  const onChangeOnlineTime = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };
  const onOkOnlineTime = (value) => {
    console.log("onOk: ", value);
  };

  const renderServicesListSelected = (data = "") => {
    console.log("data", data);
    if (data !== "") {
      const getData = data.split(",").map(Number);
      return getData
        .map((v) => {
          return onSiteServices[v - 1].value;
        })
        .join();
    }
    return "-";
  };

  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card className="header-solid h-full ant-card-p-0">
                <h3>
                  <b>
                    ปฏิบัติธรรมในสถานที่ :{" "}
                    {allDharmaOnsite[0]
                      ? allDharmaOnsite[0].dharma_onsite_name
                      : ""}
                  </b>
                </h3>
                <div>
                  <List size="small">
                    <Row gutter={[24, 0]}>
                      <Col xs={24} md={12} className="mb-24">
                        {allDharmaOnsite[0] && (
                          <List.Item>
                            <Image
                              style={{
                                width: "100%",
                                aspectRatio: "16 / 9",
                                backgroundColor: "#CCCCCC",
                                objectFit: "cover",
                                borderRadius: 8,
                              }}
                              alt={allDharmaOnsite[0].dharma_onsite_name}
                              src={allDharmaOnsite[0].dharma_onsite_image}
                            />
                          </List.Item>
                        )}
                        {allDharmaOnsite[0] &&
                          allDharmaOnsite[0].dharma_onsite_detail.length >
                            0 && (
                            <List.Item>
                              <Text style={{ whiteSpace: "pre-wrap" }}>
                                {allDharmaOnsite[0]
                                  ? allDharmaOnsite[0].dharma_onsite_detail
                                  : ""}
                              </Text>
                            </List.Item>
                          )}
                      </Col>
                      <Col xs={24} md={12} className="mb-24">
                        {allDharmaOnsite[0] && (
                          <List.Item>
                            <Title level={5}>
                              {allDharmaOnsite[0]
                                ? allDharmaOnsite[0].dharma_onsite_name
                                : ""}
                            </Title>
                          </List.Item>
                        )}
                        {allDharmaOnsite[0] && (
                          <List.Item>
                            <Text>
                              วันที่เวลา :{" "}
                              {renderStartEnd(
                                allDharmaOnsite[0]
                                  .dharma_onsite_startOnsiteTime,
                                allDharmaOnsite[0].dharma_onsite_endOnsiteTime
                              )}
                            </Text>
                          </List.Item>
                        )}

                        {allDharmaOnsite[0] &&
                          allDharmaOnsite[0].dharma_onsite_conditions.length >
                            0 && (
                            <List.Item>
                              <Text>
                                สำหรับระดับ :{" "}
                                {allDharmaOnsite[0]
                                  ? allDharmaOnsite[0].dharma_onsite_conditions
                                  : ""}
                              </Text>
                            </List.Item>
                          )}

                        {allDharmaOnsite[0] &&
                          allDharmaOnsite[0].rooms_name.length > 0 && (
                            <List.Item>
                              <Text>
                                สถานที่ :{" "}
                                {allDharmaOnsite[0]
                                  ? allDharmaOnsite[0].rooms_name
                                  : ""}
                              </Text>
                            </List.Item>
                          )}

                        {allDharmaOnsite[0] &&
                          allDharmaOnsite[0].dharma_onsite_teacher1_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnsite[0].teachers1_avatar}
                                />
                                <Text>
                                  {allDharmaOnsite[0].teachers1_firstName}{" "}
                                  {allDharmaOnsite[0].teachers1_nickname}{" "}
                                  {allDharmaOnsite[0].teachers1_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnsite[0] &&
                          allDharmaOnsite[0].dharma_onsite_teacher2_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnsite[0].teachers2_avatar}
                                />
                                <Text>
                                  {allDharmaOnsite[0].teachers2_firstName}{" "}
                                  {allDharmaOnsite[0].teachers2_nickname}{" "}
                                  {allDharmaOnsite[0].teachers2_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnsite[0] &&
                          allDharmaOnsite[0].dharma_onsite_teacher3_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnsite[0].teachers3_avatar}
                                />
                                <Text>
                                  {allDharmaOnsite[0].teachers3_firstName}{" "}
                                  {allDharmaOnsite[0].teachers3_nickname}{" "}
                                  {allDharmaOnsite[0].teachers3_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnsite[0] &&
                          allDharmaOnsite[0].dharma_onsite_teacher4_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnsite[0].teachers4_avatar}
                                />
                                <Text>
                                  {allDharmaOnsite[0].teachers4_firstName}{" "}
                                  {allDharmaOnsite[0].teachers4_nickname}{" "}
                                  {allDharmaOnsite[0].teachers4_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnsite[0] &&
                          allDharmaOnsite[0].dharma_onsite_teacher5_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnsite[0].teachers5_avatar}
                                />
                                <Text>
                                  {allDharmaOnsite[0].teachers5_firstName}{" "}
                                  {allDharmaOnsite[0].teachers5_nickname}{" "}
                                  {allDharmaOnsite[0].teachers5_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}

                        {checkConditionReceive()}
                      </Col>
                    </Row>
                  </List>
                  <Divider />
                  <Row gutter={[24, 0]}>
                    <Col xs={24} className="mb-24">
                      <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} className="mb-24">
                          ข้อมูลสถานที่ :{" "}
                          <Text>
                            {allDharmaOnsite[0]
                              ? allDharmaOnsite[0].rooms_name
                              : ""}
                          </Text>
                        </Col>
                      </Row>
                      <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} className="mb-24">
                          <Row gutter={[24, 0]}>
                            {roomImages.map((v, index) => (
                              <Col
                                key={index}
                                xs={12}
                                md={12}
                                style={{ marginBottom: 10 }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    aspectRatio: "1/1",
                                  }}
                                >
                                  <Image
                                    style={{
                                      width: "100%",
                                      aspectRatio: "1/1",
                                      objectFit: "cover",
                                    }}
                                    src={v}
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                        <Col xs={24} md={12} className="mb-24">
                          <List size="small">
                            <List.Item>
                              <Text>
                                ชื่อสถานที่ :{" "}
                                {allDharmaOnsite[0]
                                  ? allDharmaOnsite[0].rooms_name
                                  : "-"}
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text>
                                อาคาร/ตึก :{" "}
                                {allDharmaOnsite[0]
                                  ? allDharmaOnsite[0].rooms_building
                                  : "-"}
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text>
                                ชั้น :{" "}
                                {allDharmaOnsite[0]
                                  ? allDharmaOnsite[0].rooms_floor
                                  : "-"}
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text>
                                จำนวนที่รองรับ :{" "}
                                {allDharmaOnsite[0]
                                  ? allDharmaOnsite[0].rooms_maxSeats
                                  : ""}
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text>
                                สิ่งอำนวยความสะดวก :{" "}
                                {allDharmaOnsite[0]
                                  ? renderServicesListSelected(
                                      allDharmaOnsite[0].rooms_services
                                    )
                                  : "-"}
                              </Text>
                            </List.Item>
                            {allDharmaOnsite[0] &&
                              allDharmaOnsite[0].rooms_detail.length > 0 && (
                                <List.Item>
                                  <Text>
                                    เพิ่มเติม :{" "}
                                    {allDharmaOnsite[0]
                                      ? allDharmaOnsite[0].rooms_detail
                                      : ""}
                                  </Text>
                                </List.Item>
                              )}
                          </List>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Modal
                    title="ทำเรื่องขออนุมติเข้าร่วมกิจกรรมปฏิบัติธรรมในสถานที่"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    width={"80%"}
                    centered
                    confirmLoading={loading}
                    closable={false}
                    maskClosable={false}
                    footer={[
                      <Button
                        icon={<CloseCircleOutlined />}
                        key="back"
                        onClick={() => {
                          form.resetFields();
                          setIsModalVisible(false);
                          setImageFiles(null);
                          setImageShow(null);
                        }}
                        disabled={loading}
                      >
                        ยกเลิก
                      </Button>,
                      <Button
                        icon={<CheckCircleOutlined />}
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                      >
                        ยืนยันส่งเรื่องขออนุมติเข้าร่วมกิจกรรม
                      </Button>,
                    ]}
                  >
                    <>
                      <Form
                        {...formItemLayout}
                        layout={formLayout}
                        form={form}
                        initialValues={{
                          layout: "vertical",
                          isActive: true,
                        }}
                      >
                        <div
                          style={{ flexDirection: "column", display: "flex" }}
                        ></div>
                        <Form.Item label="กิจกรรม" name="dharma_onsite_name">
                          {allDharmaOnsite[0] && (
                            <Text strong>
                              {allDharmaOnsite[0]
                                ? " " + allDharmaOnsite[0].dharma_onsite_name
                                : ""}
                            </Text>
                          )}
                        </Form.Item>
                        <Form.Item label="ชื่อสถานที่" name="rooms_name">
                          {allDharmaOnsite[0] && (
                            <Text>
                              {allDharmaOnsite[0]
                                ? allDharmaOnsite[0].rooms_name
                                : ""}
                            </Text>
                          )}
                        </Form.Item>
                        <Form.Item
                          label="วันที่เวลาของกิจกรรม"
                          name="dharma_onsite_startOnsiteTime"
                        >
                          {allDharmaOnsite[0] && (
                            <Text>
                              {renderStartEnd(
                                allDharmaOnsite[0]
                                  .dharma_onsite_startOnsiteTime,
                                allDharmaOnsite[0].dharma_onsite_endOnsiteTime
                              )}
                            </Text>
                          )}
                        </Form.Item>
                        <Form.Item
                          label="วันเริ่มต้น-สิ้นสุดที่ขออนุมัติ"
                          name="goTime"
                          required
                          rules={[
                            {
                              required: true,
                              message:
                                "กรุณาระบุวันเริ่มต้น-สิ้นสุดที่ขออนุมัติ!",
                            },
                          ]}
                          extra={
                            "วันเริ่มต้น-สิ้นสุดที่ขออนุมัติต้องอยู่ในช่วงระยะเวลาของกิจกรรม"
                          }
                        >
                          <RangePicker
                            disabledDate={disabledDate}
                            showTime={{
                              hideDisabledOptions: true,
                              format: "HH:mm",
                            }}
                            placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                            format="YYYY-MM-DD HH:mm"
                            onChange={onChangeOnlineTime}
                            onOk={onOkOnlineTime}
                          />
                        </Form.Item>
                        {/* <Form.Item label="รายละเอียดเพิ่มเติม" name="detail">
                          <TextArea
                            rows={4}
                            placeholder="ระบุรายละเอียดเพิ่มเติม"
                          />
                        </Form.Item> */}
                        <div style={{ height: 50 }}></div>
                      </Form>
                    </>
                  </Modal>
                  <Modal
                    title="เกิดข้อผิดพลาด"
                    visible={isModalVisibleError}
                    footer={null}
                    onCancel={handleCancelError}
                    centered
                  >
                    <p>{errorMessage}</p>
                  </Modal>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
