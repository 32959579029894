import React from "react";
import { Row, Col, Card, List, Badge } from "antd";
import { useSelector } from "react-redux";
import { ReadOutlined, UserOutlined } from "@ant-design/icons";
import { SubAdminChecker } from "../../utils/AdminChecker";

function DharmaOnsiteMain() {
  SubAdminChecker();

  const userRole = localStorage.getItem("@userRole");
  const onsiteGoCount = useSelector(
    (state) => state.notification.onsiteGoCount
  );
  const onsiteRsCount = useSelector(
    (state) => state.notification.onsiteRsCount
  );

  const dataForAdmin = [
    {
      title: "ข้อมูลปฏิบัติธรรมในสถานที่",
      description: "เพิ่ม แก้ไข จัดการข้อมูลปฏิบัติธรรมในสถานที่",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-data",
      count: 0,
    },
    {
      title: "อนุมัติคำขอเข้าร่วมปฏิบัติธรรมในสถานที่",
      description:
        "ตรวจสอบ อนุมัติ ไม่อนุมัติ อนุมัติคำขอเข้าร่วมปฏิบัติธรรมในสถานที่",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-gochecker",
      count: onsiteGoCount,
    },
    {
      title: "ตรวจสอบผลการเข้าร่วมปฏิบัติธรรมในสถานที่",
      description:
        "ตรวจสอบ อนุมัติ ไม่อนุมัติ รายงานผลการเข้าร่วมปฏิบัติธรรมในสถานที่",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-checker",
      count: onsiteRsCount,
    },
    {
      title: "รายงานภาพรวมปฏิบัติธรรมในสถานที่",
      description: "ดู รายงานภาพรวมปฏิบัติธรรมในสถานที่",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-report",
      count: 0,
    },
    {
      title: "รายงานประวัติปฏิบัติธรรมในสถานที่รายบุคคุล",
      description: "ดู รายงานประวัติปฏิบัติธรรมในสถานที่รายบุคคุล",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-report-user",
      count: 0,
    },
  ];

  const dataForSubAdmin = [
    {
      title: "ข้อมูลปฏิบัติธรรมในสถานที่",
      description: "เพิ่ม แก้ไข จัดการข้อมูลปฏิบัติธรรมในสถานที่",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-data",
      count: 0,
    },
    {
      title: "อนุมัติคำขอเข้าร่วมปฏิบัติธรรมในสถานที่",
      description:
        "ตรวจสอบ อนุมัติ ไม่อนุมัติ อนุมัติคำขอเข้าร่วมปฏิบัติธรรมในสถานที่",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-gochecker",
      count: onsiteGoCount,
    },
    {
      title: "ตรวจสอบผลการเข้าร่วมปฏิบัติธรรมในสถานที่",
      description:
        "ตรวจสอบ อนุมัติ ไม่อนุมัติ รายงานผลการเข้าร่วมปฏิบัติธรรมในสถานที่",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-checker",
      count: onsiteRsCount,
    },
    {
      title: "รายงานภาพรวมปฏิบัติธรรมในสถานที่",
      description: "ดู รายงานภาพรวมปฏิบัติธรรมในสถานที่",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-report",
      count: 0,
    },
    {
      title: "รายงานประวัติปฏิบัติธรรมในสถานที่รายบุคคุล",
      description: "ดู รายงานประวัติปฏิบัติธรรมในสถานที่รายบุคคุล",
      icon: <ReadOutlined />,
      link: "/dharma-onsite/dharma-onsite-report-user",
      count: 0,
    },
  ];
  var data = [];
  if (userRole === "admin") {
    data = dataForAdmin;
  } else {
    data = dataForSubAdmin;
  }

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ปฏิบัติธรรมในสถานที่
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Badge size="small" count={item.count || 0}>
                            {item.icon}
                          </Badge>
                        }
                        title={<a href={item.link}>{item.title}</a>}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnsiteMain;
