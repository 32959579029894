import React, { useState, useEffect } from "react";
import { Card, Col, Row, Typography, Timeline, Progress } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import {
  StarOutlined,
  CheckCircleOutlined,
  FrownOutlined,
  CalendarOutlined,
  SmileOutlined,
  MehOutlined,
} from "@ant-design/icons";
import { getMe } from "../services/UsersServices";
import { findAllResultsByUserId } from "../services/DharamaOnlineResultsService";
import { findAllResultsByUserId as findAllResultsOnsiteByUserId } from "../services/DharamaOnsiteResultsService";
import { findAllResultsByUserId as findAllResultsOnyearByUserId } from "../services/DharmaOnyearService";
import moment from "moment";

function DharmaResult() {
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [numOfDharmaMust, setNumOfDharmaMust] = useState(0);
  const [numOfDharma, setNumOfDharma] = useState(0);
  const [numOfDharmaCompensate, setNumOfDharmaCompensate] = useState(0);
  const [numOfRemaining, setNumOfRemaining] = useState(0);
  const [percentData, setPercentDat] = useState(0);
  const [allData, setAllData] = useState([]);
  const [allDharmaOnline, setAllDharmaOnline] = useState([]);
  const [allDharmaOnsite, setAllDharmaOnsite] = useState([]);
  const [allDharmaOnyear, setAllDharmaOnyear] = useState([]);

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getMe();
    if (!res._error) {
      setAllData(res.data);
      const userInfo = res.data[0];
      const remaining =
        Number(userInfo.users_numOfDharmaMust) +
        Number(userInfo.users_numOfDharmaCompensate) -
        Number(userInfo.users_numOfDharma);
      setNumOfDharmaMust(userInfo.users_numOfDharmaMust);
      setNumOfDharma(userInfo.users_numOfDharma);
      setNumOfDharmaCompensate(userInfo.users_numOfDharmaCompensate);
      setNumOfRemaining(remaining.toFixed(1));
      const percent =
        ((userInfo.users_numOfDharmaMust - remaining) /
          userInfo.users_numOfDharmaMust) *
        100;
      setPercentDat(percent.toFixed(2));
    }
    const dharmaOnline = await findAllResultsByUserId();
    if (!dharmaOnline._error) {
      setAllDharmaOnline(dharmaOnline.data);
    }
    const dharmaOnsite = await findAllResultsOnsiteByUserId();
    if (!dharmaOnsite._error) {
      setAllDharmaOnsite(dharmaOnsite.data);
    }
    const dharmaOnyear = await findAllResultsOnyearByUserId();
    if (!dharmaOnyear._error) {
      setAllDharmaOnyear(dharmaOnyear.data);
    }
  };

  const renderCardPoint = (title, point, icon, color = "#1890ff") => {
    return (
      <>
        <Col
          //key={index}
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
          className="mb-24"
        >
          <Card bordered={false} className="criclebox ">
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col xs={18}>
                  <span>{title}</span>
                  <Title level={3}>{point}</Title>
                </Col>
                <Col xs={6}>
                  <div className="icon-box" style={{ backgroundColor: color }}>
                    {icon}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </>
    );
  };

  const renderStatusSmile = (type) => {
    var Approve = 0;
    var Waiting = 0;
    var Reject = 0;
    if (type === "ONLINE") {
      Approve = allDharmaOnline.filter(
        (v) => v.dharma_online_results_status === "อนุมัติ"
      ).length;
      Waiting = allDharmaOnline.filter(
        (v) => v.dharma_online_results_status === "รอตรวจสอบ"
      ).length;
      Reject = allDharmaOnline.filter(
        (v) => v.dharma_online_results_status === "ไม่อนุมัติ"
      ).length;
    }
    if (type === "ONSITE") {
      Approve = allDharmaOnsite.filter(
        (v) => v.dharma_onsite_results_status === "อนุมัติ"
      ).length;
      Waiting = allDharmaOnsite.filter(
        (v) =>
          v.dharma_onsite_results_status === "รอตรวจสอบ" ||
          v.dharma_onsite_results_status === "รอดำเนินการ"
      ).length;
      Reject = allDharmaOnsite.filter(
        (v) => v.dharma_onsite_results_status === "ไม่อนุมัติ"
      ).length;
    }
    if (type === "ONYEAR") {
      Approve = allDharmaOnyear.filter(
        (v) => v.dharma_onyear_isDiscussion
      ).length;
      Waiting = allDharmaOnyear.filter(
        (v) => !v.dharma_onyear_isDiscussion
      ).length;
    }
    return (
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 24 }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SmileOutlined style={{ color: "#389e0d" }} />{" "}
          <Text style={{ marginLeft: 6, color: "#389e0d" }}>{Approve}</Text>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MehOutlined style={{ color: "#faad14" }} />{" "}
          <Text style={{ marginLeft: 6, color: "#faad14" }}>{Waiting}</Text>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FrownOutlined style={{ color: "#cf1322" }} />{" "}
          <Text style={{ marginLeft: 6, color: "#cf1322" }}>{Reject}</Text>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {renderCardPoint(
            "จำนวนวันที่คาดหวัง",
            numOfDharmaMust,
            <StarOutlined />
          )}
          {renderCardPoint(
            "จำนวนวันที่สำเร็จ",
            numOfDharma,
            <CheckCircleOutlined />,
            "#389e0d"
          )}
          {renderCardPoint(
            "จำนวนวันที่ต้องซ่อม",
            numOfDharmaCompensate,
            <FrownOutlined />,
            "#cf1322"
          )}
          {renderCardPoint(
            "จำนวนวันที่เหลือ",
            numOfRemaining,
            <CalendarOutlined />,
            "#faad14"
          )}
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Text>{`เปอร์เซ็นผลความสำเร็จของปฏิบัติธรรมตามจำนวนวันที่คาดหวัง (${percentData}%)`}</Text>
              <Progress percent={percentData} status="active" />
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <center>
                  <Title level={5}>ประวัติปฏิบัติธรรมออนไลน์</Title>
                </center>
                {renderStatusSmile("ONLINE")}
                {allDharmaOnline.length <= 0 && (
                  <div style={{ alignSelf: "center", textAlign: "center" }}>
                    - - ยังไม่มีประวัติ - -
                  </div>
                )}
                {allDharmaOnline.length > 0 && (
                  <Timeline
                    //pending="Recording..."
                    className="timelinelist"
                    reverse={false}
                  >
                    {allDharmaOnline.map((t, index) => {
                      var styleColor = "green";
                      if (t.dharma_online_results_status === "รอตรวจสอบ") {
                        styleColor = "orange";
                      } else if (t.dharma_online_results_status === "อนุมัติ") {
                        styleColor = "green";
                      } else if (
                        t.dharma_online_results_status === "ไม่อนุมัติ"
                      ) {
                        styleColor = "red";
                      }
                      return (
                        <Timeline.Item color={styleColor} key={t.id}>
                          <Title level={5}>{t.dharma_online_name}</Title>
                          <div
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <Text>
                              จำนวน {t.dharma_online_results_point_day} วัน
                            </Text>
                            <Text>
                              รายงานเมื่อ{" "}
                              {moment(t.dharma_online_results_updated_dt)
                                .add(543, "year")
                                .format("DD-MM-YYYY HH:mm")}
                            </Text>
                          </div>
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                )}
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <center>
                  <Title level={5}>ประวัติปฏิบัติธรรมในสถานที่</Title>
                </center>
                {renderStatusSmile("ONSITE")}
                {allDharmaOnsite.length <= 0 && (
                  <div style={{ alignSelf: "center", textAlign: "center" }}>
                    - - ยังไม่มีประวัติ - -
                  </div>
                )}
                {allDharmaOnsite.length > 0 && (
                  <Timeline
                    //pending="Recording..."
                    className="timelinelist"
                    reverse={false}
                  >
                    {allDharmaOnsite.map((t, index) => {
                      var styleColor = "green";
                      if (
                        t.dharma_onsite_results_status === "รอตรวจสอบ" ||
                        t.dharma_onsite_results_status === "รอดำเนินการ"
                      ) {
                        styleColor = "orange";
                      } else if (t.dharma_onsite_results_status === "อนุมัติ") {
                        styleColor = "green";
                      } else if (
                        t.dharma_onsite_results_status === "ไม่อนุมัติ"
                      ) {
                        styleColor = "red";
                      }
                      return (
                        <Timeline.Item color={styleColor} key={t.id}>
                          <Title level={5}>{t.dharma_onsite_name}</Title>
                          <div
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <Text>
                              จำนวน {t.dharma_onsite_results_point_day} วัน
                            </Text>
                            <Text>
                              รายงานเมื่อ{" "}
                              {moment(t.dharma_onsite_results_updated_dt)
                                .add(543, "year")
                                .format("DD-MM-YYYY HH:mm")}
                            </Text>
                          </div>
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                )}
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <center>
                  <Title level={5}>ประวัติปฏิบัติธรรมประจำปี</Title>
                  {renderStatusSmile("ONYEAR")}
                </center>
                {allDharmaOnyear.length <= 0 && (
                  <div style={{ alignSelf: "center", textAlign: "center" }}>
                    - - ยังไม่มีประวัติ - -
                  </div>
                )}
                {allDharmaOnyear.length > 0 && (
                  <Timeline
                    //pending="Recording..."
                    className="timelinelist"
                    reverse={false}
                  >
                    {allDharmaOnyear.map((t, index) => {
                      var styleColor = "green";
                      if (!t.dharma_onyear_isDiscussion) {
                        styleColor = "orange";
                      } else if (t.dharma_onyear_isDiscussion) {
                        styleColor = "green";
                      }
                      return (
                        <Timeline.Item color={styleColor} key={t.id}>
                          <Title level={5}>{t.dharma_onyear_name}</Title>
                          <div
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <Text>
                              จำนวนปฏิบัติ{" "}
                              {t.dharma_onyear_register_numOfDharma} วัน
                            </Text>
                            <Text>
                              จำนวนซ่อม{" "}
                              {t.dharma_onyear_register_numOfDharmaCompensate}{" "}
                              วัน
                            </Text>
                            <Text>
                              รายงานเมื่อ{" "}
                              {t.dharma_onyear_discussionTime
                                ? moment(t.dharma_onyear_discussionTime)
                                    .add(543, "year")
                                    .format("DD-MM-YYYY HH:mm")
                                : "รอตรวจสอบ"}
                            </Text>
                          </div>
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                )}
              </div>
            </Card>
          </Col>
        </Row>
        {/* <div style={{ width: 300, wordWrap: "break-word" }}>
          {JSON.stringify(allData)}
        </div> */}
      </div>
    </>
  );
}

export default DharmaResult;
