import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const getAllFields = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/fields`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getAllFieldsActive = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/fields/active`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createField = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/fields`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const updateField = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/fields`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};
