import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";


export const getMe = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users/me`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getAllUsers = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createDatas = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users`;
  const res = await RunApiRefreshTokenMultipart("POST", url, data);
  return res;
};

export const updateDatas = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};

export const importUsers = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users/import`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const updateUserProfile = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users/profile`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};

export const getAllCompensate = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users/user-compensate`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const unlockUserCompensate = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users/unlock-user-compensate`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};