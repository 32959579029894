import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Typography,
  Image,
  Alert,
  Radio,
  List,
} from "antd";
import {
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import {
  findAllResultsByAdmin,
  adminSendCheckerResultGo,
  generatePDFApproveGo,
} from "../../services/DharamaOnsiteResultsService";
import { NotificationFetch } from "../../utils/NotificationFetch";
import moment from "moment";
import { Link } from "react-router-dom";

const { TextArea } = Input;

function DharmaOnsiteGoChecker() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [dharmaOnsiteTitle, setDharmaOnsiteTitle] = useState(null);
  const [dharmaOnsiteRoom, setDharmaOnsiteRoom] = useState(null);
  const [dharmaOnsiteStartEnd, setDharmaOnsiteStartEnd] = useState(null);
  const [dharmaOnsiteResultStatus, setDharmaOnsiteResultStatus] =
    useState(null);
  const [dharmaOnsiteResultNote, setDharmaOnsiteResultNote] = useState(null);
  const [adminApprove, setAdminApprove] = useState(null);
  const [adminApproveTime, setAdminApproveTime] = useState(null);
  const [adminApproveNote, setAdminApproveNote] = useState(null);

  const [userInfoCode, setUserInfoCode] = useState(null);
  const [userInfoName, setUserInfoName] = useState(null);
  const [userField, setUserField] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userRequestTime, setUserRequestTime] = useState(null);
  const [userCreatedDt, setUserCreatedDt] = useState(null);
  const [userUpdatedDt, setUserUpdatedDt] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [isApprove, setIsApprove] = useState("อนุมัติ");

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [editId, setEditId] = useState(-1);

  const [formEdit] = Form.useForm();

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await findAllResultsByAdmin();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const openEdit = (data) => {
    setIsApprove("อนุมัติ");
    setDharmaOnsiteResultStatus(data.dharma_onsite_results_statusgo);
    const adminName = `${data.admins_firstName} ${data.admins_nickname || ""} ${
      data.admins_lastName || ""
    }`;
    setAdminApprove(adminName);
    setAdminApproveNote(data.dharma_onsite_results_admin_checkgo_note);
    setAdminApproveTime(
      moment(data.dharma_onsite_results_admin_checkgo_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm")
    );
    const startEndOnsite = `วันที่เวลากิจกรรม: ${moment(
      data.dharma_onsite_startOnsiteTime
    )
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm")} ถึง ${moment(
      data.dharma_onsite_endOnsiteTime
    )
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm")}`;
    const usersCode = `รหัสนักศึกษา: ${data.users_code} `;
    const usersName = `ชื่อ: ${data.users_firstName} ${data.users_nickname} ${data.users_lastName}`;
    const note =
      data.dharma_onsite_results_detail.length > 0
        ? data.dharma_onsite_results_detail
        : "-";
    const userRequest = `ขออนุมัติเข้าร่วมวันที่: ${
      moment(data.dharma_onsite_results_user_startgo_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm") +
      " ถึง " +
      moment(data.dharma_onsite_results_user_endgo_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm")
    }`;
    setDharmaOnsiteTitle(`กิจกรรม: ${data.dharma_onsite_name}`);
    setDharmaOnsiteRoom(`สถานที่: ${data.rooms_name}`);
    setDharmaOnsiteStartEnd(startEndOnsite);
    setDharmaOnsiteResultNote(`สรุปเพิ่มเติม : ${note}`);
    setUserInfoCode(usersCode);
    setUserInfoName(usersName);
    setUserType(`ประเภทนิสิต: ${data.graduate_types_name}`);
    setUserField(
      `สาขา: ${data.fields_name} ${data.fields_level} ${data.fields_type}`
    );
    setUserRequestTime(userRequest);
    setUserCreatedDt(
      `วันที่สร้างคำขออนุมัติ: ${moment(data.dharma_onsite_results_created_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm")}`
    );
    setUserUpdatedDt(
      `วันที่อัปเดตล่าสุด: ${moment(data.dharma_onsite_results_updated_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm")}`
    );
    setEditId(data.dharma_onsite_results_id);
    setImageShow(data.dharma_onsite_results_image);
    setIsModalEditVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const renderStatusgo = (statusText, adminNote = "", data) => {
    if (statusText === "รอตรวจสอบ") {
      return (
        <>
          <Alert message="รอตรวจสอบ" type="warning" showIcon />
          <Button
            onClick={() =>
              generatePDFApproveGo("request", data.dharma_onsite_results_uuid)
            }
            download
            block
            icon={<DownloadOutlined />}
          >
            บันทึกข้อความ (ฉบับร่าง)
          </Button>
        </>
      );
    } else if (statusText === "อนุมัติ") {
      return (
        <>
          <Alert message="อนุมัติแล้ว" type="success" showIcon />
          <Button
            onClick={() =>
              generatePDFApproveGo("approve", data.dharma_onsite_results_uuid)
            }
            download
            block
            icon={<DownloadOutlined />}
          >
            บันทึกข้อความ (ฉบับมีเลขที่)
          </Button>
        </>
      );
    } else if (statusText === "ไม่อนุมัติ") {
      return (
        <>
          <Alert message="ไม่อนุมัติ" type="error" showIcon />
          {adminNote.trim().length > 0 && (
            <Alert
              style={{ whiteSpace: "pre-wrap" }}
              message={adminNote}
              type="error"
              showIcon
            />
          )}
        </>
      );
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            aspectRatio: "16/9",
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 100,
          aspectRatio: "16/9",
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "รหัส",
      dataIndex: "dharma_onsite_results_id",
      width: 110,
      key: "dharma_onsite_results_id",
      ...getColumnSearchProps("dharma_onsite_results_id"),
      sorter: (a, b) => a.dharma_onsite_results_id - b.dharma_onsite_results_id,
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_id"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "ภาพปกกิจกรรม",
      dataIndex: "dharma_onsite_image",
      width: 140,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.dharma_onsite_image) : null,
    },
    {
      title: "หัวข้อกิจกรรม",
      width: 220,
      dataIndex: "dharma_onsite_name",
      key: "dharma_onsite_name",
      ...getColumnSearchProps("dharma_onsite_name"),
      sorter: (a, b) =>
        a.dharma_onsite_name.localeCompare(b.dharma_onsite_name),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานะการอนุมัติเข้าร่วม",
      width: 240,
      dataIndex: "dharma_onsite_results_statusgo",
      key: "dharma_onsite_results_statusgo",
      ...getColumnSearchProps("dharma_onsite_results_statusgo"),
      sorter: (a, b) =>
        a.dharma_onsite_results_statusgo.localeCompare(
          b.dharma_onsite_results_statusgo
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_statusgo"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        renderStatusgo(
          record.dharma_onsite_results_statusgo,
          record.dharma_onsite_results_admin_checkgo_note,
          record
        ),
    },
    {
      title: "วันที่ขอเข้าร่วม",
      width: 220,
      dataIndex: "dharma_onsite_results_user_startgo_dt",
      key: "dharma_onsite_results_user_startgo_dt",
      ...getColumnSearchProps("dharma_onsite_results_user_startgo_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_name.localeCompare(
          b.dharma_onsite_results_user_startgo_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_user_startgo_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onsite_results_user_startgo_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm") +
        " - " +
        moment(record.dharma_onsite_results_user_endgo_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "สถานที่",
      dataIndex: "rooms_name",
      key: "rooms_name",
      ...getColumnSearchProps("rooms_name"),
      sorter: (a, b) => a.rooms_name.localeCompare(b.rooms_name),
      sortOrder:
        sortedInfo.columnKey === "rooms_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "รหัสนักศึกษา",
      width: 160,
      dataIndex: "users_code",
      key: "users_code",
      ...getColumnSearchProps("users_code"),
      sorter: (a, b) => a.users_code.localeCompare(b.users_code),
      sortOrder:
        sortedInfo.columnKey === "users_code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      width: 160,
      dataIndex: "users_firstName",
      key: "users_firstName",
      ...getColumnSearchProps("users_firstName"),
      sorter: (a, b) => a.users_firstName.localeCompare(b.users_firstName),
      sortOrder:
        sortedInfo.columnKey === "users_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      width: 160,
      dataIndex: "users_nickname",
      key: "users_nickname",
      ...getColumnSearchProps("users_nickname"),
      sorter: (a, b) => a.users_nickname.localeCompare(b.users_nickname),
      sortOrder:
        sortedInfo.columnKey === "users_nickname" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      width: 160,
      dataIndex: "users_lastName",
      key: "users_lastName",
      ...getColumnSearchProps("users_lastName"),
      sorter: (a, b) => a.users_lastName.localeCompare(b.users_lastName),
      sortOrder:
        sortedInfo.columnKey === "users_lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สาขา",
      width: 160,
      dataIndex: "fields_name",
      key: "fields_name",
      ...getColumnSearchProps("fields_name"),
      sorter: (a, b) => a.fields_name.localeCompare(b.fields_name),
      sortOrder:
        sortedInfo.columnKey === "fields_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ระดับ",
      width: 160,
      dataIndex: "fields_level",
      key: "fields_level",
      ...getColumnSearchProps("fields_level"),
      sorter: (a, b) => a.fields_level.localeCompare(b.fields_level),
      sortOrder:
        sortedInfo.columnKey === "fields_level" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วันเวลาที่สร้างคำขออนุมติ",
      width: 240,
      dataIndex: "dharma_onsite_results_created_dt",
      key: "dharma_onsite_results_created_dt",
      ...getColumnSearchProps("dharma_onsite_results_created_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_created_dt.localeCompare(
          b.dharma_onsite_results_created_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_created_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onsite_results_created_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาอัปเดตล่าสุด",
      width: 240,
      dataIndex: "dharma_onsite_results_updated_dt",
      key: "dharma_onsite_results_updated_dt",
      ...getColumnSearchProps("dharma_onsite_results_updated_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_updated_dt.localeCompare(
          b.dharma_onsite_results_updated_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_updated_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onsite_results_updated_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "เจ้าหน้าที่ตรวจสอบล่าสุด",
      width: 220,
      dataIndex: "admins_firstName",
      key: "admins_firstName",
      ...getColumnSearchProps("admins_firstName"),
      sorter: (a, b) => a.admins_firstName.localeCompare(b.admins_firstName),
      sortOrder:
        sortedInfo.columnKey === "admins_firstName" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => record.admins_firstName || "-",
    },
    {
      title: "วันเวลาเจ้าหน้าที่ตรวจสอบล่าสุด",
      width: 240,
      dataIndex: "dharma_onsite_results_admin_checkgo_dt",
      key: "dharma_onsite_results_admin_checkgo_dt",
      ...getColumnSearchProps("dharma_onsite_results_admin_checkgo_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_admin_checkgo_dt.localeCompare(
          b.dharma_onsite_results_admin_checkgo_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_admin_checkgo_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        record.dharma_onsite_results_admin_checkgo_dt
          ? moment(record.dharma_onsite_results_admin_checkgo_dt)
              .add(543, "year")
              .format("DD-MM-YYYY HH:mm")
          : "-",
    },
    {
      title: "ตรวจสอบ",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"ตรวจสอบ"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const handleEditOk = () => {
    setLoading(true);
    formEdit
      .validateFields()
      .then(async (values) => {
        const data = {
          id: editId,
          statusgo: values.statusgo,
          approveNo: values.approveNo || "",
          admin_checkgo_note: values.admin_checkgo_note || "",
        };
        const res = await adminSendCheckerResultGo(data);
        var newData = res.data[0];
        if (!res._error) {
          const updateData = allData.map((item) => {
            if (item.dharma_onsite_results_id === editId) {
              return {
                ...item,
                ...newData,
              };
            }
            return item;
          });
          setAllData(updateData);
          setIsModalEditVisible(false);
          setImageShow(null);
          formEdit.resetFields();
          NotificationFetch();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          อนุมัติคำขอเข้าร่วมปฏิบัติธรรมในสถานที่
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: "max-content" }}
                  sticky
                  rowKey="dharma_onsite_results_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>

              <Modal
                title="อนุมัติคำขอเข้าร่วมปฏิบัติธรรมในสถานที่"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  setIsApprove("อนุมัติ");
                  formEdit.resetFields();
                  setIsModalEditVisible(false);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      setIsApprove("อนุมัติ");
                      formEdit.resetFields();
                      setIsModalEditVisible(false);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    disabled={dharmaOnsiteResultStatus === "อนุมัติ"}
                    onClick={handleEditOk}
                  >
                    ยืนยันผลการอนุมติ
                  </Button>,
                ]}
              >
                <>
                  <Row gutter={[24, 0]}>
                    <Col xs={24} md={12}>
                      <List
                        header={<div>{dharmaOnsiteTitle}</div>}
                        //footer={<div>Footer</div>}
                        bordered
                        size="small"
                      >
                        <List.Item>{dharmaOnsiteRoom}</List.Item>
                        <List.Item>{dharmaOnsiteStartEnd}</List.Item>
                        <List.Item>{userInfoCode}</List.Item>
                        <List.Item>{userInfoName}</List.Item>
                        <List.Item>{userType}</List.Item>
                        <List.Item>{userField}</List.Item>
                        <List.Item>{userRequestTime}</List.Item>
                        <List.Item>{userCreatedDt}</List.Item>
                        <List.Item>{userUpdatedDt}</List.Item>
                      </List>
                    </Col>
                    <Col xs={24} md={12}>
                      {dharmaOnsiteResultStatus === "อนุมัติ" && (
                        <>
                          <Alert
                            message="อนุมัติแล้ว"
                            description={`เจ้าหน้าที่ได้อนุมัติผลคำขอเข้าร่วมปฏิบัติธรรมในสถานที่นี้แล้ว โดย ${adminApprove} ล่าสุดเมื่อ ${adminApproveTime}`}
                            type="success"
                            showIcon
                          />
                        </>
                      )}
                      {dharmaOnsiteResultStatus === "ไม่อนุมัติ" && (
                        <>
                          <Alert
                            message="ไม่อนุมัติ"
                            description={`เจ้าหน้าที่ไม่อนุมัติผลคำขอเข้าร่วมปฏิบัติธรรมในสถานที่นี้ โดย ${adminApprove} ล่าสุดเมื่อ ${adminApproveTime} เนื่องจาก ${adminApproveNote}`}
                            type="error"
                            showIcon
                            style={{ marginBottom: 20 }}
                          />
                        </>
                      )}
                      {dharmaOnsiteResultStatus !== "อนุมัติ" && (
                        <Form
                          layout={"vertical"}
                          form={formEdit}
                          initialValues={{
                            layout: "vertical",
                            statusgo: "อนุมัติ",
                            point_day: 1,
                          }}
                        >
                          <Form.Item
                            label="ผลการอนุมัติ"
                            name="statusgo"
                            required
                            onChange={(e) => setIsApprove(e.target.value)}
                          >
                            <Radio.Group>
                              <Radio value="อนุมัติ"> อนุมัติ </Radio>
                              <Radio value="ไม่อนุมัติ"> ไม่อนุมัติ </Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            label="เลขที่บันทึกข้อความ"
                            name="approveNo"
                            required={isApprove === "อนุมัติ"}
                            rules={[
                              {
                                required: isApprove === "อนุมัติ",
                                message: "กรุณาระบุเลขที่บันทึกข้อความ!",
                              },
                            ]}
                          >
                            <Input
                              disabled={isApprove !== "อนุมัติ"}
                              placeholder="ระบุเลขที่บันทึกข้อความ"
                            />
                          </Form.Item>
                          <Form.Item
                            label="รายละเอียดเพิ่มเติม"
                            name="admin_checkgo_note"
                          >
                            <TextArea
                              rows={4}
                              placeholder="ระบุรายละเอียดเพิ่มเติม"
                            />
                          </Form.Item>
                        </Form>
                      )}
                    </Col>
                  </Row>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnsiteGoChecker;
