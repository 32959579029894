import React from "react";
import { Row, Col, Card, List } from "antd";
import { ReadOutlined, UserOutlined } from "@ant-design/icons";
import { SubAdminChecker } from "../../utils/AdminChecker";

const dataForAdmin = [
  {
    title: "ข้อมูลคำนำหน้าชื่อ",
    description: "เพิ่ม แก้ไข จัดการข้อมูลคำนำหน้าชื่อ",
    icon: <ReadOutlined />,
    link: "/basic-data/prefix",
  },
  {
    title: "ข้อมูลประเภทบัณฑิต",
    description: "เพิ่ม แก้ไข จัดการข้อมูลประเภทบัณฑิต",
    icon: <ReadOutlined />,
    link: "/basic-data/graduate-type",
  },
  {
    title: "ข้อมูลสาขา",
    description: "เพิ่ม แก้ไข จัดการข้อมูลสาขา",
    icon: <ReadOutlined />,
    link: "/basic-data/field",
  },
  {
    title: "ข้อมูลนิสิต",
    description: "เพิ่ม แก้ไข นำเข้า จัดการข้อมูลนิสิต",
    icon: <UserOutlined />,
    link: "/basic-data/users",
  },
  {
    title: "ข้อมูลเจ้าหน้าที่",
    description: "เพิ่ม แก้ไข จัดการข้อมูลเจ้าหน้าที่",
    icon: <UserOutlined />,
    link: "/basic-data/admin",
  },
  {
    title: "ข้อมูลวิปัสสนาจารย์",
    description: "เพิ่ม แก้ไข จัดการข้อมูลวิปัสสนาจารย์",
    icon: <UserOutlined />,
    link: "/basic-data/teacher",
  },
];

const dataForSubAdmin = [
  {
    title: "ข้อมูลคำนำหน้าชื่อ",
    description: "เพิ่ม แก้ไข จัดการข้อมูลคำนำหน้าชื่อ",
    icon: <ReadOutlined />,
    link: "/basic-data/prefix",
  },
  {
    title: "ข้อมูลประเภทบัณฑิต",
    description: "เพิ่ม แก้ไข จัดการข้อมูลประเภทบัณฑิต",
    icon: <ReadOutlined />,
    link: "/basic-data/graduate-type",
  },
  {
    title: "ข้อมูลสาขา",
    description: "เพิ่ม แก้ไข จัดการข้อมูลสาขา",
    icon: <ReadOutlined />,
    link: "/basic-data/field",
  },
];

function BasicDataMain() {
  SubAdminChecker();

  const userRole = localStorage.getItem("@userRole");
 
  var data = [];
  if (userRole === "admin") {
    data = dataForAdmin;
  } else {
    data = dataForSubAdmin;
  }

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">ข้อมูลพื้นฐาน</h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={item.icon}
                        title={<a href={item.link}>{item.title}</a>}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default BasicDataMain;
