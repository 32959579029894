import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Typography,
  Image,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { AdminChecker } from "../../utils/AdminChecker";
import {
  getAllBanner,
  createBanner,
  updateBanner,
} from "../../services/BannerService";
import { getAllAssessment } from "../../services/AssessmentService";
import moment from "moment";
import { Link } from "react-router-dom";
import AssessmentModal from "./components/AssessmentModal";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

function AssessmentData() {
  AdminChecker();

  const [allData, setAllData] = useState([]);
  const [isOpenAssessment, setIsOpenAssessment] = useState(false);
  const [assessmentUuid, setAssessmentUuid] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllAssessment();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const handleSwitch = async (values, e) => {
    const formData = new FormData();
    formData.append("id", values.assessment_id);
    formData.append("isActive", e);
    const res = await updateBanner(formData);
    var newData = res.data[0];
    if (!res._error) {
      const updateData = allData.map((item) => {
        if (item.assessment_id === values.assessment_id) {
          var userActive = false;
          if (e === true || e === 1) {
            userActive = true;
          }
          return { ...item, ...newData, assessment_isActive: userActive };
        }
        return item;
      });
      setAllData(updateData);
    }
  };

  const openEdit = (data) => {
    // todo
    setAssessmentUuid(data.assessment_uuid);
    setIsOpenAssessment(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "ชื่อแบบประเมิน",
      dataIndex: "assessment_name",
      key: "assessment_name",
      ...getColumnSearchProps("assessment_name"),
      sorter: (a, b) => a.assessment_name.localeCompare(b.assessment_name),
      sortOrder:
        sortedInfo.columnKey === "assessment_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วันที่สร้างแบบประเมิน",
      dataIndex: "assessment_created_dt",
      width: 200,
      key: "assessment_created_dt",
      ...getColumnSearchProps("assessment_created_dt"),
      sorter: (a, b) =>
        a.assessment_created_dt.localeCompare(b.assessment_created_dt),
      sortOrder:
        sortedInfo.columnKey === "assessment_created_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.assessment_created_dt).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "สถานะการใช้งาน",
      dataIndex: "assessment_isActive",
      sorter: (a, b) =>
        JSON.stringify(a.assessment_isActive).localeCompare(
          JSON.stringify(b.assessment_isActive)
        ),
      sortOrder:
        sortedInfo.columnKey === "assessment_isActive"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      width: 160,
      render: (_, record) =>
        allData.length >= 1 ? (
          <Switch
            checkedChildren="เปิด "
            unCheckedChildren="ปิด "
            checked={record.assessment_isActive}
            onChange={(e) => handleSwitch(record, e)}
          />
        ) : null,
    },
    {
      title: "ดูตัวอย่าง",
      dataIndex: "operation",
      //fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"ดูตัวอย่าง"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">จัดการแบบประเมิน</h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Link
                          to={"/assessment/assessment-data/assessment-create"}
                        >
                          <Button type="ghost" icon={<PlusCircleOutlined />}>
                            สร้างแบบประเมินใหม่
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: "max-content" }}
                  rowKey="assessment_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <AssessmentModal
        isOpenAssessment={isOpenAssessment}
        assessmentUuid={assessmentUuid}
        onClose={() => setIsOpenAssessment(false)}
      />
    </>
  );
}

export default AssessmentData;
