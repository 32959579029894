import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";

export const checkUserIsSent = async (dharmaOnlineId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online-results/user/${dharmaOnlineId}`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const findAllResultsByUserId = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online-results/user`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const findAllResultsByAdmin = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online-results`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const adminSendCheckerResult = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online-results/admin-result`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const userCreateDharmaOnline = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online-results/user`;
  const res = await RunApiRefreshTokenMultipart("POST", url, data);
  return res;
};

export const userUpdateDharmaOnline = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online-results/user`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};

export const reportResultsByUserId = async (userCode) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online-results/report-user/${userCode}`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};
