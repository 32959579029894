import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const getNotiUserAll = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/notifications/user`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getNotiAdminAll = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/notifications/admin`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getNotiUserUnReadAll = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/notifications/user-unread`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getNotiAdminUnReadAll = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/notifications/admin-unread`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const readNotificationByNotiId = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/notifications/read`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const getNotiAdminUnApproveOnlineAll = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online-results/wait-approve`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getNotiAdminUnApproveOnsiteGo = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/wait-approve-go`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getNotiAdminUnApproveOnsite = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite-results/wait-approve`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};
