import axios from "axios";
import jwtDecode from "jwt-decode";
import { APPCONFIG } from "../config/app.config";
import { RunAPI } from "./RunApi";
import { message } from "antd";

const axiosApiInstance = axios.create();

const SUCCESS_MESSAGE = `ดำเนินการเรียบร้อย!!`;
const TEXT_ERROR_MESSAGE = `เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!!`;

const refreshing = async () => {
  const jwt = localStorage.getItem("@userJwt");
  const refreshToken = localStorage.getItem("@userRefreshToken");
  if (!jwt || jwt === "" || !refreshToken || refreshToken === "") {
    // show popup and re-login
    //console.log('show popup and re-login 1');
    localStorage.removeItem("@userJwt");
    localStorage.removeItem("@userRefreshToken");
    localStorage.removeItem("@userName");
    localStorage.removeItem("@userNickname");
    localStorage.removeItem("@userLastname");
    localStorage.removeItem("@userId");
    localStorage.removeItem("@userRole");
    localStorage.removeItem("@userAvatar");
    window.location = "/home";
    //store.dispatch(updateSuperSessionExpired(true));
    throw new Error("not found token!");
  }

  //check time access token expire
  const decoded = jwtDecode(jwt);
  const jwtExp = decoded.exp;
  const nowTime = (Date.now() / 1000).toFixed();
  console.log(jwtExp, nowTime);

  if (jwtExp <= nowTime) {
    console.log("refreshtoekn");
    const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/users/token`;
    const apikey = `${APPCONFIG.APIKEY}`;
    const data = { refreshToken: refreshToken };
    const res = await RunAPI("POST", url, data, apikey);
    if (res.code !== "00000") {
      console.log("error x => ", res);

      // show popup and re-login
      //console.log('show popup and re-login 2');
      localStorage.removeItem("@userJwt");
      localStorage.removeItem("@userRefreshToken");
      localStorage.removeItem("@userName");
      localStorage.removeItem("@userNickname");
      localStorage.removeItem("@userLastname");
      localStorage.removeItem("@userId");
      localStorage.removeItem("@userRole");
      localStorage.removeItem("@userAvatar");
      window.location = "/home";
      //store.dispatch(updateSuperSessionExpired(true));
      throw new Error("token expired!");
    }
    const newJwt = res.data.token;
    const newRefreshToken = res.data.refreshToken;
    //console.log('newRefreshToken', res.data);
    localStorage.setItem("@userJwt", newJwt);
    localStorage.setItem("@userRefreshToken", newRefreshToken);
    return newJwt;
  }
  return jwt;
};

// Add a request interceptor
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await refreshing();
    config.headers = {
      "x-api-key": APPCONFIG.APIKEY,
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

export const RunApiRefreshTokenMultipart = async (Method, Url, Data = {}) => {
  console.log(Method, Url);

  if (Method === "GET") {
    try {
      const response = await axiosApiInstance.get(Url);
      //console.log(JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
  if (Method === "POST") {
    try {
      const response = await axiosApiInstance.post(Url, Data);
      //console.log(JSON.stringify(response.data));
      message.success(SUCCESS_MESSAGE);
      return response.data;
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
  if (Method === "PUT") {
    try {
      const response = await axiosApiInstance.put(Url, Data);
      //console.log(JSON.stringify(response.data));
      message.success(SUCCESS_MESSAGE);
      return response.data;
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
  if (Method === "DELETE") {
    try {
      const response = await axiosApiInstance.delete(Url);
      //console.log(JSON.stringify(response.data));
      message.success(SUCCESS_MESSAGE);
      return response.data;
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
};

const showErrorPopup = () => {
  message.error(TEXT_ERROR_MESSAGE);
};

const toErrorHandleLog = (error) => {
  showErrorPopup();
  if (error.response) {
    // Request made and server responded
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
  const errorData = {
    _error: true,
    _error_data: error,
  };

  if (
    error.message !== "token expired!" &&
    error.message !== "not found token!"
  ) {
    // todo
    //store.dispatch(updateSuperServerError(true));
  }
  return errorData;
};
