export const walkRound = [
  { id: 1, value: "ไม่ได้เดิน" },
  { id: 2, value: "1-5 รอบ" },
  { id: 3, value: "10-20 รอบ" },
  { id: 4, value: "มากกว่า 20 รอบ" },
];
export const walkTime = [
  { id: 1, value: "ไม่ได้เดิน" },
  { id: 2, value: "1-15 นาที" },
  { id: 3, value: "15-30 นาที" },
  { id: 4, value: "มากกว่า 30 นาที" },
];
export const sitRound = [
  { id: 1, value: "ไม่ได้นั่ง" },
  { id: 2, value: "1-5 รอบ" },
  { id: 3, value: "10-20 รอบ" },
  { id: 4, value: "มากกว่า 20 รอบ" },
];
export const sitTime = [
  { id: 1, value: "ไม่ได้นั่ง" },
  { id: 2, value: "1-15 นาที" },
  { id: 3, value: "15-30 นาที" },
  { id: 4, value: "มากกว่า 30 นาที" },
];
export const speak = [
  { id: 1, value: "ปิดวาจาตลอดการปฏิบัติ" },
  { id: 2, value: "ไม่ใด้ปิดวาจาหรือมีสนทนาบ้าง" },
];
export const actions = [
  { id: 1, value: "สติตั้งมั่น" },
  { id: 2, value: "ใจสงบ" },
  { id: 3, value: "จิตผื่นรู้" },
  { id: 4, value: "ใจ เบาสบาย" },
  { id: 5, value: "กาย เบาสมาย" },
  { id: 6, value: "รู้สึกตัวได้มาก" },
  { id: 7, value: "ท่อแห่" },
  { id: 8, value: "ลังเล สงสัย" },
  { id: 9, value: "เบื่อ กลุ่ม" },
  { id: 10, value: "ขี้เกียจ" },
  { id: 11, value: "ปรดยา/เข่า/หลัง" },
  { id: 12, value: "ปวดหัว" },
  { id: 13, value: "เรียนศีรษะ" },
  { id: 14, value: "แน่นหน้าอก" },
  { id: 15, value: "รู้สึกตัวบางครั้ง" },
  { id: 16, value: "ฟุ้งซ่าน รำคาญใจ" },
  { id: 17, value: "รู้สึกชานซ่าน" },
  { id: 18, value: "รู้สึกขนลุก" },
  { id: 19, value: "รู้สึกน้ำตาไหล" },
  { id: 20, value: "รู้สึกตัวใหญ่ ตัวพอง" },
  { id: 21, value: "รู้สึกตัวโยก ตัวสั่น" },
  { id: 22, value: "ง่วงหงาว หาวนอน" },
  { id: 23, value: "เห็นแสงศว่าง" },
  { id: 24, value: "เห็นดวงไฟ" },
  { id: 25, value: "เห็นซากศพ" },
  { id: 26, value: "เห็นโดรงกระดูก" },
  { id: 27, value: "เห็นภาพด่างๆ" },
  { id: 28, value: "กำหนัดยินดีในกาม" },
  { id: 29, value: "โกรธ" },
  { id: 30, value: "หงุดหงิด" },
  { id: 31, value: "รู้สึกกายไหวๆ" },
];

export const solveProblem = [
  { id: 1, value: "ปฏิบัติต่อไป โดยวางปัญหานั้นๆไว้ก่อน" },
  { id: 2, value: "เปิดหาสืบค้นในหนังสือ หรือสื่อต่างๆบนเว็ปไซต์ออนไลน์" },
  { id: 3, value: "สอบถามจากเพื่อนบุคคลใกล้ชิดเพื่อหาคำตอบ" },
  { id: 4, value: "เลิกปฏิบัติ" },
];

export const progress = [
  { id: 1, value: "ก้าวหน้า" },
  { id: 2, value: "เท่าเดิม" },
  { id: 3, value: "ลดลง" },
];
