import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Form,
  Switch,
  Select,
  Typography,
} from "antd";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import { getReportOverall } from "../../services/DharmaOnsiteService";

function DharmaOnsiteReport() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    // todo
    // get all User to checker
    initAllFields();
  }, []);

  const initAllFields = async () => {
    const res = await getReportOverall();
    if (!res._error) {
      setAllFields(res.data);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 120,
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อกิจกรรมปฏิบัติธรรมในสถานที่",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "รายงานทั้งหมด",
      dataIndex: "sumAll",
      key: "sumAll",
      ...getColumnSearchProps("sumAll"),
      sorter: (a, b) => a.sumAll - b.sumAll,
      sortOrder: sortedInfo.columnKey === "sumAll" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "รายงานที่รอตรวจสอบ",
      dataIndex: "sumWait",
      key: "sumWait",
      ...getColumnSearchProps("sumWait"),
      sorter: (a, b) => a.sumWait - b.sumWait,
      sortOrder: sortedInfo.columnKey === "sumWait" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "รายงานที่อนุมัติ",
      dataIndex: "sumApprove",
      key: "sumApprove",
      ...getColumnSearchProps("sumApprove"),
      sorter: (a, b) => a.sumApprove - b.sumApprove,
      sortOrder:
        sortedInfo.columnKey === "sumApprove" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "รายงานที่ไม่อนุมัติ",
      dataIndex: "sumReject",
      key: "sumReject",
      ...getColumnSearchProps("sumReject"),
      sorter: (a, b) => a.sumReject - b.sumReject,
      sortOrder: sortedInfo.columnKey === "sumReject" ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">รายงานภาพรวมปฏิบัติธรรมในสถานที่</h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allFields}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 1800 }}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnsiteReport;
