import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Modal,
} from "antd";
import moment from "moment";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { findAllResultsByUserId } from "../../services/DharmaOnyearService";
import DhmOnYearRegister from "./components/DhmOnYearRegister";

function DharmaOnYearUserChecker() {
  const [loading, setLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [registerData, setRegisterData] = useState([]);
  const [userRegisterData, setUserRegisterData] = useState([]);
  const [onYearName, setOnYearName] = useState("");
  const [onYearId, setOnYearId] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    // todo
    // get all User to checker
    initAllFields();
  }, []);

  const initAllFields = async () => {
    const res = await findAllResultsByUserId();
    if (!res._error) {
      setAllFields(res.data);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const openData = async (data) => {
    console.log("openData", data);
    setOnYearName(data.name);
    setOnYearId(data.id);
    // const res = await getRegisterUserData(data.id);
    // if (!res._error) {
    //   setUserRegisterData(res.data);
    //   setIsModalVisible(true);
    // }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 60,
      ellipsis: true,
      render: (_, record, index) =>
        allFields.length >= 1 ? <div>{index + 1}</div> : null,
    },
    {
      title: "ชื่อกิจกรรม",
      dataIndex: "dharma_onyear_name",
      key: "dharma_onyear_name",
      ...getColumnSearchProps("dharma_onyear_name"),
      sorter: (a, b) =>
        a.dharma_onyear_name.localeCompare(b.dharma_onyear_name),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วันที่เริ่มต้นกิจกรรม",
      dataIndex: "dharma_onyear_startOnyearTime",
      key: "dharma_onyear_startOnyearTime",
      ...getColumnSearchProps("dharma_onyear_startOnyearTime"),
      sorter: (a, b) =>
        a.dharma_onyear_startOnyearTime.localeCompare(
          b.dharma_onyear_startOnyearTime
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_startOnyearTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onyear_startOnyearTime).format("DD-MM-YYYY"),
    },
    {
      title: "วันที่สิ้นสุดกิจกรรม",
      dataIndex: "dharma_onyear_endOnyearTime",
      key: "dharma_onyear_endOnyearTime",
      ...getColumnSearchProps("dharma_onyear_endOnyearTime"),
      sorter: (a, b) =>
        a.dharma_onyear_endOnyearTime.localeCompare(
          b.dharma_onyear_endOnyearTime
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_endOnyearTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onyear_endOnyearTime).format("DD-MM-YYYY"),
    },
    {
      title: "จำนวนวันที่ปฏิบัตืธรรม",
      dataIndex: "dharma_onyear_register_numOfDharma",
      key: "dharma_onyear_register_numOfDharma",
      ...getColumnSearchProps("dharma_onyear_register_numOfDharma"),
      sorter: (a, b) =>
        a.dharma_onyear_register_numOfDharma.localeCompare(
          b.dharma_onyear_register_numOfDharma
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_register_numOfDharma"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) => {
        if (record.dharma_onyear_isDiscussion) {
          return <div>{record.dharma_onyear_register_numOfDharma}</div>;
        } else {
          return <div>รอมติการประชุมสรุป</div>;
        }
      },
    },
    {
      title: "จำนวนวันที่ต้องซ่อม",
      dataIndex: "dharma_onyear_register_numOfDharmaCompensate",
      key: "dharma_onyear_register_numOfDharmaCompensate",
      ...getColumnSearchProps("dharma_onyear_register_numOfDharmaCompensate"),
      sorter: (a, b) =>
        a.dharma_onyear_register_numOfDharmaCompensate.localeCompare(
          b.dharma_onyear_register_numOfDharmaCompensate
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_register_numOfDharmaCompensate"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) => {
        if (record.dharma_onyear_isDiscussion) {
          return (
            <div>{record.dharma_onyear_register_numOfDharmaCompensate}</div>
          );
        } else {
          return <div>รอมติการประชุมสรุป</div>;
        }
      },
    },
    {
      title: "วันที่อนุมัติผล",
      dataIndex: "dharma_onyear_register_updated_dt",
      key: "dharma_onyear_register_updated_dt",
      ...getColumnSearchProps("dharma_onyear_register_updated_dt"),
      sorter: (a, b) =>
        a.dharma_onyear_register_updated_dt.localeCompare(
          b.dharma_onyear_register_updated_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_register_updated_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) => {
        if (record.dharma_onyear_isDiscussion) {
          return (
            <div>
              {moment(record.dharma_onyear_register_updated_dt).format(
                "DD-MM-YYYY"
              )}
            </div>
          );
        } else {
          return <div>รอมติการประชุมสรุป</div>;
        }
      },
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ตรวจสอบผลรายงานปฏิบัติธรรมประจำปี
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allFields}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 1200 }}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />

                <Modal
                  title={`การลงทะเบียน : ${onYearName}`}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                  width="80%"
                >
                  <DhmOnYearRegister
                    onYearId={onYearId}
                    userRegisterDatas={userRegisterData}
                  />
                </Modal>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnYearUserChecker;
