import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    count: 0,
    onlineCount: 0,
    onsiteCount: 0,
    onsiteGoCount: 0,
    onsiteRsCount: 0,
  },
  reducers: {
    updateCount: (state, action) => {
      state.count = action.payload;
    },
    updateOnlineCount: (state, action) => {
      state.onlineCount = action.payload;
    },
    updateOnsiteCount: (state, action) => {
      state.onsiteCount = action.payload;
    },
    updateOnsiteGoCount: (state, action) => {
      state.onsiteGoCount = action.payload;
    },
    updateOnsiteRsCount: (state, action) => {
      state.onsiteRsCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateCount,
  updateOnlineCount,
  updateOnsiteCount,
  updateOnsiteGoCount,
  updateOnsiteRsCount,
} = notificationSlice.actions;

export default notificationSlice.reducer;
