export const AdminChecker = () => {
  const userRole = localStorage.getItem("@userRole");
  if (userRole !== "admin") {
    window.location = "/home";
    return false;
  }
  return true;
};

export const SubAdminChecker = () => {
  const userRole = localStorage.getItem("@userRole");
  if (userRole === "admin" || userRole === "subadmin") {
    return true;
  } else {
    window.location = "/home";
    return false;
  }
};
