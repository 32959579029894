import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";
import { RunAPI } from "../utils/RunApi";

export const getAllDharmaOnsitePlublic = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite/public`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getAllDharmaOnsiteWithResults = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite/user`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getOneDharmaOnsitePlublicId = async (uuid) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite/public/${uuid}`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getAllDharmaOnsite = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createDharmaOnsite = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite`;
  const res = await RunApiRefreshTokenMultipart("POST", url, data);
  return res;
};

export const updateDharmaOnsite = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};

export const getReportOverall = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onsite/report-overall`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};
