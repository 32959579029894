import store from "../store";
import {
  updateCount,
  updateOnlineCount,
  updateOnsiteCount,
  updateOnsiteGoCount,
  updateOnsiteRsCount,
} from "../redux/notification.slice";
import {
  getNotiAdminUnReadAll,
  getNotiAdminUnApproveOnlineAll,
  getNotiAdminUnApproveOnsiteGo,
  getNotiAdminUnApproveOnsite,
} from "../services/NotificationsService";

export const NotificationFetch = async () => {
  const res = await getNotiAdminUnReadAll();
  if (!res._error) {
    store.dispatch(updateCount(res.data.length));
  }
  const resOnline = await getNotiAdminUnApproveOnlineAll();
  if (!resOnline._error) {
    store.dispatch(updateOnlineCount(resOnline.data));
  }
  let countOnsite = 0;
  const resOnsiteGo = await getNotiAdminUnApproveOnsiteGo();
  if (!resOnsiteGo._error) {
    countOnsite = countOnsite + resOnsiteGo.data;
    store.dispatch(updateOnsiteGoCount(resOnsiteGo.data));
  }
  const resOnsite = await getNotiAdminUnApproveOnsite();
  if (!resOnsite._error) {
    countOnsite = countOnsite + resOnsite.data;
    store.dispatch(updateOnsiteRsCount(resOnsite.data));
  }
  store.dispatch(updateOnsiteCount(countOnsite));
};
