import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Modal,
  Checkbox,
  Alert,
} from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  SearchOutlined,
  PlusCircleOutlined,
  PoweroffOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import {
  getRegisterOverall,
  getRegisterUserData,
  startProcessing,
  findAllResultReportChecker,
  submitAllResultReportChecker,
} from "../../services/DharmaOnyearService";
import DhmOnYearRegister from "./components/DhmOnYearRegister";

function DharmaOnYearResultChecker(props) {
  SubAdminChecker();

  if (props.location.state == null) {
    window.location = "/dharma-onyear/dharma-onyear-checker";
  }

  const { onYearId_p, onYearName_p, isStartReport_p, isDiscussion_p } =
    props.location.state;

  const [IsStartReport, setisStartReport] = useState(isStartReport_p);

  const [loading, setLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [allFieldsDate, setAllFieldsDate] = useState([]);
  const [allFieldsDateColum, setAllFieldsDateColum] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [registerData, setRegisterData] = useState([]);
  const [userRegisterData, setUserRegisterData] = useState([]);
  const [onYearName, setOnYearName] = useState("");
  const [onYearId, setOnYearId] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    // todo
    // get all User to checker
    if (isStartReport_p) {
      initAllFields();
    }
  }, []);

  const initAllFields = async () => {
    const res = await findAllResultReportChecker(onYearId_p);
    if (!res._error) {
      setAllFields(res.data.results);
      setAllFieldsDate(res.data.dateList);

      const dateList = res.data.dateList;
      const addColumn = dateList.map((v) => {
        return {
          title: v,
          dataIndex: v,
          key: v,
          ellipsis: true,
          width: 80,
          // render: (_, record) => {
          //   if (record.isActive) {
          //     return <Checkbox value={record.isActive}></Checkbox>;
          //   }
          //   return <Checkbox></Checkbox>;
          // },
        };
      });

      setAllFieldsDateColum(addColumn);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const saveData = async () => {
    setLoading(true);
    const data = {
      dharmaOnyearId: onYearId_p,
      dataList: allFields,
    };
    console.log(data);
    const res = await submitAllResultReportChecker(data);
    if (!res._error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "#",
      width: 50,
      dataIndex: "index",
      key: "index",
      render: (_, record, index) => index + 1,
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "code",
      key: "code",
      width: 120,
      ...getColumnSearchProps("code"),
      sorter: (a, b) => a.code - b.code,
      sortOrder: sortedInfo.columnKey === "code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "firstName",
      key: "firstName",
      width: 140,
      ...getColumnSearchProps("firstName"),
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortOrder: sortedInfo.columnKey === "firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      dataIndex: "lastName",
      key: "lastName",
      width: 140,
      ...getColumnSearchProps("lastName"),
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      sortOrder: sortedInfo.columnKey === "lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สาขา",
      dataIndex: "fieldName",
      key: "fieldName",
      ...getColumnSearchProps("fieldName"),
      sorter: (a, b) => a.fieldName.localeCompare(b.fieldName),
      sortOrder: sortedInfo.columnKey === "fieldName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    // ...allFieldsDateColum,
    ...allFieldsDate.map((v) => {
      return {
        title: (
          <Checkbox onChange={(e) => handleCheckboxAll(e.target.checked, v)}>
            <div style={{ fontSize: 10 }}>{v}</div>
          </Checkbox>
        ),
        dataIndex: v,
        key: v,
        ellipsis: true,
        width: 100,
        render: (_, record) => {
          return (
            <Checkbox
              onChange={(e) => handleCheckbox(e.target.checked, v, record.id)}
              checked={record[v]}
            ></Checkbox>
          );
        },
      };
    }),
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCheckboxAll = (e, key) => {
    const newData = allFields.map((v) => {
      return {
        ...v,
        ...{ [key]: e },
      };
    });
    setAllFields(newData);
  };

  const handleCheckbox = (e, key, id) => {
    const newData = allFields.map((v) => {
      if (v.id === id) {
        console.log(key, e);
        return {
          ...v,
          ...{ [key]: e },
        };
      } else {
        return v;
      }
    });
    setAllFields(newData);
  };

  const handleStartProcessing = async () => {
    setLoading(true);
    const res = await startProcessing(onYearId_p);
    if (!res._error) {
      initAllFields();
      setisStartReport(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={24}>
                        <h6 className="font-semibold m-0">
                          ตรวจสอบผลเข้าร่วมปฏิบัติธรรมประจำปี
                        </h6>
                      </Col>
                      <Col xs={24} md={24}>
                        <h6 className="font-semibold m-0">{onYearName_p}</h6>
                      </Col>
                    </Row>
                    {!IsStartReport && (
                      <Row
                        gutter={[24, 0]}
                        className="ant-row-flex ant-row-flex-middle "
                      >
                        <Col xs={24} md={24}>
                          <center>
                            <Button
                              type="primary"
                              icon={<PoweroffOutlined />}
                              loading={loading}
                              onClick={() => handleStartProcessing()}
                              style={{ margin: 10 }}
                            >
                              เริ่มต้นประมวลผลรายงาน!
                            </Button>
                          </center>
                        </Col>
                      </Row>
                    )}
                  </>
                }
              >
                {!!IsStartReport && (
                  <>
                    <Table
                      size="small"
                      sticky
                      dataSource={allFields}
                      columns={columns}
                      onChange={handleChange}
                      scroll={{ x: "max-content" }}
                      rowKey="id"
                      pagination={false}
                      // pagination={{
                      //   defaultPageSize: 10,
                      //   showSizeChanger: true,
                      //   pageSizeOptions: ["10", "20", "30"],
                      // }}
                    />

                    <Col xs={24} md={24}>
                      <center>
                        {!!isDiscussion_p && (
                          <Alert
                            message="กิจกรรมนี้ถูกประชุมและประมวลผลเรียบร้อยแล้ว"
                            type="success"
                          />
                        )}
                        <Button
                          type="primary"
                          icon={<SaveOutlined />}
                          loading={loading}
                          onClick={() => saveData()}
                          style={{ marginTop: 10 }}
                          block
                          disabled={isDiscussion_p}
                        >
                          บันทึกผลเข้าร่วมปฏิบัติธรรมประจำปี
                        </Button>
                      </center>
                    </Col>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnYearResultChecker;
