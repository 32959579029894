import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Modal,
  Checkbox,
  Alert,
} from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  SearchOutlined,
  PlusCircleOutlined,
  PoweroffOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import {
  getRegisterOverall,
  getRegisterUserData,
  startProcessing,
  findAllResultReportDiscussion,
  submitAllResultReportDiscussion,
} from "../../services/DharmaOnyearService";
import DhmOnYearRegister from "./components/DhmOnYearRegister";

function DharmaOnYearResultDiscussion(props) {
  SubAdminChecker();

  if (props.location.state == null) {
    window.location = "/dharma-onyear/dharma-onyear-discussion";
  }

  const { onYearId_p, onYearName_p, isStartReport_p, isDiscussion_p } =
    props.location.state;

  console.log("isDiscussion_p", isDiscussion_p);
  const [IsStartReport, setisStartReport] = useState(isStartReport_p);

  const [loading, setLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [allFieldsDate, setAllFieldsDate] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isDiscussion, setIsDiscussion] = useState(isDiscussion_p);

  const [alertData, setAlertData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const [registerData, setRegisterData] = useState([]);
  const [userRegisterData, setUserRegisterData] = useState([]);
  const [onYearName, setOnYearName] = useState("");
  const [onYearId, setOnYearId] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    // todo
    // get all User to checker
    if (isStartReport_p) {
      initAllFields();
    }
  }, []);

  const initAllFields = async () => {
    const res = await findAllResultReportDiscussion(onYearId_p);
    if (!res._error) {
      setAllFields(res.data);
      setAllFieldsDate(res.data.dateList);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const saveAlert = () => {
    setIsModalVisible2(true);
  };

  const saveData = async () => {
    // sumCheck
    // calLeft

    const checkData = allFields
      .map((v, index) => {
        let str = "ลำดับที่ " + (index + 1) + " ";
        let check = 0;
        if (v.sumCheck < 0 || v.sumCheck === "" || v.sumCheck === null) {
          str =
            str + "จำนวนวันที่ปฏิบัติได้ต้องเป็นตัวเลขที่มากกว่าหรือเท่ากับ 0 ";
          check++;
        }
        if (v.calLeft < 0 || v.calLeft === "" || v.calLeft === null) {
          str =
            str + "จำนวนวันที่ควรซ่อมต้องเป็นตัวเลขที่มากกว่าหรือเท่ากับ 0 ";
          check++;
        }
        if (check > 0) {
          return str;
        } else {
          return "";
        }
      })
      .filter((x) => x !== "");

    if (checkData.length > 0) {
      setAlertData(checkData);
      setIsModalVisible(true);
      return false;
    }

    setLoading(true);
    const data = {
      dharmaOnyearId: onYearId_p,
      dataList: allFields,
    };
    console.log(data);
    const res = await submitAllResultReportDiscussion(data);
    if (!res._error) {
      setLoading(false);
      setIsDiscussion(true);
    }
    setLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "#",
      width: 50,
      dataIndex: "index",
      key: "index",
      render: (_, record, index) => index + 1,
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "code",
      key: "code",
      width: 120,
      ...getColumnSearchProps("code"),
      sorter: (a, b) => a.code - b.code,
      sortOrder: sortedInfo.columnKey === "code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "firstName",
      key: "firstName",
      width: 140,
      ...getColumnSearchProps("firstName"),
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortOrder: sortedInfo.columnKey === "firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      dataIndex: "lastName",
      key: "lastName",
      width: 140,
      ...getColumnSearchProps("lastName"),
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      sortOrder: sortedInfo.columnKey === "lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สาขา",
      dataIndex: "fieldName",
      key: "fieldName",
      ...getColumnSearchProps("fieldName"),
      sorter: (a, b) => a.fieldName.localeCompare(b.fieldName),
      sortOrder: sortedInfo.columnKey === "fieldName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "จำนวนวันทั้งหมด",
      dataIndex: "sumAllDate",
      key: "sumAllDate",
      width: 140,
      ellipsis: true,
    },
    {
      title: "จำนวนวันที่ปฏิบัติได้",
      dataIndex: "sumCheck",
      key: "sumCheck",
      ellipsis: true,
      render: (_, record) =>
        allFields.length >= 1 ? (
          <Input
            value={record.sumCheck}
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              handleOnChangePoint("sumCheck", e.target.value, record.id)
            }
          />
        ) : null,
    },
    {
      title: "จำนวนวันที่ควรซ่อม",
      dataIndex: "calLeft",
      key: "calLeft",
      ellipsis: true,
      render: (_, record) =>
        allFields.length >= 1 ? (
          <Input
            value={record.calLeft}
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              handleOnChangePoint("calLeft", e.target.value, record.id)
            }
          />
        ) : null,
    },
  ];

  const handleOnChangePoint = (key, value, id) => {
    console.log(key, value, id);
    const newData = allFields.map((v) => {
      if (v.id === id) {
        return {
          ...v,
          [key]: value,
        };
      }
      return v;
    });
    console.log(newData);
    setAllFields(newData);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
    saveData();
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  const handleCheckboxAll = (e, key) => {
    const newData = allFields.map((v) => {
      return {
        ...v,
        ...{ [key]: e },
      };
    });
    setAllFields(newData);
  };

  const handleCheckbox = (e, key, id) => {
    const newData = allFields.map((v) => {
      if (v.id === id) {
        console.log(key, e);
        return {
          ...v,
          ...{ [key]: e },
        };
      } else {
        return v;
      }
    });
    setAllFields(newData);
  };

  const handleStartProcessing = async () => {
    setLoading(true);
    const res = await startProcessing(onYearId_p);
    if (!res._error) {
      initAllFields();
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={24}>
                        <h6 className="font-semibold m-0">
                          ประชุมสรุปผลปฏิบัติธรรมประจำปี
                        </h6>
                      </Col>
                      <Col xs={24} md={24}>
                        <h6 className="font-semibold m-0">{onYearName_p}</h6>
                      </Col>
                    </Row>
                    {!IsStartReport && (
                      <Row
                        gutter={[24, 0]}
                        className="ant-row-flex ant-row-flex-middle "
                      >
                        <Col xs={24} md={24}>
                          <center>
                            <Alert
                              message="รอผลการเริ่มต้นรายงานปฏิบัติธรรมประจำปี"
                              type="warning"
                            />
                          </center>
                        </Col>
                      </Row>
                    )}
                  </>
                }
              >
                {!!IsStartReport && (
                  <>
                    <Table
                      size="small"
                      sticky
                      dataSource={allFields}
                      columns={columns}
                      onChange={handleChange}
                      scroll={{ x: "max-content" }}
                      rowKey="id"
                      pagination={false}
                      // pagination={{
                      //   defaultPageSize: 10,
                      //   showSizeChanger: true,
                      //   pageSizeOptions: ["10", "20", "30"],
                      // }}
                    />

                    <Col xs={24} md={24}>
                      <center>
                        {!!isDiscussion && (
                          <Alert
                            message="กิจกรรมนี้ถูกประชุมและประมวลผลเรียบร้อยแล้ว"
                            type="success"
                          />
                        )}
                        <Button
                          type="primary"
                          icon={<SaveOutlined />}
                          loading={loading}
                          onClick={() => saveAlert()}
                          style={{ marginTop: 10 }}
                          disabled={isDiscussion}
                          block
                        >
                          ยืนยันสรุปผลเข้าร่วมปฏิบัติธรรมประจำปี
                        </Button>
                      </center>
                    </Col>
                  </>
                )}
              </Card>
              <Modal
                title={`เกิดข้อผิดพลาด!`}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width="80%"
              >
                {alertData.map((v, index) => (
                  <Alert key={index} message={v} type="error" />
                ))}
              </Modal>
              <Modal
                title={`แจ้งเตือน!`}
                visible={isModalVisible2}
                onOk={handleOk2}
                onCancel={handleCancel2}
                cancelText="ยกเลิก"
                okText="ตกลงยืนยันประมวลผลข้อมูล"
              >
                <Alert
                  message={
                    "เมื่อยืนยันจะไม่สามารถกลับมาแก้ไขได้ เนื่องจากระบบจะนำวันปฏิบัติธรรมและวันที่ต้องซ่อมไปประมวลผลให้กับนักศึกษาตามรายการ"
                  }
                  type="warning"
                />
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnYearResultDiscussion;
