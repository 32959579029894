import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Modal,
  Checkbox,
  Alert,
  Divider,
} from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  SearchOutlined,
  PlusCircleOutlined,
  PoweroffOutlined,
  SaveOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink, CSVDownload } from "react-csv";

import { SubAdminChecker } from "../../utils/AdminChecker";
import { getAssessmentOverAllResults } from "../../services/AssessmentService";

function AssessmentResultChecker(props) {
  SubAdminChecker();

  if (props.location.state == null) {
    window.location = "/assessment/assessment-results-list";
  }

  const { assessmentId_p, assessmentName_p } = props.location.state;

  const [loading, setLoading] = useState(false);
  const [allQuestion, setAllQuestion] = useState([]);
  const [allQuestionFiveScroe, setAllQuestionFiveScroe] = useState([]);
  const [allQuestionString, setAllQuestionString] = useState([]);
  const [allQuestionText, setAllQuestionText] = useState([]);
  const [allResultFiveScroe, setAllResultFiveScroe] = useState([]);
  const [allResultString, setAllResultString] = useState([]);
  const [allResultText, setAllResultText] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [csvHeaderGen, setCsvHeaderGen] = useState([]);
  const [csvDataGen, setCsvDataGen] = useState([]);

  const searchInput = useRef(null);

  useEffect(() => {
    // todo
    // get all User to checker
    if (assessmentId_p) {
      initAllFields();
    }
  }, []);

  const initAllFields = async () => {
    setLoading(true);
    const res = await getAssessmentOverAllResults(assessmentId_p);
    if (!res._error) {
      const datas = res.data;
      const question = datas.question;
      const resFiveScroe = datas.fiveScroe;
      const resString = datas.string;
      const resText = datas.text;
      setAllQuestion(question);
      setAllResultFiveScroe(resFiveScroe);
      setAllResultString(resString);
      setAllResultText(resText);
      const fiveScroeQuestion = question.filter(
        (v) => v.result_type === "FIVESCORE"
      );
      setAllQuestionFiveScroe(fiveScroeQuestion);
      const stringQuestion = question.filter((v) => v.result_type === "STRING");
      setAllQuestionString(stringQuestion);
      const textQuestion = question.filter((v) => v.result_type === "TEXT");
      setAllQuestionText(textQuestion);

      //csv data
      const mainHeaders = [
        "#",
        "รหัสนักศึกษา",
        "ชื่อ",
        "ฉายา",
        "นามสกุล",
        "ประเภทบัณฑิต",
        "ปีที่เข้า",
        "ระดับ",
        "สาขา",
        "ประเภท",
      ];
      const fiveScroeHeaders = fiveScroeQuestion.map(
        (v) => `ข้อ ${v.ordering + 1}`
      );
      const csvHeader = [...mainHeaders, ...fiveScroeHeaders];
      const csvData = resFiveScroe.map((v, index) => [
        index + 1,
        v.code,
        v.firstName,
        v.nickName,
        v.lastName,
        v.graduateTypeName,
        v.educateYear,
        v.level,
        v.fieldName,
        v.type,
        ...fiveScroeQuestion.map((rs) => v[rs.ordering]),
      ]);
      setCsvHeaderGen(csvHeader);
      setCsvDataGen(csvData);
    }
    setLoading(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "#",
      width: 50,
      dataIndex: "index",
      key: "index",
      render: (_, record, index) => index + 1,
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "code",
      key: "code",
      width: 120,
      ...getColumnSearchProps("code"),
      sorter: (a, b) => a.code - b.code,
      sortOrder: sortedInfo.columnKey === "code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "firstName",
      key: "firstName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      dataIndex: "nickName",
      key: "nickName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      dataIndex: "lastName",
      key: "lastName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "ประเภทบัณฑิต",
      dataIndex: "graduateTypeName",
      key: "graduateTypeName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "ปีที่เข้า",
      dataIndex: "educateYear",
      key: "educateYear",
      width: 80,
      ellipsis: true,
    },
    {
      title: "ระดับ",
      dataIndex: "level",
      key: "level",
      ellipsis: true,
    },
    {
      title: "สาขา",
      dataIndex: "fieldName",
      key: "fieldName",
      ellipsis: true,
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
    },

    ...allQuestionFiveScroe.map((v) => {
      return {
        title: `ข้อ ${v.ordering + 1}`,
        dataIndex: v.ordering,
        key: v.ordering,
        ellipsis: true,
        width: 60,
      };
    }),
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          รายงานผลการทำแบบประเมิน
                        </h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <CSVLink
                          headers={csvHeaderGen}
                          data={csvDataGen}
                          filename={`${assessmentName_p}.csv`}
                        >
                          <Button type="ghost" icon={<FileExcelOutlined />}>
                            Export CSV
                          </Button>
                        </CSVLink>
                      </Col>

                      <Col xs={24} md={24}>
                        <h6 className="font-semibold m-0">
                          {assessmentName_p}
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  size="small"
                  sticky
                  dataSource={allResultFiveScroe}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: "max-content" }}
                  rowKey="id"
                  pagination={false}
                />
                {allQuestionString.length > 0 && (
                  <div>
                    <Divider />
                    <h6 className="font-semibold m-0">
                      คำตอบข้อความตอบแบบสั้น
                    </h6>
                    {allQuestionString.map((q, index) => (
                      <div
                        key={index}
                        style={{ marginLeft: 10, marginBottom: 10 }}
                      >
                        {`ข้อที่ ${q.ordering + 1} : ${q.title}`}
                        {allResultString
                          .filter(
                            (f) =>
                              f.assessmentQuestionId === q.id &&
                              f.result.trim() !== ""
                          )
                          .map((r, index) => (
                            <div key={index}>{`- ${r.result}`}</div>
                          ))}
                      </div>
                    ))}
                  </div>
                )}

                {allQuestionText.length > 0 && (
                  <div>
                    <Divider />
                    <h6 className="font-semibold m-0">คำตอบข้อความตอบแบบยาว</h6>
                    {allQuestionText.map((q, index) => (
                      <div
                        key={index}
                        style={{ marginLeft: 10, marginBottom: 10 }}
                      >
                        {`ข้อที่ ${q.ordering + 1} : ${q.title}`}
                        {allResultText
                          .filter(
                            (f) =>
                              f.assessmentQuestionId === q.id &&
                              f.result.trim() !== ""
                          )
                          .map((r, index) => (
                            <div key={index}>{`- ${r.result}`}</div>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AssessmentResultChecker;
