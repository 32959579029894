import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const getAllGraduateTypes = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/graduate-types`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createGraduateTypes = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/graduate-types`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const updateGraduateTypes = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/graduate-types`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};
