import { Button, Modal, List, Image } from "antd";
import React, { useState, useEffect } from "react";
import { getAllRoomsActive } from "../../../services/RoomsService";

const RoomSelect = ({ isOpen, onClose, onSubmit }) => {
  const [allRooms, setAllRooms] = useState([]);

  useEffect(() => {
    initAllData();
  }, []);
  const initAllData = async () => {
    const rooms = await getAllRoomsActive();
    if (!rooms._error) {
      setAllRooms(rooms.data);
    }
  };

  const renderDesc = (item) => {
    return (
      <>
        <div>ตึก/อาคาร: {item.rooms_building}</div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div>ชั้น: {item.rooms_floor}</div>
          <div style={{ marginLeft: 10 }}>รองรับ: {item.rooms_maxSeats}</div>
        </div>
      </>
    );
  };

  const handleSubmit = (item) => {
    onSubmit(item);
    onClose();
  };

  return (
    <>
      <Modal
        title="เลือกสถานที่ปฏิบัติธรรม"
        visible={isOpen}
        onOk={onSubmit}
        onCancel={onClose}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
      >
        <List
          dataSource={allRooms}
          renderItem={(item) => (
            <List.Item key={item.rooms_id}>
              <List.Item.Meta
                avatar={
                  <Image
                    style={{
                      width: 80,
                      height: 80,
                      backgroundColor: "#CCCCCC",
                      objectFit: "cover",
                    }}
                    src={item.rooms_image1}
                  />
                }
                title={item.rooms_name}
                description={renderDesc(item)}
              />
              <Button onClick={() => handleSubmit(item)}>เลือก</Button>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default RoomSelect;
