import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";
import { RunAPI } from "../utils/RunApi";

export const getAllDharmaOnlinePlublic = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online/public`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getAllDharmaOnlineWithResults = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online/user`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getOneDharmaOnlinePlublicId = async (uuid) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online/public/${uuid}`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getAllDharmaOnline = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createDharmaOnline = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online`;
  const res = await RunApiRefreshTokenMultipart("POST", url, data);
  return res;
};

export const updateDharmaOnline = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};

export const getReportOverall = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-online/report-overall`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};
